import { ActionList, Button, ButtonBar, ButtonProps, ButtonType, Icon, Text, TextType, Error, ErrorContext, ErrorType } from "@mit/hui";
import React, { Fragment } from "react";
import { AccountModel } from "../api/models/AccountModel";
import { DeviceModel } from "../api/models/DeviceModel";
import StringProvider from "../services/StringProvider";
import DeviceController from "../api/DeviceController";
import { store } from "../common/redux/store/store";
import AuthProvider from "../services/AuthProvider";
export const globalStore = store;

interface CardEntityProps {
	accountDetails: AccountModel;
	actionButtons?: ButtonProps[];
	resetPasscodeAction: () => void;
	myDevicesAction?: () => void;
	onNumberClick?: () => void;
}

interface CardEntityState {
	devices: DeviceModel[];
	isLoading: boolean;
	isError: boolean;
	hiddenDeviceCount: number | null;
}

export default class CardEntity extends React.Component<CardEntityProps, CardEntityState> {
	deviceController: DeviceController;
	stringProvider: StringProvider;
	authProvider: AuthProvider

	constructor(props: CardEntityProps) {
		super(props);

		//This class did not take any special action on timeout
		//It will continue to act in such a way
		const onSessionTimeout = () => {}
		this.authProvider = new AuthProvider(onSessionTimeout)
		this.deviceController = new DeviceController(this.authProvider);
		this.stringProvider = new StringProvider();

		this.state = {
			devices: [],
			isError: false,
			isLoading: true,
			hiddenDeviceCount: null,
		};
	}

	componentDidMount() {
		if (this.props.accountDetails.uuid) {
			this.deviceController.getAccountDevices(this.props.accountDetails.uuid).then((response) => {
				let devices: DeviceModel[] = response.items;
				let hiddenDeviceCount = response.hidden_device_count;
				if (devices) {
					let kerb = globalStore.getState().app.impersonate || globalStore.getState().app.user.kerberos;
					devices.forEach((device) => {
						if (!device.owners.find((owner) => owner.cn === kerb)) hiddenDeviceCount++;
					});
					devices = devices.filter((device) => device.owners.find((owner) => owner.cn === kerb));
					this.setState({
						isLoading: false,
						devices: devices,
						hiddenDeviceCount: hiddenDeviceCount,
					});
				} else {
					this.setState({
						isLoading: false,
						devices: [],
					});
				}
			});
		}
	}

	render() {
		return (
			<Fragment>
				<div className="card-entity col-xl-6 col-md-10">
					<div className="card card-entity-container p-3 mb-4">
						<div className="row mb-4">
							<div className="col">
								<Text content={this.props.accountDetails.description} type={TextType.Heading4} />
								<div className="sub-header">
									<Button type={ButtonType.TextNaked} padded={false} text={this.stringProvider.formatPhoneNumber(this.props.accountDetails.username)} onClick={() => this.props.onNumberClick && this.props.onNumberClick()} />
								</div>
							</div>
							<div className="col header-actions">{this.props.actionButtons && <ButtonBar buttons={this.props.actionButtons} alignment={"right"} />}</div>
						</div>
						<div className="row mb-3">
							<div className="col-12 col-md-6 mobile-margin">
								<Text content={this.props.accountDetails.owners.length > 0 ? this.props.accountDetails.owners[0].cn : " - "} icon={"user"} type={TextType.Heading5} />
								<span className="text-indent">
									Caller ID: {this.props.accountDetails.first_name} {this.props.accountDetails.last_name}
								</span>
							</div>
							<div className="col-12 col-md-6">
								<Text content={"Call Control and Settings"} icon={"phone"} type={TextType.Heading5} />
								<span className="text-indent">Manage at </span>
								<a href={this.props.accountDetails.call_control_url}>{this.props.accountDetails.call_control_url?.replace("https://", "").split("/")[0]}</a>
							</div>
						</div>
						<div className="row">
							<div className="col-12 col-md-6">
								<Text type={TextType.Heading5} icon="phone" content="Softphone" />
								<div className="text-indent">
									{this.props.accountDetails.softclient_username && this.props.accountDetails.softclient_username.length > 0 ? (
										<div>
											<Text type={TextType.Body} content={`Username: ${this.props.accountDetails.softclient_username[0].cn}`} />
											<Text
												type={TextType.Body}
												content={`Client type: ${this.stringProvider.capitalizeFirstLetter(this.props.accountDetails.softclient_username[0].softclient_type || "")}`}
											/>
										</div>
									) : (
										<Text type={TextType.Body} content="No softphone enabled" />
									)}
								</div>
							</div>
							<div className="col-12 col-md-6">
								<Icon icon="voicemail" type="regular" />
								<Button text="Reset VM Passcode" onClick={() => this.props.resetPasscodeAction()} type={ButtonType.TextNaked} />
							</div>
						</div>
						{this.state.isLoading && this.state.devices.length === 0 && (
							<Fragment>
								<hr />
								<ActionList isLoading items={[]} />
							</Fragment>
						)}
						{!this.state.isLoading && this.state.devices.length > 0 && (
							<Fragment>
								<hr />
								<div className="row">
									<div className="col">
										<Button
											text={`My Devices (${this.state.devices.length})`}
											type={ButtonType.TextNaked}
											onClick={() => this.props.myDevicesAction && this.props.myDevicesAction()}
										/>
									</div>
								</div>
								{this.state.devices.map((device, index) => {
									return index < 3 ? (
										<div className="row">
											<div className="col-12">
												<Text content={device.description} icon={"phone-office"} type={TextType.Heading5} />
												<div className="text-indent">{device.model}</div>
												<div className="text-indent">MAC: {device.mac}</div>
											</div>
											<div className="col"></div>
										</div>
									) : (
										" "
									);
								})}
								<div className="row">
									<div className="col">
										<Button text={`Other Devices (${this.state.hiddenDeviceCount})`} type={ButtonType.TextNaked} />
									</div>
								</div>
							</Fragment>
						)}
						{!this.state.isLoading && this.state.devices.length === 0 && (
							<Fragment>
								<hr />
								<div className="row">
									<div className="col">
										<Error context={ErrorContext.Component} message="No devices that belong to you are assigned to this account" type={ErrorType.NoData} />
									</div>
								</div>
							</Fragment>
						)}
					</div>
				</div>
			</Fragment>
		);
	}
}
