import React, { Fragment } from "react";
import {
	FormContainer,
	GridContainer,
	LayoutColumn,
	Text,
	TextType,
	FormField,
	IAdvancedTableColumn,
	ButtonProps,
	ButtonType,
	ButtonState,
	AdvancedTable,
	ActionList,
	Button,
	TemplateModalSize,
	ValidationUtility,
} from "@mit/hui";
import { AudioCodesPortModel, DeviceModel } from "../api/models/DeviceModel";
import DeviceController from "../api/DeviceController";
import { ChangeLogModel } from "../api/models/ChangeLogModel";
import { PairModel } from "../api/models/PairModel";
import Modal from "../common/Modal";
import ConfirmationModal from "../common/ConfirmationModal";
import { withComponent } from "../common/WithComponent";
import PortAssignment from "./PortAssignment";
import StringProvider from "../services/StringProvider";
import PairController from "../api/PairController";
import AccountController from "../api/AccountController";
import { connect, Provider } from "react-redux";
import { bindActionCreators } from "redux";
import * as notificationActionCreator from "../common/redux/actions/notification";
import { uuidv4 } from "../common/redux/actions/notification";
import { store } from "../common/redux/store/store";
import AuthProvider from "../services/AuthProvider";
export const globalStore = store;

interface IAudioCodesProps {
	portId: string;
	device: DeviceModel;
	close: () => void;
	changeLogs: ChangeLogModel[];
	actions?: any;
	portDrawerActions: any;
}

interface AudioCodesDetailState {
	port: AudioCodesPortModel;
	device: DeviceModel;
	portId: string;
	changeLogs: ChangeLogModel[];
	isLoading: boolean;
	isBusy: boolean;
	showAssignPairModal: boolean;
	selectedPair: PairModel;
	showUnassignPairModal: boolean;
	ringdownSet: boolean;
	formError: boolean;
	unassignBusy: boolean;
	searchCriteria: string;
}

class AudioCodesDetail extends React.Component<IAudioCodesProps, AudioCodesDetailState> {
	_isMounted: boolean;
	deviceController: DeviceController;
	accountController: AccountController;
	pairController: PairController;
	stringProvider: StringProvider;
	authProvider: AuthProvider

	constructor(props: any) {
		super(props);

		//This class did not take any special action on timeout
		//It will continue to act in such a way
		const onSessionTimeout = () => {}
		this.authProvider = new AuthProvider(onSessionTimeout)
		this.deviceController = new DeviceController(this.authProvider);
		this.accountController = new AccountController(this.authProvider);
		this.pairController = new PairController(this.authProvider);
		this.stringProvider = new StringProvider();

		console.log(" portDrawerActions = ",this.props.portDrawerActions);

		this.state = {
			port: { port: 0, settings: {}, cable_pairs: [] },
			device: this.props.device,
			portId: this.props.portId,
			changeLogs: this.props.changeLogs,
			isLoading: false,
			isBusy: false,
			showAssignPairModal: false,
			selectedPair: {
				switch_site: "",
				frame_partition: "",
				pair_number: 0,
				status: "",
				notes: null,
			},
			showUnassignPairModal: false,
			ringdownSet: false,
			formError: false,
			unassignBusy: false,
			searchCriteria: "",
		};
	}

	componentDidMount() {
		this._isMounted = true;

		const fetchPort = async () => {
			if (this.state.device.uuid) {
				this.setState({ isLoading: true });

				const port = await this.deviceController.getPortAssignment(this.state.device.uuid, this.state.portId);

				this._isMounted &&
					this.setState({
						isLoading: false,
						port: port,
					});
			}
		};

		//Calls
		this._isMounted && fetchPort();
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	fetchAccount = (phoneNumber: string) => {
		this.setState({ isLoading: true });
		this.accountController.getAccountLookup(phoneNumber.replace(/[^0-9 ]/g, "")).then((accountId) => {
			if (accountId) {
				this.accountController.getAccount(accountId, globalStore.getState().app.adminView ).then((response) => {
					if (response) {
						if (response.account_type === this.state.device.account_type) {
							let updatedPort = this.state.port;
							updatedPort.settings.account = response.did || "";
							updatedPort.settings.account_type = response.account_type;
							updatedPort.settings.owners = response.owners;
							updatedPort.settings.callerid = `${response.first_name} ${response.last_name}`;
							updatedPort.settings.line_port = response.line_port;
							this.setState({ port: updatedPort, isLoading: false });
						} else {
							this.props.actions.addNotification(
								uuidv4(),
								"Error",
								"Account Lookup",
								`Account type must match AudioCodes account type (${this.stringProvider.capitalizeFirstLetter(this.state.device.account_type)})`,
								[],
								1
							);
							this.setState({ isLoading: false });
						}
					} else {
						this.props.actions.addNotification(uuidv4(), "Error", "Account Lookup", "Could not find an account associate with the entered DID", [], 1);
						this.setState({ isLoading: false });
					}
				});
			} else {
				this.setState({ isLoading: false });
			}
		});
	};

	isPhoneNumber = (phoneNumber: string): boolean => {
		return !!phoneNumber.match(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);
	};

	validatePhoneNumber = (e: any) => {
		if (this.isPhoneNumber(e.currentTarget.value)) {
			this.fetchAccount(e.currentTarget.value);
		}
	};

	getAccountOwnersList = () => {
		let ownerList: string = "";
		this.state.port.settings.owners?.forEach((owner) => {
			ownerList += owner.cn + "\n";
		});
		return ownerList;
	};

	saveAudioCodes = async () => {
		if (!this.state.formError && this.state.device.uuid && this.state.portId && this.state.port.settings.account) {
			this.setState({ isBusy: true });
			await this.deviceController.updatePortAssignment(this.state.device.uuid, this.state.portId, this.state.port.settings).then((response) => {
				if (response) {
					let updatedPort = this.state.port;
					updatedPort.settings.line_port = response.line_port || null;
					this.setState({
						port: updatedPort,
					});
					this.props.actions.addNotification(uuidv4(), "Success", "Update", "Port Successfully Updated");
					this.props.portDrawerActions(this.state.port);
				}
				this.setState({
					isBusy: false,
				});
			});
		} else {
			this.setState({
				isBusy: false,
			});
			this.props.actions.addNotification(uuidv4(), "Error", "Form Error", "Please complete all required fields before submitting", [], 1);
		}
	};

	closePairAssignmentModal = (pair: PairModel) => {
		let updatedPort = this.state.port;
		if (pair) {
			updatedPort.cable_pairs.push(pair);
			this.props.actions.addNotification(uuidv4(), "Success", "Pair Assignment", "Pair was successfully assigned to Audiocodes Port");
		}
		this.setState({
			showAssignPairModal: false,
			port: updatedPort,
		});
	};

	unassignPair = () => {
		if (this.state.selectedPair.uuid) {
			this.setState({ unassignBusy: true });
			let updatedPair = this.state.selectedPair;
			updatedPair.audio_codes_hostname = null;
			updatedPair.audio_codes_port = null;
			this.pairController.updatePair(updatedPair).then((response) => {
				let updatedPort = this.state.port;
				if (response) {
					updatedPort.cable_pairs = updatedPort.cable_pairs.filter((pair) => pair.uuid !== response.uuid);
					this.props.actions.addNotification(uuidv4(), "Success", "Pair Unassignment", "Pair Successfully Unassigned from Audiocodes Port");
				}
				this.setState({
					showUnassignPairModal: false,
					port: updatedPort,
					unassignBusy: false,
				});
			});
		}
	};

	render() {
		const ModalData = withComponent(Modal);

		if (this.state.isLoading) return <ActionList isLoading items={[]} />;

		const pairTableHeaders: IAdvancedTableColumn[] = [
			{
				id: "switchSite",
				name: "Switch Site",
			},
			{
				id: "framePartition",
				name: "Frame Partition",
			},
			{
				id: "pair",
				name: "Pair number",
			},
			{
				id: "status",
				name: "Status",
			},
			{
				id: "delete",
				name: "",
			},
		];

		const pairTableActions: ButtonProps[] = [
			{
				icon: "times",
				type: ButtonType.IconNaked,
				text: "",
				onClick: (e: any) =>
					this.setState({
						showUnassignPairModal: true,
						selectedPair: e.pairModel,
					}),
				state: ButtonState.Enabled,
			},
		];

		let pairTableData: any[] = [];
		this.state.port.cable_pairs.map((pair: PairModel) => {
			pairTableData.push({
				switchSite: this.stringProvider.capitalizeFirstLetter(pair.switch_site),
				framePartition: this.stringProvider.capitalizeFirstLetter(pair.frame_partition),
				pair: pair.pair_number,
				status: this.stringProvider.capitalizeFirstLetter(pair.status),
				pairModel: pair,
			});
			return null;
		});

		return (
			<Fragment>
				{this.state.port.port > 0 && (
					<div>
						<FormContainer
							id="AudioCodesForm"
							submitText="Save"
							action={this.saveAudioCodes}
							secondaryAction={() => this.props.close()}
							secondaryActionText={"Cancel"}
							isValid={(e: any) => this.setState({ formError: !e })}
							actionIsBusy={this.state.isBusy}
							formValidationTrigger="onChange">
							{this.state.changeLogs.length > 0 ? (
								<div className="metadata">
									<GridContainer showGutters={true}>
										<LayoutColumn colSize={6}>
											<Text type={TextType.Body} content="Created By" padded={false} />
										</LayoutColumn>
										<LayoutColumn colSize={6}>
											<Text
												type={TextType.Body}
												content={
													this.state.changeLogs.filter((log) => log.action === "add")[0].editor +
													" " +
													this.state.changeLogs.filter((log) => log.action === "add")[0].action_date
												}
												padded={false}
											/>
										</LayoutColumn>
									</GridContainer>
								</div>
							) : (
								<div></div>
							)}
							{this.state.changeLogs.length > 0 ? (
								<div className="metadata mb-4">
									<GridContainer showGutters={true}>
										<LayoutColumn colSize={6}>
											<Text type={TextType.Body} content="Modified By" padded={false} />
										</LayoutColumn>
										<LayoutColumn colSize={6}>
											<Text
												type={TextType.Body}
												content={this.state.changeLogs[this.state.changeLogs.length - 1].editor + " " + this.state.changeLogs[this.state.changeLogs.length - 1].action_date}
												padded={false}
											/>
										</LayoutColumn>
									</GridContainer>
								</div>
							) : (
								<div></div>
							)}

							{this.state.port.settings.account ? (
								<GridContainer showGutters={true}>
									<LayoutColumn colSize={6}>
										<FormField
											id="phoneNumber"
											value={this.state.port.settings.account}
											//Implementation to be uncommented when backend functionality has been implemented
											// onChange={(e: any) => {
											//   let updatedPort = this.state.port;
											//   updatedPort.settings.account = e.currentTarget.value;
											//   this.setState({ port: updatedPort });
											// }}
											// properties={{ onBlur: this.validatePhoneNumber }}
											pattern={{
												value: ValidationUtility.phonePattern,
												message: "Phone format",
											}}
											editor="textbox"
											label="Account Phone Number"
											labelDisplay="ontop"
											readonly={true}
											required={true}
										/>
										<FormField
											id="description"
											value={this.state.port.settings.description}
											onChange={(e: any) => {
												let updatedPort = this.state.port;
												updatedPort.settings.description = e.currentTarget.value;
												this.setState({ port: updatedPort });
											}}
											editor="textbox"
											label="Port Description"
											labelDisplay="ontop"
										/>
										<FormField
											id="ringdown"
											value={this.state.port.settings.ringdown}
											onChange={(e: any) => {
												let updatedPort = this.state.port;
												updatedPort.settings.ringdown = e.currentTarget.value;
												if (!this.state.ringdownSet) {
													updatedPort.settings.ringdown_enabled = !!this.state.port.settings.ringdown;
												} else {
													if (!this.state.port.settings.ringdown) {
														updatedPort.settings.ringdown_enabled = false;
														this.setState({ ringdownSet: false });
													}
												}
												this.setState({ port: updatedPort });
											}}
											editor="textbox"
											label="Ring Down Number"
											labelDisplay="ontop"
											properties={{ numericOnly: true }}
										/>
										<FormField
											id="ringDownToggle"
											editor="toggle"
											choice={this.state.port.settings.ringdown_enabled ? "Positive" : "Negative"}
											label="Ring Down Status"
											labelDisplay="ontop"
											value={this.state.port.settings.ringdown_enabled}
											properties={{
												onStateChanged: (e: any) => {
													let updatedPort = this.state.port;
													updatedPort.settings.ringdown_enabled = e.choice === "Positive";
													this.setState({ port: updatedPort, ringdownSet: true });
												},
												disabled: !this.state.port.settings.ringdown,
											}}
										/>
										<FormField
											id="mwiToggle"
											editor="toggle"
											choice={this.state.port.settings.mwi ? "Positive" : "Negative"}
											label="MWI"
											labelDisplay="ontop"
											value={this.state.port.settings.mwi}
											properties={{
												onStateChanged: (e: any) => {
													let updatedPort = this.state.port;
													updatedPort.settings.mwi = e.choice === "Positive";
													this.setState({ port: updatedPort });
												},
											}}
										/>
									</LayoutColumn>
									<LayoutColumn colSize={6}>
										<FormField id="linePort" value={this.state.port.settings.line_port} editor="textbox" label="Line Port" labelDisplay="ontop" readonly={true} />
										<FormField
											id="accountType"
											value={this.stringProvider.capitalizeFirstLetter(this.state.port.settings.account_type || "")}
											editor="textbox"
											label="Account Type"
											labelDisplay="ontop"
											readonly={true}
										/>
										<FormField id="callerID" value={this.state.port.settings.callerid} editor="textbox" label="Caller ID" labelDisplay="ontop" readonly={true} />
										<FormField
											id="number"
											value={this.getAccountOwnersList()}
											editor="multilinetextbox"
											label="Account Owner(s)"
											labelDisplay="ontop"
											properties={{ readOnly: true }}
											readonly={true}
										/>
									</LayoutColumn>
								</GridContainer>
							) : (
								<GridContainer showGutters={true}>
									<LayoutColumn colSize={6}>
										<FormField
											id="phoneNumberSearch"
											properties={{ onBlur: this.validatePhoneNumber }}
											value={this.state.searchCriteria}
											pattern={{
												value: ValidationUtility.phonePattern,
												message: "Phone format",
											}}
											onChange={(e: any) => {
												this.setState({ searchCriteria: e.currentTarget.value });
											}}
											editor="textbox"
											label="Account Phone Number"
											labelDisplay="ontop"
										/>
									</LayoutColumn>
								</GridContainer>
							)}
						</FormContainer>
						<hr />
						<GridContainer showGutters={true}>
							<LayoutColumn colSize={12}>
								<Text content="Pair Assignments" type={TextType.Heading5} />
							</LayoutColumn>
							<LayoutColumn colSize={12}>
								<AdvancedTable columns={pairTableHeaders} items={pairTableData} actionButtons={pairTableActions} maxRows={1000} />
								{(!this.state.port.cable_pairs || this.state.port.cable_pairs.length < 1) && (
									<div className="metadata">
										<Text type={TextType.Body} content="No pairs assigned to this port"></Text>
									</div>
								)}
								<Button type={ButtonType.TextNaked} text="Assign pair" onClick={() => this.setState({ showAssignPairModal: true })} />
							</LayoutColumn>
						</GridContainer>
					</div>
				)}

				{this.state.showAssignPairModal && <ModalData
					show={this.state.showAssignPairModal}
					onClose={() => this.setState({ showAssignPairModal: false })}
					containerless={false}
					size={TemplateModalSize.Default}
					body={
						<Provider store={globalStore}>
							<PortAssignment port={this.state.port} device={this.state.device} close={this.closePairAssignmentModal} />
						</Provider>
					}
					footer={[]}
					header={<Text content="Assign Pair to AudioCodes port" type={TextType.Heading4} icon="" />}
					name={"deviceAssignModal"}
				/>}
				
				{this.state.showUnassignPairModal && <ConfirmationModal
					show={this.state.showUnassignPairModal}
					onClose={() => this.setState({ showUnassignPairModal: false })}
					name="unassignModal"
					title="Unassign Pair from Port"
					isBusy={this.state.unassignBusy}
					body="Are you sure you want to unassign the pair from the port?"
					callBack={this.unassignPair}
				/>}
			</Fragment>
		);
	}
}

const mapDispatchToProps = (dispatch: any) => ({
	actions: bindActionCreators(notificationActionCreator, dispatch),
});

export default connect(null, mapDispatchToProps)(AudioCodesDetail);
