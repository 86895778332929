import React, { Fragment } from "react";
import
{
    TemplateModal,
    TemplateModalAlignment,
    TemplateModalSize,
} from "@mit/hui";

interface ModalProps
{
    show: boolean;
    onClose: any;
    body: any;
    header: any;
    footer: any;
    name: string;
    size: TemplateModalSize;
    containerless: boolean;
}

export default class Modal extends React.Component<ModalProps> {
    render()
    {
        return (
            <Fragment>
                <TemplateModal
                    show={this.props.show}
                    onClose={() => this.props.onClose()}
                    body={this.props.body}
                    bodyAlignment={TemplateModalAlignment.Center}
                    footer={this.props.footer}
                    header={this.props.header}
                    imageUrl=''
                    name={this.props.name}
                    size={this.props.size}
                    containerless={this.props.containerless}
                />
            </Fragment>
        );
    }
}
