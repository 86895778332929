// eslint-disable-next-line
import React, { Fragment } from "react";
import {
	FormContainer,
	GridContainer,
	LayoutColumn,
	FormField,
	ValidationUtility,
	Text,
	TextType,
	Button,
	ButtonType,
	DropdownItemProps,
	AdvancedTable,
	Toggle,
	ActionList,
	IAdvancedTableColumn,
	ButtonProps,
	ButtonState,
	Error,
	ErrorContext,
	ErrorType,
	TemplateModalSize,
	Label,
	Icon,
	Tooltip,
} from "@mit/hui";
import "./Accounts.css";
import { AccountJobModel, AccountModel } from "../api/models/AccountModel";
import AccountController from "../api/AccountController";
import { ChangeLogModel } from "../api/models/ChangeLogModel";
import { AccountServiceModel, AccountTypeModel, VoicemailTypeModel } from "../api/models/AccountTypeModel";
import Modal from "../common/Modal";
import ConfirmationModal from "../common/ConfirmationModal";
import { withComponent } from "../common/WithComponent";
import AppController from "../api/AppController";
import DeviceController from "../api/DeviceController";
import DeviceAssignment from "./DeviceAssignment";
import { DeviceModel } from "../api/models/DeviceModel";
import { store } from "../common/redux/store/store";
import { UserRoleType } from "../api/models/UserRoleModel";
import StringProvider from "../services/StringProvider";
import { bindActionCreators } from "redux";
import { connect, Provider } from "react-redux";
import * as notificationActionCreator from "../common/redux/actions/notification";
import { uuidv4 } from "../common/redux/actions/notification";
import AccountBanner from "./AccountBanner";
import {sortByRelevance} from "../searchUtilities";
import AuthProvider from "../services/AuthProvider";

export const globalStore = store;

interface AccountDetailState {
	accountUUID: string;
	account: AccountModel;
	accountDevices: DeviceModel[];
	isEdit: boolean;
	changeLogs: ChangeLogModel[];
	accountTypes: AccountTypeModel[];
	voicemailTypes: VoicemailTypeModel[];
	accountServiceTypes: AccountServiceModel[];
	accountTypeData: DropdownItemProps[];
	accountServiceData: DropdownItemProps[];
	voicemailOptionData: DropdownItemProps[];
	voicemailTypeData: DropdownItemProps[];
	formError: boolean;
	showDeleteAccountModal: boolean;
	deleteIsBusy: boolean;
	isLoading: boolean;
	isLoadingOwner: boolean;
	isLoadingAdmin: boolean;
	isLoadingSoftphoneUser: boolean;
	adminList: any[];
	ownerList: any[];
	softclient_enabled: boolean;
	softPhoneUserList: any[];
	isError: boolean;
	showDeviceAddModal: boolean;
	showDeviceDeleteModal: boolean;
	currentDevice: any;
	isAdminView: boolean;
	userRole: UserRoleType;
	isBusy: boolean;
	unassignIsBusy: boolean;
	devicesIsLoading: boolean;
	zoomRooms: any[];
	filteredZoomRooms: any[];
	isLoadingZoomRooms: boolean;
	allow_deletion?: boolean;
	deletion_confirmation_message?: any;
}

interface IAccountDetailProps {
	accountId: string;
	sip: string | undefined;
	close: (did?: string) => void;
	isPristine: (pristine?: boolean) => void;
	actions?: any;
	onSuccessfulCreation: (searchTerm: string) => void;
	passAccountData: (account: any) => void;
	callControlBanner: any;
	activationCode: string;
}

const WAIT_INTERVAL = 200;
let timerID: any;


class AccountDetail extends React.Component<IAccountDetailProps, AccountDetailState> {
	accountId: string = this.props.accountId;

	accountController: AccountController;
	deviceController: DeviceController;
	appController: AppController;
	stringProvider: StringProvider;

	newAccount: AccountModel;
	authProvider: AuthProvider

	constructor(props: any) {
		super(props);

		//This class did not take any special action on timeout
		//It will continue to act in such a way
		const onSessionTimeout = () => {}
		this.authProvider = new AuthProvider(onSessionTimeout)
		this.accountController = new AccountController(this.authProvider);
		this.deviceController = new DeviceController(this.authProvider);
		this.appController = new AppController(this.authProvider);
		this.stringProvider = new StringProvider();

		this.newAccount = {
			account_type: "",
			account_service: "",
			did: null,
			owners: [],
			admins: [],
			assigned_phones: [],
			devices: [],
			first_name: "",
			last_name: "",
			display_name: "",
			description: "",
			location: "",
			active: true,
			voicemail_active: false,
			voicemail: null,
			voicemail_behavior: '',
			hide_contact_list: true,
			assigned_room_name: ""
		};

		this.state = {
			accountUUID: this.accountId,
			account: this.newAccount,
			accountDevices: [],
			isEdit: !!this.accountId,
			changeLogs: [],
			accountTypes: [],
			accountTypeData: [],
			voicemailTypes: [],
			accountServiceTypes: [],
			accountServiceData: [],
			voicemailOptionData: [],
			voicemailTypeData: [],
			formError: false,
			showDeleteAccountModal: false,
			deleteIsBusy: false,
			isLoadingAdmin: false,
			isLoadingOwner: false,
			isLoadingSoftphoneUser: false,
			adminList: [],
			ownerList: [],
			softPhoneUserList: [],
			isLoading: false,
			softclient_enabled: false,
			isError: false,
			showDeviceAddModal: false,
			showDeviceDeleteModal: false,
			currentDevice: {},
			isAdminView: globalStore.getState().app.adminView,
			userRole: globalStore.getState().app.userRole,
			isBusy: false,
			unassignIsBusy: false,
			devicesIsLoading: false,
			zoomRooms: [],
			filteredZoomRooms: [],
			isLoadingZoomRooms: false
		};
	}

	componentDidMount() {
		const fetchLookupData = async () => {
			const accountTypes = globalStore.getState().lookups.accountTypes;
			const voicemailTypes = globalStore.getState().lookups.voicemailTypes;

			this.setState({
				accountTypes: accountTypes,
				voicemailTypes: voicemailTypes,
				accountTypeData: this.generateDropdownItems(this.fiterAccountTypeOptions(accountTypes)),
			});
		};

		if (this.state.isEdit === false && this.state.userRole !== "UNPRIV_USER") {
			this.onHandleZoomRoomSearch()
		}
		
		const fetchAccount = async () => {
			this.setState({ isLoading: true });

			this.accountController.getAccount(this.state.accountUUID, this.state.isAdminView).then((account) => {
				if (account) {
					this.accountServiceUpdate(account.account_type);
					if (account.voicemail) {
						this.voicemailServiceUpdate(account.voicemail);
					}
					let voicemailTypes = this.state.voicemailTypes	
					let accountDetails = {
						...account,
						voicemail_behavior_text: voicemailTypes.find((voicemailType) => voicemailType.key === 'broadsoft')?.options.find((option) => option.key === account.voicemail_behavior)?.text || ''
					}
					this.setState({
						account: this.fromAccount(accountDetails),
						accountDevices: this.sortDevices(account.assigned_phones),
						isLoading: false,
						softclient_enabled: !!account.softclient_username,
					});
					this.props.passAccountData(this.state.account);
				} else {
					this.setState({ isError: true, isLoading: false });
				}
			});
			this.updateChangeLog();
		};

		//Calls
		fetchLookupData();
		this.state.isEdit && fetchAccount();
	}

	componentDidUpdate() {
		if (this.state.account && this.state.account.uuid && this.props.sip !== "" && this.props.sip !== this.state.account.password) {
			let updatedAccount = this.state.account;
			updatedAccount.password = this.props.sip;
			this.setState({ account: updatedAccount });
		}
	}

	updateChangeLog = async () => {
		let changeLogs = await this.accountController.getChangeLog(this.state.accountUUID);
		this.setState({ changeLogs: changeLogs || [] });
		this.props.isPristine(true);
	};

	isCallTree = () => {
		return this.state.account.account_service === "Call Tree";
	};

	hasVoicemail = () => {
		return this.state.account.account_service !== "CJP" && this.state.account.account_service !== "fixed";
	};

	showSoftphoneSection = () => {
		const accountType: AccountTypeModel = this.state.accountTypes.filter((type) => type.text === this.state.account.account_type)[0];

		if(accountType){
			return this.state.isEdit && !this.isCallTree() && this.state.account.account_type && (accountType.display_softclient_section);
		}
		return false;
	};

	closeAccount = (did?: string) => {
		this.setState({ account: this.newAccount });
		this.props.isPristine(true);
		this.props.close(did);
	};

	generateDropdownItems = (items: any[], enabled: boolean = true): DropdownItemProps[] => {
		const dropdownData: DropdownItemProps[] = [];
		items.forEach((item: any) => {
			if (enabled || item.enabled) {
				dropdownData.push({
					text: item.text,
					icon: item.key,
				});
			}
		});
		return dropdownData;
	};

	fiterAccountTypeOptions = (accountTypes:any) => {
		const accountTypesTemp: DropdownItemProps[] = [];
		accountTypes.forEach((accountType: any) => {
			if (accountType.admin_types.includes(this.state.userRole.replaceAll(/_|\s/g, '').toLocaleLowerCase())) {
				accountTypesTemp.push(accountType);
			}
		});
		return accountTypesTemp;
	}

	generateVoicemailDropdownItemsFrom = (items: string[]): DropdownItemProps[] => {
		const dropdownData: DropdownItemProps[] = [];
		items.forEach((item: any) => {
			dropdownData.push({
				text: this.state.voicemailTypes.find((type) => type.key === item)?.text || item,
				icon: item,
			});
		});
		return dropdownData;
	};

	accountServiceUpdate = (accountKey: string) => {
		const accountType: AccountTypeModel = this.state.accountTypes.filter((type) => type.key === accountKey)[0];
		let updatedAccount = this.state.account;

		if (accountType) {
			updatedAccount.account_service = accountType.account_service_options.find((service) => service.key.includes("user"))?.text || "User Account";
			this.setState({
				accountServiceTypes: accountType.account_service_options,
				accountServiceData: this.generateDropdownItems(accountType.account_service_options),
				voicemailOptionData: this.generateVoicemailDropdownItemsFrom(accountType.voicemail_options),
			});
		}
	};

	voicemailServiceUpdate = (voicemailSystem: string) => {
		const voicemailType: VoicemailTypeModel = this.state.voicemailTypes.filter((type) => type.key === voicemailSystem)[0];

		if (voicemailType) {
			this.setState({
				voicemailTypeData: this.generateDropdownItems(voicemailType.options, false),
			});
		}
	};

	completeCreateAccount = async (job: AccountJobModel, firstRun?: boolean) => {
		let monitorJob = await this.accountController.monitorAccountJob(job.uuid);
		let drawer = document.getElementsByClassName("drawer")[0];

		if (monitorJob && monitorJob.status === "done") {
			this.props.actions.addNotification(uuidv4(), "Success", "Create", "Account was successfully created");
			this.setState({
				isBusy: false,
			});
			drawer.scroll(0, 0);
			this.closeAccount(this.state.account.did || "");
		} else if (monitorJob && monitorJob.status === "pending") {
			if (firstRun) {
				this.props.actions.addNotification(uuidv4(), "Progress", "Create", "Account creation still in progress, please be patient");
				drawer.scroll(0, 0);
			}
			setTimeout(() => this.completeCreateAccount(job), 5000);
		} else {
			drawer.scroll(0, 0);
			this.props.actions.addNotification(uuidv4(), "Error", "Create", "Account was not successfully created", [], 1);
			this.props.actions.addNotification(
				uuidv4(),
				"Error",
				"Create",
				monitorJob.error,
				[],
				1
			);
			this.setState({
				isBusy: false,
			});
		}
	};

	createAccount = () => {
		if (!this.state.formError && !this.state.isBusy) {
			this.setState({ isBusy: true });
			this.accountController
				.createAccount(this.toAccount(this.state.account))
				.then((response) => {
					if (response && response.job) {
						this.setState({ account: this.fromAccount(this.state.account) });
						this.accountController.completeCreateAccount(response.job.uuid);
						this.completeCreateAccount(response.job, true).then(() => this.props.onSuccessfulCreation(response.did || ""));
					} else {
						this.props.actions.addNotification(uuidv4(), "Error", "Create", "Account was not successfully created", [], 1);
						this.setState({
							isBusy: false,
						});
					}
				})
				.catch((error) => {
					this.props.actions.addNotification(uuidv4(), "Error", "Create", "Account was not successfully created", [], 1);
					if(error?.message){
						this.props.actions.addNotification(
							uuidv4(),
							"Error",
							"Create",
							 error.message,
							[],
							1
						);
					}

					this.setState({ isLoading: false, isBusy: false });
				});
		}
	};

	updateAccount = () => {
		if (!this.state.formError) {
			this.setState({ isBusy: true });
			this.accountController
				.updateAccount(this.toAccount(this.state.account))
				.then((response) => {
					if (response) {
						this.props.actions.addNotification(uuidv4(), "Success", "Update", "Account was successfully updated");
						this.setState({
							account: this.fromAccount(response),
							isBusy: false,
						});
						let drawer = document.getElementsByClassName("drawer")[0];
						drawer.scroll(0, 0);
						this.props.isPristine(true);
						this.updateChangeLog();
					} else {
						this.fromAccount(this.state.account);
						this.props.actions.addNotification(uuidv4(), "Error", "Update", "Account was not successfully updated", [], 1);
						this.setState({
							isBusy: false,
						});
					}
				})
				.catch((error) => {
					this.props.actions.addNotification(uuidv4(), "Error", "Update", "Account was not successfully updated", [], 1);
					this.setState({ isLoading: false, isBusy: false });
				});
		}
	};

	assignDevice = (device: DeviceModel) => {
		if (device && device.uuid) {
			let accountDevices = this.state.accountDevices;
			accountDevices.push(device);
			this.props.actions.addNotification(uuidv4(), "Success", "Device Assignment", "Account was successfully assigned to device");
			this.setState({
				accountDevices: accountDevices,
			});
			this.updateChangeLog();
		} else {
			this.props.actions.addNotification(uuidv4(), "Error", "Device Assignment", "Account was not assigned to device");
		}
		this.props.isPristine(true);
		this.setState({ showDeviceAddModal: false });
	};

	searchUsers = (searchTerm: string, loadingType: any, listType: any) => {

		this.appController.searchUsers(searchTerm).then((people: any) => {
			//check if status not 200 (null is return)

			if (people === null || people.name === "TypeError") {
				this.setState({ ...this.state, [loadingType]: false });
				return;
			}

			//Check if request was aborted
			if (people.name !== "AbortError") {
				const newData = people.map((itm: any) => {
					return {
						data: itm,
						icon: "user",
						text: itm.cn,
						secondaryText: itm.displayname,
						onClick: () => null,
					};
				}).sort(sortByRelevance(searchTerm));
				this.props.actions.closeNotification();
				this.setState({
					...this.state,
					[listType]: newData,
					[loadingType]: false,
				});
			}
		});
	}

	underMinimumLength = (q: string, listType: any, loadingType: any) => {
		if (q.length < 1) {
			this.setState({ ...this.state, [listType]: [], [loadingType]: false } );
			return true;
		}

		if (q.length < 3){
			this.setState( {...this.state,[loadingType]: true});
			return true;
		}

		this.setState( {...this.state,[loadingType]: false});
		return false;
	}

	bind_trailing_args = (fn : any, ...bound_args : any) => {
		return function(...args : any) {
			return fn(...args, ...bound_args);
		};
	}

	onHandleSearch = (searchTerm: string, e: any, listComponentName: string, loadingComponentName: string) =>{
		clearTimeout(timerID);
		const state = this.state;
		const loadingType = loadingComponentName as keyof typeof state;
		const listType = listComponentName as keyof typeof state;

		if( !this.underMinimumLength(searchTerm,listType,loadingType)){

			if (e.keyCode === 8 || e.keyCode === 46) return;

			this.setState({...this.state,[loadingType]: true});

			timerID = setTimeout(() => {
				this.props.actions.closeNotification();

				this.searchUsers(searchTerm,loadingType,listType);
			}, WAIT_INTERVAL);
		}
	}
	
	updateZoomRoomValue = (room:string) => {
		let account = this.state.account
		account.assigned_room_name = room;
		this.setState({account: account});
		this.props.passAccountData(this.state.account)
	}

	onHandleZoomRoomSearch = async() => {
		
		if (this.state.zoomRooms.length === 0) {
			await this.appController.getZoomRooms().then(rooms => {
				if (rooms === null || rooms.name === "TypeError") {
					return;
				} else if (rooms) {
					const zoomRooms = rooms.items.map((room: any) => {
						return { icon: "columns", text: room.room_name, room_name: room.room_name, available: room.available,
							onClick: () => this.updateZoomRoomValue(room.room_name),
						};
					});
					this.setState({zoomRooms: zoomRooms});
				}
			})	
		}

		return;
	}

	filterZoomRooms = (searchTerm: string) => {
		let filteredZoomRooms = this.state.zoomRooms.filter(o => Object.keys(o).some( () => { 
			return o.room_name.toLowerCase().includes(searchTerm.toLowerCase()) && o.available
		}));
		this.setState({filteredZoomRooms: filteredZoomRooms});
	}

	findZoomRoom = (searchTerm: string, e: any) => {
		this.filterZoomRooms(searchTerm);
	}

	onHandleAdminChange = (userList: any) => {
		let updatedAccount: AccountModel = this.state.account;

		if (userList && userList.length > 0) {
			//Add New Users
			userList.forEach((user: any) => {
				if (user.data) {
					if (!updatedAccount.admins.find((admin) => admin.cn === user.data.cn)) {
						updatedAccount.admins.push(user.data);
					}
				}
			});

			//Remove users
			updatedAccount.admins = updatedAccount.admins.filter((admin) => userList.find((user: any) => user.text === admin.cn));

			this.setState({ account: updatedAccount });
		} else {
			//Remove users
			updatedAccount.admins = updatedAccount.admins.filter((admin) => userList.find((user: any) => user.text === admin.cn));
		}
	};

	//Check to determine whether the call control banner should be displayed or nor
	displayCallControlBanner = () => {
		return (this.state.isEdit && !this.state.isAdminView && this.state.account.call_control_banner);
	}

	onHandleOwnerChange = (userList: any) => {
		let updatedAccount: AccountModel = this.state.account;

		if (userList && userList.length > 0) {
			//Add New Users
			userList.forEach((user: any) => {
				if (user.data) {
					if (!updatedAccount.owners.find((owner) => owner.cn === user.data.cn)) {
						updatedAccount.owners.push(user.data);
					}
				}
			});

			//Remove users
			updatedAccount.owners = updatedAccount.owners.filter((owner) => userList.find((user: any) => user.text === owner.cn));

			this.setState({ account: updatedAccount });
		} else {
			//Remove users
			updatedAccount.owners = updatedAccount.owners.filter((owner) => userList.find((user: any) => user.text === owner.cn));
		}
	};

	onHandleSoftphoneUserChange = (user: any) => {
		let updatedAccount: AccountModel = this.state.account;
		updatedAccount.softclient_username = user && user.data ? [user.data] : null;
		this.setState({ account: updatedAccount });
	};

	handelSoftphoneUserClear = () => {
		let updatedAccount: AccountModel = this.state.account;
		updatedAccount.softclient_username = null;
		this.setState({ account: updatedAccount });
	};


	completeDeleteAccount = async (job: AccountJobModel, firstRun?: boolean) => {
		let monitorJob = await this.accountController.monitorAccountJob(job.uuid);
		let drawer = document.getElementsByClassName("drawer")[0];

		if (monitorJob && monitorJob.status === "done") {
			this.props.actions.addNotification(uuidv4(), "Success", "Delete", "Account was successfully deleted");
			this.setState({
				deleteIsBusy: false,
				showDeleteAccountModal: false,
			});
			drawer.scroll(0, 0);
			this.closeAccount();
		} else if (monitorJob && monitorJob.status === "pending") {
			if (firstRun) {
				this.props.actions.addNotification(uuidv4(), "Progress", "Delete", "Account deletion still in progress, please be patient");
				drawer.scroll(0, 0);
			}
			setTimeout(() => this.completeDeleteAccount(job), 5000);
		} else {
			drawer.scroll(0, 0);
			this.props.actions.addNotification(uuidv4(), "Error", "Delete", "Account was not successfully deleted", [], 1);

			this.props.actions.addNotification(
				uuidv4(),
				"Error",
				"Delete",
				monitorJob.error,
				[],
				1
			);

			this.setState({
				deleteIsBusy: false,
				showDeleteAccountModal: false,
			});
		}
	};

	deleteAccount = () => {
		this.setState({ deleteIsBusy: true });
		this.accountController
			.deleteAccount(this.state.account)
			.then(async (response) => {
				if (response && response.job) {
					await this.accountController.completeDeleteAccount(response.job.uuid);
					await this.completeDeleteAccount(response.job, true);
				} else {
					this.props.actions.addNotification(uuidv4(), "Error", "Delete", "Account was not deleted", [], 1);
					this.setState({ showDeleteAccountModal: false, deleteIsBusy: false });
				}
			})
			.catch((error) => {
				this.props.actions.addNotification(uuidv4(), "Error", "Delete", "Account was not deleted", [], 1);

				if(error?.message){
					this.props.actions.addNotification(
						uuidv4(),
						"Error",
						"Delete",
						 error.message,
						[],
						1
					);
				}

				this.setState({ showDeleteAccountModal: false, deleteIsBusy: false });
			});
	};
	
	removeDeviceAssignmentConfirmation = (device: any) => {
		this.props.isPristine(true);
		this.setState({ showDeviceDeleteModal: true, currentDevice: device });
	};

	removeDeviceAssignment = () => {
		if (this.state.accountUUID && this.state.currentDevice) {
			this.setState({ unassignIsBusy: true });
			this.accountController.removeDeviceAssignment(this.state.accountUUID, this.state.currentDevice.mac).then((response) => {
				if (response) {
					let accountDevices = this.state.accountDevices;
					accountDevices = accountDevices.filter((device) => device.mac !== this.state.currentDevice.mac);
					this.props.actions.addNotification(uuidv4(), "Success", "Assignment Removal", "Device was successfully unassigned from account");
					this.setState({
						showDeviceDeleteModal: false,
						unassignIsBusy: false,
						currentDevice: {},
						accountDevices: accountDevices,
					});
					this.updateChangeLog();
				} else {
					this.props.actions.addNotification(uuidv4(), "Error", "Assignment Removal", "Device was not unassigned from account", [], 1);
					this.setState({
						showDeviceDeleteModal: false,
						unassignIsBusy: false,
					});
				}
				this.props.isPristine(true);
			});
		}
	};

	updateMWI = (device: DeviceModel) => {
		this.setState({ devicesIsLoading: true });
		if (this.state.account.uuid && device.mac) {
			this.accountController.updateDeviceAssignmentMWI(this.state.account, device, !device.mwi).then((response) => {
				if (response) {
					this.props.actions.addNotification(uuidv4(), "Success", "MWI Update", `MWI for ${device.mac} was successfully updated`);
					this.setState({
						devicesIsLoading: false,
					});
					this.updateChangeLog();
				} else {
					this.props.actions.addNotification(uuidv4(), "Error", "MWI Update", `MWI for ${device.mac} was not updated`, [], 1);
					this.setState({
						devicesIsLoading: false,
					});
				}
				this.props.isPristine(true);
			});
		}
	};

	viewSipPassword = () => {
		return this.state.isEdit && this.state.isAdminView && 
		(this.state.userRole === "SUPER_USER" || (this.state.userRole === "VOIP_ADMIN" && !this.state.account.sip_password_lock))
	}

	isZoomAccount = () => {
		return this.isAccountType("zoom")
	}

	isZoomRoom = () => {
		return this.isAccountService("zoom room");
	}

	isNotZoomRoom = () => {
		return (this.state.account.account_service.toLowerCase() !== "zoom room");
	}

	isUserAccount = () => {
		return this.isAccountService("user") || this.isAccountService("user account"); 
	}

	isNotUserAccount = () => {
		return this.isNotAccountService("user") || this.isNotAccountService("user account");
	}

	isServiceLineAccount = () => {
		return this.isAccountService("service") || this.isAccountService("service account");
	}

	isAccountService = (accountService:string) => {
		return (this.state.account.account_service.toLowerCase() === accountService);
	}

	isNotAccountService = (accountService:string) => {
		return (this.state.account.account_service.toLowerCase() !== accountService);
	}
	
	isAccountType = (accountType:string) => {
		return (this.state.account.account_type.toLowerCase() === accountType);
	}

	isNotAccountType = (accountType:string) => {
		return (this.state.account.account_type.toLowerCase() !== accountType);
	}

	doNotDisplaySoftphone = () => {
		return this.isZoomAccount() && this.isServiceLineAccount();
	}

	render() {
		const ModalData = withComponent(Modal);

		if (this.state.isError) return <Error context={ErrorContext.Component} message={"Failed to retrieve Account Details"} type={ErrorType.Generic} />;

		if (this.state.isLoading) return <ActionList isLoading items={[]} />;

		let headers: IAdvancedTableColumn[] = [
			{
				id: "empty1",
				name: "",
			},
			{
				id: "macAddress",
				name: "MAC Address",
			},
			{
				id: "description",
				name: "Description",
			},
			{
				id: "model",
				name: "Device Model",
			},
			{
				id: "mwi",
				name: "MWI",
				options: {
					demandPopin: true,
					minWidth: 600,
					popinText: "MWI",
				},
			},
		];

		let showAudioCodesFields: boolean = false;
		let audioCodesPortCount: number = 0;
		let audioCodesMac: string = "";
		let tableData: any[] = [];
		this.state.accountDevices.forEach((device, index) => {
			if (device.audio_codes) {
				showAudioCodesFields = true;
				audioCodesPortCount = device.mac === audioCodesMac ? audioCodesPortCount + 1 : 0;
				audioCodesMac = device.mac;
			}

			let tableItem = {
				empty1: index + 1,
				mac: device.mac,
				macAddress: <span className="no-wrap">{device.mac}</span>,
				description: device.description,
				model: device.model,
				mwi: (
					<Toggle
						choice={device.mwi ? "Positive" : "Negative"}
						onClick={(e: any) => this.updateMWI(device)}
						onStateChanged={(e: any) => {
							device.mwi = e.choice === "Positive";
						}}
						disabled={device.audio_codes}
					/>
				),
				ringdown: device.audio_codes_ports && device.audio_codes_ports.length > 0 ? device.audio_codes_ports[0].settings.ringdown : "",
				ringdownEnabled:
					device.audio_codes_ports && device.audio_codes_ports.length > 0 && device.audio_codes_ports[0].settings.ringdown ? (
						device.audio_codes_ports[0].settings.ringdown_enabled ? (
							<Tooltip text={"Ringdown Enabled"}>
								<div className="pl-2">
									<Icon icon={"check"} type={"regular"} color={"green"} />
								</div>
							</Tooltip>
						) : (
							<Tooltip text={"Ringdown Disabled"}>
								<div className="pl-2">
									<Icon icon={"times"} type={"regular"} color={"red"} />
								</div>
							</Tooltip>
						)
					) : (
						""
					),
				port: device.audio_codes_ports && device.audio_codes_ports.length > 0 ? device.audio_codes_ports[0].port : "",
				device: device,
			};

			tableData.push(tableItem);
		});

		if (showAudioCodesFields) {
			headers.push(
				{
					id: "ringdown",
					name: "Ringdown",
					options: {
						demandPopin: true,
						minWidth: 600,
						popinText: "Ringdown",
					},
				},
				{
					id: "ringdownEnabled",
					name: "",
				},
				{
					id: "port",
					name: "Port",
					options: {
						demandPopin: true,
						minWidth: 600,
						popinText: "Port",
					},
				}
			);
		}

		const actionButtons: ButtonProps[] = [
			{
				icon: "times",
				type: ButtonType.IconNaked,
				text: "",
				onClick: (row: any) => this.removeDeviceAssignmentConfirmation(row.device),
				shouldDisplay: (row: any) => !row.device.audio_codes,
				state: ButtonState.Enabled,
			},
		];

		let ownerList = this.state.account.owners.map((owner) => {
			return { icon: "user", text: owner.cn, secondaryText: owner.displayname };
		});

		let adminList = this.state.account.admins.map((admin) => {
			return { icon: "user", text: admin.cn, secondaryText: admin.displayname };
		});

		return (
			<Fragment>
				{ this.displayCallControlBanner() && <AccountBanner account={this.state.account} callControlBanner={this.props.callControlBanner}></AccountBanner>}
				{!this.state.isEdit || (this.state.isEdit && this.state.account.uuid) ? (
					<FormContainer
						isPristine={(e) => this.props.isPristine(e)}
						id="Account Form"
						submitText="Save"
						actionIsBusy={this.state.isBusy}
						action={() => (this.state.isEdit ? this.updateAccount() : this.createAccount())}
						secondaryAction={this.closeAccount}
						secondaryActionText={"Cancel"}
						tertiaryAction={
							this.state.isEdit && this.state.account.allow_deletion
								? () => this.setState({ showDeleteAccountModal: true })
								: null
						}
						tertiaryActionText={this.state.isEdit && this.state.account.allow_deletion ? "Delete Account" : ""}
						isValid={(e: any) => this.setState({ formError: !e })}
						formValidationTrigger="onChange">
						{this.state.isEdit && this.state.changeLogs.length > 0 ? (
							<Fragment>
								<div className="metadata">
									<GridContainer showGutters={true}>
										<LayoutColumn colSize={6}>
											<Text type={TextType.Body} content="Created By" padded={false} />
										</LayoutColumn>
										<LayoutColumn colSize={6}>
											<Text
												type={TextType.Body}
												content={
													this.state.changeLogs.filter((log) => log.action === "add")[0].editor +
													" " +
													this.state.changeLogs.filter((log) => log.action === "add")[0].action_date
												}
												padded={false}
											/>
										</LayoutColumn>
									</GridContainer>
								</div>
							</Fragment>
						) : (
							<span></span>
						)}
						{this.state.isEdit && this.state.changeLogs.length > 0 ? (
							<Fragment>
								<div className="metadata mb-4">
									<GridContainer showGutters={true}>
										<LayoutColumn colSize={6}>
											<Text type={TextType.Body} content="Modified By" padded={false} />
										</LayoutColumn>
										<LayoutColumn colSize={6}>
											<Text
												type={TextType.Body}
												content={this.state.changeLogs[this.state.changeLogs.length - 1].editor + " " + this.state.changeLogs[this.state.changeLogs.length - 1].action_date}
												padded={false}
											/>
										</LayoutColumn>
									</GridContainer>
								</div>
							</Fragment>
						) : (
							<div></div>
						)}
						<GridContainer showGutters={true}>
							<LayoutColumn colSize={6}>
								{(this.state.isAdminView || this.isZoomRoom()) && (
									<FormField
										id="accountType"
										editor="dropdown"
										options={this.state.accountTypeData}
										label="Account Type"
										labelDisplay="ontop"
										value={this.state.account.account_type}
										onChange={(e: any) => {
											let updatedAccount = this.state.account;
											updatedAccount.account_type = e.text;
											this.setState({ account: updatedAccount });
												
											this.accountServiceUpdate(e.icon);
										}}
										properties={{ readOnly: this.state.isEdit }}
										required={true}
									/>
								)}
								<FormField
									id="phoneNumber"
									value={this.state.account.did}
									onChange={(e: any) => {
										let updatedAccount = this.state.account;
										updatedAccount.did = e.currentTarget.value;
										this.setState({ account: updatedAccount });
									}}
									editor="textbox"
									label="Phone number"
									labelDisplay="ontop"
									pattern={{
										value: ValidationUtility.phonePattern,
										message: "Phone format",
									}}
									readonly={this.state.isEdit}
								/>
								<FormField
									id="description"
									value={this.state.account.description}
									onChange={(e: any) => {
										let updatedAccount = this.state.account;
										updatedAccount.description = e.currentTarget.value;
										this.setState({ account: updatedAccount });
									}}
									editor="textbox"
									label="Description"
									labelDisplay="ontop"
								/>
								<GridContainer showGutters={true}>
									<LayoutColumn colSize={12}>
										<br />
										<Label text="Caller ID" />
									</LayoutColumn>
									{this.isZoomRoom() && (
										<LayoutColumn colSize={12}>
											<FormField
												id="callerIdzr"
												value={this.state.account.display_name}
												onChange={(e: any) => {
													let updatedAccount = this.state.account;
													updatedAccount.display_name = e.currentTarget.value;
													this.setState({ account: updatedAccount });
												}}
												editor="textbox"
												label=""
												labelDisplay="ontop"
												required={true}
											/>
										</LayoutColumn>
									)}
									{this.isNotZoomRoom() && (
										<LayoutColumn colSize={6}>
											<FormField
												id="callerId"
												value={this.state.account.first_name}
												onChange={(e: any) => {
													let updatedAccount = this.state.account;
													updatedAccount.first_name = e.currentTarget.value;
													this.setState({ account: updatedAccount });
												}}
												editor="textbox"
												label=""
												labelDisplay="ontop"
												required={true}
											/>
										</LayoutColumn>
									)}
									{this.isNotZoomRoom() && (
										
										<LayoutColumn colSize={6}>
											<FormField
												id="callerId2"
												value={this.state.account.last_name}
												onChange={(e: any) => {
													let updatedAccount = this.state.account;
													updatedAccount.last_name = e.currentTarget.value;
													this.setState({ account: updatedAccount });
												}}
												editor="textbox"
												label=""
												labelDisplay="ontop"
												required={true}
											/>
										</LayoutColumn>
									)}
									
								</GridContainer>
							</LayoutColumn>
							<LayoutColumn colSize={6}>
								{(this.state.isAdminView || this.isZoomRoom()) && (
									<FormField
										id="accountService"
										editor="dropdown"
										options={this.state.accountServiceData}
										label="Account Service"
										labelDisplay="ontop"
										value={this.state.account.account_service}
										onChange={(e: any) => {
											let updatedAccount = this.state.account;
											updatedAccount.account_service = e.text;
											this.setState({ account: updatedAccount });
										}}
										properties={{ readOnly: this.state.isEdit }}
										required={true}
									/>
								)}
								
								
								{this.state.isAdminView && this.isNotZoomRoom() && (
									<FormField
										id="sipUserName"
										value={this.state.account.sip_user_id}
										onChange={(e: any) => {
											let updatedAccount = this.state.account;
											updatedAccount.sip_user_id = e.currentTarget.value;
											this.setState({ account: updatedAccount });
										}}
										editor="textbox"
										label="SIP Username"
										labelDisplay="ontop"
										pattern={{
											value: ValidationUtility.emailPattern,
											message: "Email format",
										}}
										readonly={this.state.isEdit}
									/>
								)}
								
								{ this.viewSipPassword() && this.isNotZoomRoom() && (
										<FormField id="sipPassword" value={this.state.account.password} editor="textbox" label="SIP Password" labelDisplay="ontop" readonly={true} />
								)}

								{!this.isCallTree() && (
									<FormField
										id="location"
										value={this.state.account.location}
										onChange={(e: any) => {
											let updatedAccount = this.state.account;
											updatedAccount.location = e.currentTarget.value;
											this.setState({ account: updatedAccount });
										}}
										editor="textbox"
										label="Primary Location"
										labelDisplay="ontop"
									/>
								)}

								{this.state.isAdminView && this.isNotZoomRoom() && (
									<FormField
										id="activeToggle"
										editor="toggle"
										choice={this.state.account.active ? "Positive" : "Negative"}
										properties={{
											onStateChanged: (e: any) => {
												let updatedAccount = this.state.account;
												updatedAccount.active = e.choice === "Positive";
												this.setState({ account: updatedAccount });
											},
										}}
										label="Active"
										labelDisplay="ontop"
									/>
								)}
								{(this.isZoomRoom()) && !this.state.isEdit  && (
									
									<FormField
										id="assignZoomRoom"
										editor="autocomplete"
										value={this.state.account.assigned_room_name}
										properties={{
											type: "dropdown",
											onSearch: this.bind_trailing_args(this.findZoomRoom),
											isBusy: this.state.isLoadingZoomRooms,
											value: this.state.account.assigned_room_name,
											fullWidthTags: true,
											limit: 1000,
										}}
										label="Assign account to Zoom room"
										labelDisplay="ontop"
										searchOptions={{
											name: "assignZoomRoom",
											placeholderText: "Assign account to Zoom room",
										}}
										
										items={this.state.filteredZoomRooms}
									/>

								)}
								{ this.state.isEdit && (this.state.isAdminView && this.isZoomRoom()) && (
										<FormField id="assignZoomRoom" value={this.state.account.assigned_room_name} editor="textbox" label="Assigned to Room" labelDisplay="ontop" readonly={true} />
								)}

								{(this.isZoomRoom()) && (
									<FormField
										id="hideContactList"
										editor="toggle"
										choice={this.state.account.hide_contact_list ? "Positive" : "Negative"}
										properties={{
											onStateChanged: (e: any) => {
												let updatedAccount = this.state.account;
												updatedAccount.hide_contact_list = e.choice === "Positive";
												this.setState({ account: updatedAccount });
											},
										}}
										label="Hide from contact list"
										labelDisplay="ontop"
									/>
								)}

								{ this.state.isEdit && (this.state.isAdminView && this.isZoomRoom()) && (
										<FormField id="activationCode" value={this.props.activationCode} editor="textbox" label="Activation Code" labelDisplay="ontop" readonly={true} />
								)}
							</LayoutColumn>
						</GridContainer>

						<hr />
						<GridContainer showGutters={true}>
							<LayoutColumn colSize={6}>
								<FormField
									id="ownersList"
									editor="autocomplete"
									properties={{
										type: "tags",
										onSearch: this.bind_trailing_args(this.onHandleSearch,"ownerList","isLoadingOwner"),
										isBusy: this.state.isLoadingOwner,
										value: ownerList,
										fullWidthTags: true,
										onChange: this.onHandleOwnerChange,
										limit: 1000,
									}}
									label="Account Owners"
									labelDisplay="ontop"
									searchOptions={{
										name: "acoundAdminsSearch",
										placeholderText: "KerbID or Name",
									}}
									items={this.state.ownerList}
								/>
							</LayoutColumn>
							{this.isNotZoomRoom() && (
								<LayoutColumn colSize={6}>
									<FormField
										id="adminList"
										editor="autocomplete"
										properties={{
											type: "tags",
											onSearch: this.bind_trailing_args(this.onHandleSearch,"adminList","isLoadingAdmin"),
											isBusy: this.state.isLoadingAdmin,
											value: adminList,
											fullWidthTags: true,
											onChange: this.onHandleAdminChange,
											limit: 1000,
										}}
										label="Account Admins"
										labelDisplay="ontop"
										searchOptions={{
											name: "acoundAdminsSearch",
											placeholderText: "KerbID or Name",
										}}
										items={this.state.adminList}
									/>
								</LayoutColumn>
							)}
						</GridContainer>
						<hr />

						{/* Voicemail Section */}
						{this.state.voicemailOptionData.length > 0 && !this.isCallTree() && this.hasVoicemail() ? (
							!this.state.isEdit ||
							(this.state.isEdit && (this.state.account.account_type.toLowerCase().includes("broadsoft") || (this.state.account.voicemail && this.state.account.voicemail_active))) ? (
								<GridContainer showGutters={true}>
									<LayoutColumn colSize={6}>
										{this.state.voicemailOptionData && (!this.state.isEdit || (this.state.isEdit && this.state.isAdminView && this.state.userRole !== "UNPRIV_USER")) && (
											<FormField
												id="voicemailSystem"
												editor="dropdown"
												options={this.state.voicemailOptionData}
												label="Voicemail System"
												labelDisplay="ontop"
												value={this.state.account.voicemail}
												onChange={(e: any) => {
													let updatedAccount = this.state.account;
													updatedAccount.voicemail = e.text;
													updatedAccount.voicemail_active = true;
													this.voicemailServiceUpdate(e.icon);
													this.setState({ account: updatedAccount });
												}}
												properties={{
													readOnly: this.state.isEdit,
												}}
											/>
										)}
										<FormField
											id="voicemailToggle"
											editor="toggle"
											choice={this.state.account.voicemail_active ? "Positive" : "Negative"}
											properties={{
												onStateChanged: (e: any) => {
													let updatedAccount = this.state.account;
													updatedAccount.voicemail_active = e.choice === "Positive";
													this.setState({ account: updatedAccount });
												},
											}}
											label="Voicemail"
											labelDisplay="ontop"
										/>
									</LayoutColumn>
									<LayoutColumn colSize={6}>
										{this.state.account.voicemail && this.state.account.voicemail_active && this.state.voicemailTypeData.length > 0 ? (
											<FormField
												id="voicemailSettings"
												editor="dropdown"
												options={this.state.voicemailTypeData}
												label="Voicemail Settings"
												labelDisplay="ontop"
												value={this.state.account.voicemail_behavior_text}
												onChange={(e: any) => {
													let updatedAccount = this.state.account;
													updatedAccount.voicemail_behavior_text = e.text;
													updatedAccount.voicemail_behavior = this.state.voicemailTypes.find((voicemailType) => voicemailType.key === 'broadsoft')?.options.find((option) => option.text === e.text)?.text
													this.setState({account: updatedAccount });
												}}
												properties={{ required: true }}
											/>
										) : (
											<div></div>
										)}
										{this.state.account.voicemail_behavior_text &&
										this.state.account.voicemail_behavior_text !== "store on server" &&
										this.state.account.voicemail &&
										this.state.account.voicemail_active &&
										!this.state.isBusy ? (
											<FormField
												id="voicemailEmail"
												value={this.state.account.voicemail_email}
												onChange={(e: any) => {
													let updatedAccount = this.state.account;
													updatedAccount.voicemail_email = e.currentTarget.value;
													this.setState({ account: updatedAccount });
												}}
												setValue={this.state.account.voicemail_email}
												editor="textbox"
												label="Voicemail Email"
												labelDisplay="ontop"
												pattern={{
													value: ValidationUtility.emailPattern,
													message: "Email format",
												}}
												required={true}
											/>
										) : (
											<div></div>
										)}
									</LayoutColumn>
								</GridContainer>
							) : (
								<GridContainer showGutters={true}>
									<LayoutColumn colSize={12}>
										<Text content="Voicemail not available for this account" />
									</LayoutColumn>
								</GridContainer>
							)
						) : (
							<div></div>
						)}

						{(this.showSoftphoneSection() || (this.isZoomAccount() && this.isUserAccount())) && !this.doNotDisplaySoftphone() && (
							
							<div>
								<hr />
								{ this.state.account.softphone_banner && (
									<GridContainer showGutters={true}>
										<LayoutColumn colSize={12}>
											{this.state.account.softphone_banner && (
												<div>	
													<Text content={this.state.account.softphone_banner.headline} type={TextType.Heading5}></Text>
													{this.state.account.softphone_banner.contents.map((item:any, index:any) => {
														return(<Text key={index} content={<><span dangerouslySetInnerHTML={{__html: `${item}`}}></span></>} />);
													})}
												</div>
											)}
										</LayoutColumn>
									</GridContainer>
								)}
								
								<GridContainer showGutters={true}>
									{ !this.isZoomAccount() && this.isNotUserAccount() && (
										<LayoutColumn colSize={6}>
											<FormField
												id="softPhoneToggle"
												editor="toggle"
												choice={this.state.softclient_enabled ? "Positive" : "Negative"}
												properties={{
													onStateChanged: (e: any) => {
														let updatedAccount: AccountModel = this.state.account;
														updatedAccount.softclient_username = null;
														this.setState({
															softclient_enabled: e.choice === "Positive",
															account: updatedAccount,
														});
													},
												}}
												label="Softphone"
												labelDisplay="ontop"
											/>
										</LayoutColumn>
									)}	
									<LayoutColumn colSize={6}>
										{(this.state.softclient_enabled || (this.isZoomAccount() && this.isUserAccount())) && (
											<FormField
												id="softphoneClient"
												editor="autocomplete"
												properties={{
													clearOffSelect: true,
													onSearch: this.bind_trailing_args(this.onHandleSearch,"softPhoneUserList","isLoadingSoftphoneUser"),
													isBusy: this.state.isLoadingSoftphoneUser,
													value:
														this.state.account.softclient_username && this.state.account.softclient_username.length > 0 ? this.state.account.softclient_username[0].cn : "",
													limit: 1000,
													clearOnBlur: true,
													onClear: this.handelSoftphoneUserClear,
													onChange: this.onHandleSoftphoneUserChange,
												}}
												// onChange={this.onHandleSoftphoneUserChange}
												label="Softphone Username"
												labelDisplay="ontop"
												searchOptions={{
													name: "softclientSearch",
													placeholderText: "KerbID or Name",
												}}
												items={this.state.softPhoneUserList}
												required={(this.isZoomAccount() && this.isUserAccount()) ? true : false}
											/>
										)}
									</LayoutColumn>
								</GridContainer>
							</div>
						)}

						{this.state.isAdminView && this.state.account.account_type && !this.isCallTree() && this.state.account.account_type.toLowerCase() !== "cjp" &&  this.isNotZoomRoom() && (
							<div>
								<hr />
								<GridContainer showGutters={true}>
									<LayoutColumn colSize={6}>
										<FormField
											id="forkingToggle"
											editor="toggle"
											choice={this.state.account.forking ? "Positive" : "Negative"}
											properties={{
												onStateChanged: (e: any) => {
													let updatedAccount = this.state.account;
													updatedAccount.forking = e.choice === "Positive";
													this.setState({ account: updatedAccount });
												},
											}}
											label="Forking"
											labelDisplay="ontop"
										/>
									</LayoutColumn>
									<LayoutColumn colSize={6}>
										<FormField
											id="sharedToggle"
											editor="toggle"
											choice={this.state.account.shared ? "Positive" : "Negative"}
											properties={{
												onStateChanged: (e: any) => {
													let updatedAccount = this.state.account;
													updatedAccount.shared = e.choice === "Positive";
													this.setState({ account: updatedAccount });
												},
											}}
											label="Shared Line"
											labelDisplay="ontop"
										/>
									</LayoutColumn>
								</GridContainer>
							</div>
						)}
					</FormContainer>
				) : null}

				{this.state.isEdit && this.state.accountDevices && this.state.account.account_type.toLowerCase() !== "cjp" && !this.isCallTree() && this.isNotZoomRoom() && (
					<div>
						<hr />
						<GridContainer showGutters={true}>
							<LayoutColumn colSize={6}>
								<Text content="Device Assignments" type={TextType.Heading5} />
							</LayoutColumn>
							<LayoutColumn colSize={6}>
								<Button icon="plus" type={ButtonType.TextNaked} text="Assign account to device" onClick={() => this.setState({ showDeviceAddModal: true })} />
							</LayoutColumn>
						</GridContainer>
						<GridContainer showGutters={true}>
							<LayoutColumn colSize={12}>
								<br />
								{this.state.devicesIsLoading ? <ActionList isLoading items={[]} /> : <AdvancedTable columns={headers} items={tableData} actionButtons={actionButtons} maxRows={1000} />}
							</LayoutColumn>
							{this.state.accountDevices.length < 1 && (
								<LayoutColumn colSize={12}>
									<Error context={ErrorContext.Component} message="No Devices assigned to this account" type={ErrorType.NoData} />
								</LayoutColumn>
							)}
						</GridContainer>
					</div>
				)}

				{this.state.showDeviceAddModal && <ModalData
					show={this.state.showDeviceAddModal}
					onClose={() => this.setState({ showDeviceAddModal: false })}
					containerless={false}
					size={TemplateModalSize.Default}
					body={
						<Provider store={globalStore}>
							<DeviceAssignment assignDevice={this.assignDevice} account={this.state.account} close={() => this.setState({ showDeviceAddModal: false })} />
						</Provider>
					}
					footer={[]}
					header={<Text content="Assign Account to Device" type={TextType.Heading4} icon="" />}
					name={"deviceAddModal"}
				/>}

				{this.state.showDeleteAccountModal && <ConfirmationModal
					show={this.state.showDeleteAccountModal}
					onClose={() => this.setState({ showDeleteAccountModal: false })}
					name="deleteAccoundModal"
					isBusy={this.state.deleteIsBusy}
					title="Delete Account"
					body={this.state.account.deletion_confirmation_message}
					callBack={this.deleteAccount}
				/>}
				
				{this.state.showDeviceDeleteModal && <ConfirmationModal
					show={this.state.showDeviceDeleteModal}
					onClose={() => this.setState({ showDeviceDeleteModal: false })}
					name="deleteDeviceModal"
					title="Unassign device"
					isBusy={this.state.unassignIsBusy}
					body={"Are you sure you want to unassign " + this.state.currentDevice.mac + " from this account?"}
					callBack={this.removeDeviceAssignment}
				/>}
				
			</Fragment>
		);
	}
	//API Transformations
	toAccount = (account: AccountModel): AccountModel => {
		account.account_type = this.state.accountTypes.find((accountType) => accountType.text === account.account_type)?.text || account.account_type;
		account.account_service = this.state.accountServiceTypes.find((accountService) => accountService.text === account.account_service)?.text || account.account_service;
		account.voicemail = this.state.voicemailTypes.find((voicemailType) => voicemailType.text === account.voicemail)?.key || account.voicemail;
		account.voicemail_behavior =
			this.state.voicemailTypes.find((voicemailType) => voicemailType.key === account.account_type)?.options.find((option) => option.text === account.voicemail_behavior_text)?.key ||
			account.voicemail_behavior;
		account.assigned_phones = undefined
		return account;
	};

	fromAccount = (account: AccountModel): AccountModel => {
		account.account_type = this.state.accountTypes.find((accountType) => accountType.key === account.account_type)?.text || account.account_type;
		account.account_service = this.state.accountServiceTypes.find((accountService) => accountService.text === account.account_service)?.text || account.account_service;
		account.voicemail = this.state.voicemailTypes.find((voicemailType) => voicemailType.key === account.voicemail)?.text || account.voicemail;
		account.voicemail_behavior =
			this.state.voicemailTypes.find((voicemailType) => voicemailType.key === account.account_type)?.options.find((option) => option.key === account.voicemail_behavior_text)?.text ||
			account.voicemail_behavior;
		return account;
	};

	sortDevices = (accountDevices?: DeviceModel[]): DeviceModel[] => {
		if (!this.state.isAdminView && accountDevices) {
			let cn = globalStore.getState().app.impersonate || globalStore.getState().app.user.kerberos;
			accountDevices.forEach((device) => {
				let hasOwners = device.owners ? true : false;

				if (!hasOwners) {
					device.description = "";
				} else {
					if (device.owners.find((owner) => owner.cn !== cn)) {
						device.description = "";
					}
				}
			});
		}

		return accountDevices ? accountDevices.sort((a, b) => (a.audio_codes === b.audio_codes ? 0 : a.audio_codes ? -1 : 1)) : [];
	};
}

const mapDispatchToProps = (dispatch: any) => ({
	actions: bindActionCreators(notificationActionCreator, dispatch),
});

export default connect(null, mapDispatchToProps)(AccountDetail);
