import { Alert, Text, TextType } from "@mit/hui";
import { divide, indexOf } from "lodash";
import React, { Fragment } from "react";
import { AccountModel } from "../api/models/AccountModel";
import { store } from "../common/redux/store/store";
export const globalStore = store;

interface IAccountBannerProps {
	account: AccountModel;
	callControlBanner: any;
}

interface AccountBannerState {}

class AccountBanner extends React.Component<IAccountBannerProps, AccountBannerState> {
	constructor(props: any) {
		super(props);
		this.state = {};
	}

	getHostName = (urlString: string): string => {
		return new URL(urlString).hostname || urlString;
	};

	render() {
		return (
			<Fragment>
				<Alert
					text={
						<div>
							{this.props.callControlBanner && (
								<div>	
									<Text content={this.props.callControlBanner.headline} type={TextType.Heading5}></Text>
									{this.props.callControlBanner.contents.map((item:any, index:any) => {
										return(<Text key={index} content={<><span key={index} dangerouslySetInnerHTML={{__html: `${item}`}}></span></>} />);
									})}
								</div>
							)}
						</div>
					}
					type="info"
				/>
			</Fragment>
		);
	}
}

export default AccountBanner;
