import React, { Fragment } from "react";
import { BasicLayout } from "@mit/hui";
import DeviceList from "./DeviceList";
import { connect } from "react-redux";

class Devices extends React.Component<any> {
  render() {
    let Id = this.props.match.params.id;

    return (
      <Fragment>
        <BasicLayout
          fullWidth
          content={<DeviceList Id={Id} />}
          contentTitle={"Devices"}
          startState={true}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: any) => ({
  adminView: state.app.adminView,
  impersonate: state.app.impersonate
});

export default connect(mapStateToProps)(Devices);
