import React, { Fragment } from "react";
import { Switch, Route, Router, Redirect } from "react-router-dom";
import History from "../common/History";
import Navigation from "../common/Navigation";
import Accounts from "../accounts/Accounts";
import {
	AtlasPage,
	TextType,
	Text,
	Button,
	ButtonType,
	TemplateModalSize,
	Loader,
	Drawer,
	ReleaseNotes,
	ReleaseNoteItemProps,
	DrawerType,
	DrawerSize,
	DrawerLayout,
	Error,
	ErrorType,
	ErrorContext,
} from "@mit/hui";
import Devices from "../devices/Devices";
import Status from "../status/Status";
import Reports from "../reports/Reports";
import Pairs from "../pairs/Pairs";
import Modal from "../common/Modal";
import AppController from "../api/AppController";
import AuthProvider from "../services/AuthProvider";
import appConfig from "../app-config";
import ProfileController from "../api/ProfileController";
import { bindActionCreators } from "redux";
import * as appActionCreator from "../common/redux/actions/app";
import * as notificationActionCreator from "../common/redux/actions/notification";
import * as lookupActionCreator from "../common/redux/actions/lookups";
import { connect, Provider } from "react-redux";
import ProfileView from "../common/ProfileView";
import InformationModal from "../common/InformationModal";
import { BannerMessageModel } from "../api/models/BannerMessageModel";
import ErrorBoundary from "../common/ErrorBoundry";
import { withComponent } from "../common/WithComponent";
import Notification from "./Notification";
import { uuidv4 } from "../common/redux/actions/notification";
import { store } from "../common/redux/store/store";
import ReactDOM from "react-dom";
import AccountController from "../api/AccountController";
import DeviceController from "../api/DeviceController";
import PairController from "../api/PairController";
import { UserRoleType } from "../api/models/UserRoleModel";
import jwt_decode from "jwt-decode";
import moment from "moment";
export const globalStore = store;

interface IAppProps {
	appActions?: any;
	notificationActions?: any;
	lookupActions?: any;
	adminView: boolean;
	roles?: any;
	userRole: UserRoleType;
}
interface AppState {
	isLoading: boolean;
	isReady: boolean;
	isUnauth: boolean;
	bannerMessages: BannerMessageModel[];
	showStatusModal: boolean;
	showReportModal: boolean;
	showHelpModal: boolean;
	showReleaseNotesDrawer: boolean;
	showSessionTimeout: boolean;
}

class App extends React.Component<IAppProps, AppState> {
	appController: AppController;
	authProvider: AuthProvider;
	profileController: ProfileController;
	accountController: AccountController;
	deviceController: DeviceController;
	pairController: PairController;
	private _container: any;
	private _child: any;

	constructor(props: any) {
		super(props);

		this.state = {
			isLoading: true,
			isReady: false,
			isUnauth: false,
			bannerMessages: [],
			showStatusModal: false,
			showReportModal: false,
			showHelpModal: false,
			showReleaseNotesDrawer: false,
			showSessionTimeout: false,
		};
		this.authProvider = new AuthProvider(this.timeout);

		this.appController = new AppController(this.authProvider);
		this.profileController = new ProfileController(this.authProvider);
		this.accountController = new AccountController(this.authProvider);
		this.deviceController = new DeviceController(this.authProvider);
		this.pairController = new PairController(this.authProvider);

		//Page Redirect for Prod
		if (window.location.hostname === "voip.hcp.mit.edu") {
			window.location.replace("http://voip.mit.edu/");
		}
	}

	async componentDidMount() {
		this._container = document.createElement("div");
		document.documentElement.appendChild(this._container);
		this._child = ReactDOM.render(this.renderNotification(), this._container);

		await this.authProvider.signIn()

		await Promise.all([
			this.profileController.getProfile().then((data) => {
				if (data) this.props.appActions.changeUser(data);
			}),
			this.profileController.getPicture().then((data) => {
				if (data) this.props.appActions.changePicture(data);
			}),
			this.appController.getAdminRoles().then((data) => {
				if (data) {
					this.props.appActions.changeRoles(data);
					this.props.appActions.changeUserRole(data);
				}
			}),
			this.appController.getBannerMessages().then((data) => {
				if (data) {
					data.forEach((bannerMessage: BannerMessageModel) => {
						let notificationId = uuidv4();
						this.props.notificationActions.addNotification(
							notificationId,
							bannerMessage.title,
							"Announcement",
							this.htmlToText(bannerMessage.message),
							bannerMessage.cancel ? <Button text="Dont show this message again" type={ButtonType.Primary} onClick={() => this.clearBannerMessage(bannerMessage, notificationId)} /> : [],
							bannerMessage.style === "I" ? 0 : 1
						);
					});
				}
			}),
			this.accountController.getAccountTypes().then((data) => {
				if (data) this.props.lookupActions.changeAccountTypes(data);
			}),
			this.pairController.getSwitchSites().then((data) => {
				if (data) this.props.lookupActions.changeSwitchSites(data);
			}),
			this.pairController.getFramePartitions().then((data) => {
				if (data) this.props.lookupActions.changeFramePartitions(data);
			}),
		]);

		await this.getLookupData();

		setTimeout(() => {
			this.setState({ isLoading: false });
		}, 500);
		setTimeout(() => {
			this.setState({ isReady: true });
		}, 1000);

		//this.setSessionTimeout();
		await this.checkSessionInvalid()
	}

	// setSessionTimeout = () => {
	// 	const token = localStorage.getItem("sessionTimeout");
	// 	if (token) {
	// 		const decodedToken: any = jwt_decode(token);
	// 		const expirationDate = moment.unix(decodedToken.exp);
	// 		const currentDate = moment();
	// 		const timeDifference = expirationDate.diff(currentDate, "millisecond");
	// 		setTimeout(() => this.setState({ showSessionTimeout: true }), timeDifference);
	// 	}
	// };

	//If we can get a token, then the session should still be valid, otherwise require a re-login
	checkSessionInvalid = async () => {
		const checkToken = await this.authProvider.getToken()

		if(checkToken == null)
			this.timeout()

		//Token is still valid, check again in a bit
		if(!this.state.showSessionTimeout){
			const tenMinutes = 600000
			setTimeout(() => this.checkSessionInvalid(),tenMinutes);
		}
	}

	timeout(){
		this.setState({ showSessionTimeout: true})
	}

	expireSession = () => {
		localStorage.clear();
		this.appController.getHeartBeat();
	};

	getLookupData = async () => {
		const voicemailTypes = await this.accountController.getVoicemailTypes();
		const deviceModels = await this.deviceController.getDeviceModels();
		const firmwareTypes = await this.deviceController.getFirmwareTypes();

		this.props.lookupActions.changeVoicemailTypes(voicemailTypes);
		this.props.lookupActions.changeDeviceModels(deviceModels);
		this.props.lookupActions.changeFirmwareTypes(firmwareTypes);

		if (this.props.userRole !== "HELPDESK" && this.props.userRole !== "UNPRIV_USER") {
			const pairStatuses = await this.pairController.getPairStatuses();
			this.props.lookupActions.changePairStatuses(pairStatuses);
		}
	};

	htmlToText = (html: string): string => {
		return html;
	};

	getEnv() {
		if (this.props.adminView) return "admin";

		if (appConfig.stage.name === "develop") return "dev";

		if (appConfig.stage.name === "release") return "staging";

		if (appConfig.stage.name === "master") return "production";

		return "production";
	}

	clearBannerMessage = (bannerMessage: BannerMessageModel, notificationId: string) => {
		this.appController.deleteBannerMessage(bannerMessage.id.toString());
		this.props.notificationActions.closeNotification(notificationId);
	};

	closeToast(id: number) {
		let banners = this.state.bannerMessages;

		let Itemindex = this.state.bannerMessages.findIndex((itm) => {
			return itm.id === id;
		});

		banners.splice(Itemindex, 1);

		this.setState({ bannerMessages: banners });
	}

	componentDidUpdate() {
		ReactDOM.hydrate(this.renderNotification(), this._container);
		if (!this._child) return;
		this._child.setState({});
	}
	componentWillUnmount() {
		ReactDOM.unmountComponentAtNode(this._container);
		document.documentElement.removeChild(this._container);
	}

	renderNotification() {
		return (
			<Provider store={globalStore}>
				<Notification />
			</Provider>
		);
	}

	render() {
		if (document.location.pathname.includes("user") && document.location.pathname.includes("pairs")) {
			History.push("/");
		}
		
		if(document.location.pathname.startsWith("/user/") && this.props.adminView){
			return <Redirect to="/admin/accounts" />;
		}

		if (this.state.isUnauth) return <Loader contactEmail="info@mit.edu" hasAccess={false} exit={!this.state.isLoading} name={"MITvoip"} />;

		if (!this.state.isReady) return <Loader contactEmail="" exit={!this.state.isLoading} name={"MITvoip"} />;

		const releaseNotes: ReleaseNoteItemProps[] = [
			{
				date: "12/22/2020",
				items: [
					{
						heading: "Improved Interface",
						description: "Upgraded voip.mit.edu look and feel",
					},
				],
			},
		];

		const utilNavItems = [
			{
				key: "utility-action-4",
				icon: "question-circle",
				text: "Help",
				iconOnly: true,
				onClick: () => {
					this.setState({ showHelpModal: true });
				},
			},
			{
				icon: "bell",
				text: "What's new!",
				iconOnly: true,
				onClick: () => {
					this.setState({ showReleaseNotesDrawer: true });
				},
			},
			{
				key: "utility-action-2",
				icon: "file-chart-line",
				text: "Reports",
				iconOnly: true,
				onClick: () => {
					this.setState({ showReportModal: true });
				}
			}
		];

		if (this.props.adminView)
			utilNavItems.unshift(
				{
					key: "utility-action-1",
					icon: "chart-pie",
					text: "API Status",
					iconOnly: true,
					onClick: () => {
						this.setState({ showStatusModal: true });
					}
				}
			);

		const NavigationData = withComponent(Navigation);

		if (!this.props.roles.ONLINE) {
			return (
				<Loader
					contactEmail="info@mit.edu"
					hasAccess={false}
					exit={!this.state.isLoading}
					name="MITVoip"
					type={"offline"}
					message={
						"This is a scheduled period (usually less than 2 hours) of downtime to perform necessary maintenance or updates to the application. We apologize for the inconvenience that this may cause. Please inform the IS&T Service Desk of any concerns that you may have about the planned outage."
					}
				/>
			);
		}

		return (
			<Fragment>
				<ErrorBoundary errorComponent={<Error message={"Something went wrong."} type={ErrorType.Generic} context={ErrorContext.Page} />}>
					<Router history={History}>
						<AtlasPage
							environment={this.getEnv()}
							utilityNavigation={utilNavItems}
							gaId="G-M8C1WVYGEK"
							name={"MITvoip " + (this.props.adminView ? "Admin" : "")}
							navigation={<NavigationData />}
							profile={<ProfileView />}
							content={
								<Switch>
									<Route
										exact
										path="/"
										render={() => {
											return <Redirect to="/user/accounts" />;
										}}
									/>
									<Route path={"/:admin/accounts/:type?/:id?"} component={Accounts} />
									<Route path={"/:admin/devices/:id?"} component={Devices} />
									<Route path={"/:admin/pairs"} component={Pairs} />
									<Route path={"/:admin/pairs/pair-tracking/:id?"} component={Pairs} />
									<Route path={"/:admin/pairs/pair-range-tracking/:id?"} component={Pairs} />
								</Switch>
							}
						/>
					</Router>
				</ErrorBoundary>
				<Modal
					show={this.state.showStatusModal}
					onClose={() => this.setState({ showStatusModal: false })}
					containerless={false}
					size={TemplateModalSize.Large}
					body={<Status />}
					footer={<Button text="Close" type={ButtonType.Primary | ButtonType.Ghost} onClick={() => this.setState({ showStatusModal: false })} />}
					header={<Text content="API Status" type={TextType.Heading4} icon="" />}
					name={"statusModal"}
				/>
				<Modal
					show={this.state.showReportModal}
					onClose={() => this.setState({ showReportModal: false })}
					containerless={false}
					size={TemplateModalSize.Large}
					body={<Reports />}
					footer={<Button text="Close" type={ButtonType.Primary | ButtonType.Ghost} onClick={() => this.setState({ showReportModal: false })} />}
					header={<Text content="Reports" type={TextType.Heading4} icon="" />}
					name={"reportModal"}
				/>
				<InformationModal
					show={this.state.showHelpModal}
					onClose={() => this.setState({ showHelpModal: false })}
					name="helpModal"
					title="Help"
					body={
						<Fragment>
							<b>More Info</b>
							<br />
							<a href="http://kb.mit.edu/confluence/x/XUmACQ" rel="noopener noreferrer">
								MITvoip Account and Device Management
							</a>
							<br />
							<br />
							<b>Need help? Contact</b>
							<br />
							<a href="/">IS&T Service Desk</a>
							<br />
							<br />
							<b>Phone and Email</b>
							<br />
							24 hours a day, 7 days a week
							<br />
							<a href="mailto:servicedeskt@mit.edu">servicedesk@mit.edu</a>
							<br />
							<br />
							<b>Walk-ins</b>
							<br />
							Monday - Friday, 8am-5pm
							<br />
							Building:{" "}
							<a href="https://goo.gl/maps/uTvboSeb5EwniE6i8" target="_blank" rel="noopener noreferrer">
								E17-106
							</a>
							<br />
							<br />
							<b>Voicemail</b>
							<br />
							To listen to Voicemail messages call <a href="tel:617-258-6245">617-258-6245</a>
							<br />
							or <a href="tel:617-25V-MAIL">617-25V-MAIL</a> and enter your phone number and passcode
						</Fragment>
					}></InformationModal>
				<Drawer
					show={this.state.showReleaseNotesDrawer}
					onClose={() => this.setState({ showReleaseNotesDrawer: false })}
					type={DrawerType.Right}
					size={DrawerSize.Medium}
					layout={DrawerLayout.Hero}
					header={<Text content="What's New!" type={TextType.Heading4} icon="" />}
					contents={<ReleaseNotes items={releaseNotes} />}
					footer={[]}
				/>

				{/* Session Timeout Modal */}
				<Modal
					size={TemplateModalSize.Small}
					show={this.state.showSessionTimeout}
					onClose={this.expireSession}
					containerless={false}
					body={<Text content="Your session has expired, the page will be reloaded." />}
					footer={<Button text="OK" type={ButtonType.Primary | ButtonType.Ghost} onClick={this.expireSession} />}
					header={<Text content="Session Timeout" type={TextType.Heading4} icon="" />}
					name={"sessionModal"}
				/>
			</Fragment>
		);
	}
}

const mapDispatchToProps = (dispatch: any) => ({
	appActions: bindActionCreators(appActionCreator, dispatch),
	notificationActions: bindActionCreators(notificationActionCreator, dispatch),
	lookupActions: bindActionCreators(lookupActionCreator, dispatch),
});

const mapStateToProps = (state: any) => ({
	user: state.app.user,
	roles: state.app.roles,
	userRole: state.app.userRole,
	adminView: state.app.adminView,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
