import React from 'react';
import { TemplateModalSize, Text, TextType } from '@mit/hui';
import { withComponent } from './WithComponent';
import Modal from './Modal';

interface ConfirmationProps
{
    show: boolean;
    onClose: any;
    body: any;
    title: string;
    name: string;
}

export default class InformationModal extends React.Component<
    ConfirmationProps
    > {
    render()
    {
        const ModalData = withComponent(Modal);

        return (
            <ModalData
                show={this.props.show}
                onClose={this.props.onClose}
                containerless={false}
                size={TemplateModalSize.Default}
                body={this.props.body}
                footer={[]}
                header={
                    <Text
                        content={this.props.title}
                        type={TextType.Heading4}
                        icon='info-circle'
                    />
                }
                name={this.props.name}
            />
        );
    }
}
