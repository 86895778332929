// eslint-disable-next-line
import React, { Fragment } from "react";
import {
	FormContainer,
	GridContainer,
	LayoutColumn,
	Text,
	TextType,
	Button,
	ButtonType,
	DropdownItemProps,
	FileUpload,
	Icon,
	AdvancedTable,
	IAdvancedTableColumn,
	ButtonProps,
	ActionList,
	TemplateModalSize,
	Error,
	ErrorContext,
	ErrorType,
	FormField,
	CFileError,
} from "@mit/hui";
import "./Accounts.css";
import AccountController from "../api/AccountController";
import { CallTreeModel, CallTreeOption, KeyOptions, Keys } from "../api/models/CallTreeModel";
import { ChangeLogModel } from "../api/models/ChangeLogModel";
/**
 * Upgrade to HUI 1.0.139 broke the below import. 
 * I copied the original TableRowProps interface
 * and declared it lower down for use.
 */
//import { TableRowProps } from "@mit/hui/build/components/Table/TableRow";
import Modal from "../common/Modal";
import StringProvider from "../services/StringProvider";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as notificationActionCreator from "../common/redux/actions/notification";
import { uuidv4 } from "../common/redux/actions/notification";
import AuthProvider from "../services/AuthProvider";

interface ICallTreeProps {
	accountId: string;
	actions?: any;
	account_type:string;

}
interface CallTreeState {
	accountUUID: string;
	callTree: CallTreeModel;
	changeLogs: ChangeLogModel[];
	showUpload: boolean;
	isLoading: boolean;
	selectedCalltreeKey: keyof Keys;
	showEditModal: boolean;
	callTreeOptions: DropdownItemProps[];
	actions: CallTreeOption[];
	formError: boolean;
	isBusy: boolean;
	isError: boolean;
	uploadIsBusy: boolean;
	calltreeConfigurationHelpText: string;
}
interface TableRowProps
{
    state: string, //unused still.
    items: any[]
}
class CallTreeDetail extends React.Component<ICallTreeProps, CallTreeState> {
	accountId: string = this.props.accountId;
	callTreeData: TableRowProps[];
	callTreeKeys: Array<keyof Keys>;
	newCallTreeOption: KeyOptions;
	_isMounted: boolean;

	accountController: AccountController;
	stringProvider: StringProvider;
	authProvider: AuthProvider

	constructor(props: any) {
		super(props);

		//This class did not take any special action on timeout
		//It will continue to act in such a way
		const onSessionTimeout = () => {}
		this.authProvider = new AuthProvider(onSessionTimeout)
		this.accountController = new AccountController(this.authProvider);
		this.stringProvider = new StringProvider();

		this.state = {
			accountUUID: this.accountId,
			callTree: {
				businessHoursMenu: {
					keys: {
						1: {},
						2: {},
						3: {},
						4: {},
						5: {},
						6: {},
						7: {},
						8: {},
						9: {},
						0: {},
						"#": {},
						"*": {},
					},
				},
			},
			changeLogs: [],
			showUpload: false,
			isLoading: false,
			selectedCalltreeKey: "0",
			showEditModal: false,
			callTreeOptions: [],
			formError: false,
			actions: [],
			isBusy: false,
			isError: false,
			uploadIsBusy: false,
			calltreeConfigurationHelpText: "",
		};

		this.callTreeKeys = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "#", "*"];
		this.callTreeData = [];
	}

	componentDidMount() {
		this._isMounted = true;

		const fetchCallTreeData = async () => {
			this.setState({ isLoading: true });
			this.accountController.getCalltreeActions(this.props.account_type).then((response:any) => {
				if (response) {
					this.setState({
						callTreeOptions: this.constructCallTreeOptions(response.action_options),
						actions: response,
						calltreeConfigurationHelpText: response.calltree_configuration_help_text,
					});
				}
			});

			this.accountController.getCallTreeMenu(this.state.accountUUID).then((response) => {
				if (response) {
					this.setState({
						callTree: this.constructCalltree(response),
					});
				} else {
					this.setState({ isError: true });
				}
				this.setState({ isLoading: false });
			});

			this.accountController.getChangeLog(this.state.accountUUID).then((changeLogs) => {
				if (changeLogs) this.setState({ changeLogs: changeLogs });
			});
		};

		//Calls
		this._isMounted && fetchCallTreeData();
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	constructCalltree = (callTree: CallTreeModel): CallTreeModel => {
		let newCallTree = this.state.callTree;
		newCallTree.businessHoursMenu.audio_file = callTree.businessHoursMenu.audio_file;
		Object.keys(newCallTree.businessHoursMenu.keys).forEach((key: keyof Keys) => {
			newCallTree.businessHoursMenu.keys[key] = callTree.businessHoursMenu.keys[key] || {
				action: "",
				description: "",
				did: "",
				text: "",
				key: "",
			};
		});
		return newCallTree;
	};

	constructCallTreeOptions = (callTreeOptions: CallTreeOption[]): DropdownItemProps[] => {
		let dropdownData: DropdownItemProps[] = [];

		callTreeOptions.forEach((option: CallTreeOption) => {
			if (option.enabled) {
				dropdownData.push({
					id: option.key,
					text: option.text,
					icon: "",
				} as DropdownItemProps);
			}
		});
		return dropdownData;
	};
	
	getCallTreeOptions = (key:any, actions:any) => {
		let dropdownData: DropdownItemProps[] = [];
		let options =  actions.action_options.filter((option:any) => option.key === key)[0].options
		options.forEach((option: CallTreeOption) => {
			if (option.enabled) {
				dropdownData.push({
					id: option.key,
					text: option.text,
					icon: "",
				} as DropdownItemProps);
			}
		});
		return dropdownData;
	}

	getAcceptedMimeTypes = (actions:any) => {
		let acceptedMimeTypes = ["audio/wave","audio/wav","audio/x-wav","audio/x-pn-wav"];
		let mp3MimeTypes = ["audio/mpeg","audio/mpg","audio/mp3","audio/x-mp3","audio/x-mpeg","audio/x-mpegaudio","audio/x-mpg"];
		if (actions.file_types.includes("mp3"))
			acceptedMimeTypes = acceptedMimeTypes.concat(mp3MimeTypes)
		return acceptedMimeTypes;
	}

	getFileType = (fileType:string) => {
		let mp3MimeTypes = ["audio/mpeg","audio/mpg","audio/mp3","audio/x-mp3","audio/x-mpeg","audio/x-mpegaudio","audio/x-mpg"];
		if (mp3MimeTypes.includes(fileType))
			return "mp3";
		return "wav";
	}

	generateAudioError = (actions:any) => {
		if (actions.file_types && actions.file_types.length > 1)
			return "The file must be of type wav or mp3. Please select a wav or mp3 file and try again.";
		return "The file must be of type wav. Please select a wav file and try again.";
	}

	getActionKey = (text: string, key:string, actions:any) => {	
		let options = actions.action_options.filter((option:any) => option.key === key)[0].options
		return options.find((action:any) => action.text === text)?.key || "";
	};

	showPhoneIput = (text: any, key:string, actions:any) => {
		let options = actions.action_options.filter((option:any) => option.key === key)[0].options;
		return options.find((action:any) => action.text === text)?.uses_phone_number;
	}
	showDescriptionInput = (text: any, key:string, actions:any) => {
		let options = actions.action_options.filter((option:any) => option.key === key)[0].options;
		return options.find((action:any) => action.text === text)?.uses_description;
	}

	updateCalltree = () => {
		this.setState({ isBusy: true });
		this.accountController.updateCallTree(this.state.accountUUID, this.state.callTree).then((response) => {
			if (response) {
				this.setState({
					callTree: this.constructCalltree(response),
				});
				this.props.actions.addNotification(uuidv4(), "Success", "Update", "Call Tree was Successfully Updated");
			}
			this.setState({ isBusy: false });
		});
	};

	uploadFile = async (file: any) => {
		if (file) {
			let fileType = this.getFileType(file.type);
			let binary: any = await this.toArrayBuffer(file);
			if (binary) {
				this.setState({ uploadIsBusy: true });
				this.accountController
					.uploadCallTreeAnnouncement(this.state.accountUUID, file.name, binary, fileType)
					.then((response) => {
						if (response) {
							let updatedCalltree = this.state.callTree;
							updatedCalltree.businessHoursMenu.audio_file = {
								file_name: "new_file",
								file_type: fileType,
							};
							this.setState({
								showUpload: false,
								callTree: updatedCalltree,
							});
							this.props.actions.addNotification(uuidv4(), "Success", "File Upload", "Active announcement has successfully been uploaded");
						}
						this.setState({ uploadIsBusy: false });
					})
					.catch(() => this.props.actions.addNotification(uuidv4(), "Error", "File Upload", "Active announcement has not been uploaded", [], 1));
			}
		}
	};

	toArrayBuffer = (file: File) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();

			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result?.toString().replace(/^data:.+;base64,/, ""));
			reader.onerror = (error) => reject(error);
		});

	render() {
		if (this.state.isError) return <Error type={ErrorType.Generic} message="Failed to retrieve Calltree Details" context={ErrorContext.Component} />;

		if (this.state.isLoading) return <ActionList isLoading items={[]} />;

		const headers: IAdvancedTableColumn[] = [
			{
				id: "option",
				name: "Option",
			},
			{
				id: "action",
				name: "Action",
			},
			{
				id: "phoneNumber",
				name: "Phone Number",
			},
			{
				id: "description",
				name: "Description",
			},
		];

		let tableData: any[] = [];

		this.state.callTree &&
			this.callTreeKeys.map((key: keyof Keys) => {
				let tableItem = {
					option: key,
					action: this.state.callTree.businessHoursMenu.keys[key]?.action || "No Action",
					phoneNumber: this.state.callTree.businessHoursMenu.keys[key]?.did ? this.state.callTree.businessHoursMenu.keys[key].did : "",
					description: this.state.callTree.businessHoursMenu.keys[key]?.description || "",
					calltree: this.state.callTree,
				};

				tableData.push(tableItem);

				return null;
			});

		const actionButtons: ButtonProps[] = [
			{
				icon: "edit",
				type: ButtonType.IconNaked,
				onClick: (e: any) => {
					this.setState({
						showEditModal: true,
						selectedCalltreeKey: e.option,
					});
				},

				text: "Edit",
				state: 0,
				padded: false,
			},
		];

		const customErrorText: CFileError[] = [
			{
			  type: 'audio', 
			  code: 'file-too-large', 
			  message: "Audio file must be less than 1 MB. Please reduce the file size and try again."
			},
			{
			  type: 'application',
			  code: 'file-invalid-type',
			  message: "The file must be of type wav. Please select a wav file and try again."
			},
			{
			  type: 'image',
			  code: 'file-invalid-type',
			  message: "The file must be of type wav. Please select a wav file and try again."
			},
			{
			  type: 'audio',
			  code: 'file-invalid-type',
			  message: this.generateAudioError(this.state.actions)
			}
		  ]

		return (
			<Fragment>
				{this.state.changeLogs.length > 0 ? (
					<div className="metadata">
						<GridContainer showGutters={true}>
							<LayoutColumn colSize={6}>
								<Text type={TextType.Body} content="Created By" padded={false} />
							</LayoutColumn>
							<LayoutColumn colSize={6}>
								<Text
									type={TextType.Body}
									content={this.state.changeLogs.filter((log) => log.action === "add")[0].editor + " " + this.state.changeLogs.filter((log) => log.action === "add")[0].action_date}
									padded={false}
								/>
							</LayoutColumn>
						</GridContainer>
					</div>
				) : (
					<div></div>
				)}
				{this.state.changeLogs.length > 0 ? (
					<div className="metadata mb-4">
						<GridContainer showGutters={true}>
							<LayoutColumn colSize={6}>
								<Text type={TextType.Body} content="Modified By" padded={false} />
							</LayoutColumn>
							<LayoutColumn colSize={6}>
								<Text
									type={TextType.Body}
									content={this.state.changeLogs[this.state.changeLogs.length - 1].editor + " " + this.state.changeLogs[this.state.changeLogs.length - 1].action_date}
									padded={false}
								/>
							</LayoutColumn>
						</GridContainer>
					</div>
				) : (
					<div></div>
				)}
				<GridContainer showGutters={true}>
					<LayoutColumn colSize={12}>
						<Text type={TextType.Heading3} content="Call Tree Configuration" />
						<div>	
							<p>
								<Text content={<><span dangerouslySetInnerHTML={{__html: this.state.calltreeConfigurationHelpText}}></span></>} />
							</p>
						</div>
						
						<strong>
							<Icon icon={"info-circle"} type={"regular"} />
							{this.state.callTree.businessHoursMenu.audio_file && this.state.callTree.businessHoursMenu.audio_file.file_name
								? "This call tree has an active announcement"
								: "This call tree does not have active announcement"
							}
						</strong>
						<p></p>
						{!this.state.showUpload && (
							<Button
								type={ButtonType.TextNaked}
								text={`Upload ${this.state.callTree.businessHoursMenu.audio_file ? "a new" : `an`} announcement file`}
								icon="upload"
								onClick={() => this.setState({ showUpload: true })}
							/>
						)}
						{this.state.showUpload && <Button type={ButtonType.TextNaked} text="Cancel" icon="times" onClick={() => this.setState({ showUpload: false })} />}
						<p></p>
						{this.state.showUpload && (
							<div key={"Upload"}>
								<FileUpload
									key={"file Upload"}
									isLoading={this.state.uploadIsBusy}
									maxSize={1000000}
									customErrorText={customErrorText}
									acceptedMimeTypes={this.getAcceptedMimeTypes(this.state.actions)}
									text="Drop file here or click attachment icon to browse"
									onConfirm={(file) => this.uploadFile(file)}
								/>
							</div>
						)}
						<p></p>
						<p>
							Please review the announcement file requirement on{" "}
							<a href="http://kb.mit.edu/confluence/x/yRCVCQ" rel="noopener noreferrer" target="_blank">
								MITvoip Call Tree Management
							</a>{" "}
							before uploading your file.
						</p>
					</LayoutColumn>
				</GridContainer>
				<FormContainer id="Calltree Form" submitText="Save" action={this.updateCalltree} actionIsBusy={this.state.isBusy} isValid={(e: any) => null} formValidationTrigger="onChange">
					<GridContainer showGutters={true}>
						<LayoutColumn colSize={12}>
							<AdvancedTable columns={headers} items={tableData} maxRows={100} actionButtons={actionButtons} />
						</LayoutColumn>
					</GridContainer>
				</FormContainer>

				{this.state.showEditModal && <Modal
					show={this.state.showEditModal}
					onClose={() => this.setState({ showEditModal: false })}
					containerless={false}
					size={TemplateModalSize.Default}
					body={
						<Fragment>
							<FormContainer
								formValidationTrigger="onChange"
								isValid={(e: any) => this.setState({ formError: !e })}
								action={() => this.setState({ showEditModal: this.state.formError })}
								submitText="Done"
								id="calltreeEditForm">
								<GridContainer showGutters={true}>
									<LayoutColumn colSize={12}>
										<FormField label="Option" id="key" editor="textbox" value={this.state.selectedCalltreeKey} readonly />
										<FormField
											id="action"
											editor="dropdown"
											label="Action"
											value={this.state.callTree.businessHoursMenu.keys[this.state.selectedCalltreeKey].action || "No Action"}
											options={this.getCallTreeOptions(this.state.selectedCalltreeKey, this.state.actions)}
											onChange={(e: any) => {
												let updatedCalltree = this.state.callTree;

												updatedCalltree.businessHoursMenu.keys[this.state.selectedCalltreeKey].action = e.text;
												updatedCalltree.businessHoursMenu.keys[this.state.selectedCalltreeKey].key = this.getActionKey(e.text, this.state.selectedCalltreeKey, this.state.actions);
												updatedCalltree.businessHoursMenu.keys[this.state.selectedCalltreeKey].text = e.text;
												if (e.text !== "transfer_with_prompt" && e.text !== "transfer_without_prompt") {
													updatedCalltree.businessHoursMenu.keys[this.state.selectedCalltreeKey].did = undefined;
													updatedCalltree.businessHoursMenu.keys[this.state.selectedCalltreeKey].description = undefined;
												}

												this.setState({ callTree: updatedCalltree });
											}}
										/>
										
										{(this.showPhoneIput(this.state.callTree.businessHoursMenu.keys[this.state.selectedCalltreeKey].text, this.state.selectedCalltreeKey, this.state.actions)) && (
											<FormField
												id="number"
												editor="textbox"
												label="Phone Number"
												placeholder="Phone Number"
												value={this.state.callTree.businessHoursMenu.keys[this.state.selectedCalltreeKey]?.did}
												pattern={{
													value: /^[0-9]{1,30}$/,
													message: "Numeric between 1 -30",
												}}
												required={true}
												onChange={(e: any) => {
													let updatedCalltree = this.state.callTree;
													updatedCalltree.businessHoursMenu.keys[this.state.selectedCalltreeKey].did = e.currentTarget.value;
													this.setState({ callTree: updatedCalltree });
												}}
											/>
										)}
										{(this.showDescriptionInput(this.state.callTree.businessHoursMenu.keys[this.state.selectedCalltreeKey].text, this.state.selectedCalltreeKey, this.state.actions)) && (
											<FormField
												id="description"
												editor="textbox"
												label="Description"
												placeholder="Description"
												value={this.state.callTree.businessHoursMenu.keys[this.state.selectedCalltreeKey]?.description}
												onChange={(e: any) => {
													let updatedCalltree = this.state.callTree;
													updatedCalltree.businessHoursMenu.keys[this.state.selectedCalltreeKey].description = e.currentTarget.value;
													this.setState({ callTree: updatedCalltree });
												}}
											/>
										)}
									</LayoutColumn>
								</GridContainer>
							</FormContainer>
						</Fragment>
					}
					footer={[]}
					header={<Text content="Edit Call Tree Configuration" type={TextType.Heading4} icon="" />}
					name={"showEditModal"}
				/>}
			</Fragment>
		);
	}
}

const mapDispatchToProps = (dispatch: any) => ({
	actions: bindActionCreators(notificationActionCreator, dispatch),
});

export default connect(null, mapDispatchToProps)(CallTreeDetail);
