import React, { Fragment } from "react";
import {
	Button,
	ButtonState,
	ButtonType,
	Drawer,
	Text,
	TextType,
	DrawerType,
	DrawerLayout,
	DrawerSize,
	NavBar,
	NavBarTypes,
	NavItemProps,
	TemplateModalSize,
	Textbox,
	TextboxType,
	GridContainer,
	LayoutColumn,
	Error,
	ErrorContext,
	ErrorType,
	ActionList,
	Spacer,
	AdvancedTable,
	IAdvancedTableColumn,
	Icon,
	ButtonProps,
	Tooltip,
	Label,
} from "@mit/hui";
import "./Accounts.css";
import AccountController from "../api/AccountController";
import { AccountModel, UserModel } from "../api/models/AccountModel";
import AccountDetail from "./AccountDetail";
import Modal from "../common/Modal";
import ConfirmationModal from "../common/ConfirmationModal";
import { withComponent } from "../common/WithComponent";
import CallTreeDetail from "./CallTreeDetail";
import { SystemModel } from "../api/models/SystemModel";
import { connect, Provider } from "react-redux";
import { store } from "../common/redux/store/store";
import History from "../common/History";
import { UserRoleType } from "../api/models/UserRoleModel";
import CardEntity from "../common/CardEntity";
import StringProvider from "../services/StringProvider";
import { bindActionCreators } from "redux";
import * as notificationActionCreator from "../common/redux/actions/notification";
import { uuidv4 } from "../common/redux/actions/notification";
import VoicemailPasscode from "./VoicemailPasscode";
import AccountServiceConversion from "./AccountServiceConversion";
import AuthProvider from "../services/AuthProvider";
export const globalStore = store;

interface AccountState {
	showAccount: boolean;
	account: AccountModel;
	showCallTree: boolean;
	showLeaveModal: boolean;
	accountList: AccountModel[] | null;
	accountItems: AccountModel[];
	accountSystems: SystemModel[];
	isLoading: boolean;
	showSystemLookupModal: boolean;
	showSipModal: boolean;
	showVoicmailPinModal: boolean;
	systems: Array<any>;
	loadingSystems: boolean;
	isBusy: boolean;
	userRole: UserRoleType;
	isPristine: boolean;
	searchCriteria: string;
	formError: boolean;
	showManualSipModal: boolean;
	sipPasswordManual: boolean;
	sipIsBusy: boolean;
	accountPassword: string;
	callTreeFromList: boolean;
	callControlBanner: any;
	drawerActions: [];
	activation_code: string;
	showAccountServiceConversionModal: boolean;
	accountServiceConversionIsBusy: boolean;
	offset: number;
	limit: number;
	accountsDataCache: { [key: number]: AccountModel[] }
	totalCount: number;
}

interface IAccountListProps {
	accountId: string;
	accountType: string;
	adminView?: any;
	impersonate?: string;
	tab: string;
	actions?: any;
}

class AccountList extends React.Component<IAccountListProps, AccountState> {
	accountController: AccountController;
	stringProvider: StringProvider;
	newAccount: AccountModel;
	newVoicemailPin: string;
	newSipPassword: string;
	authProvider: AuthProvider;

	constructor(props: any) {
		super(props);

		//This class did not take any special action on timeout
		//It will continue to act in such a way
		const onSessionTimeout = () => {}
		this.authProvider = new AuthProvider(onSessionTimeout)
		this.accountController = new AccountController(this.authProvider);
		this.stringProvider = new StringProvider();

		this.newAccount = {
			account_type: "",
			account_service: this.props.accountType ? this.props.accountType : "",
			did: null,
			owners: [],
			admins: [],
			assigned_phones: [],
			first_name: "",
			last_name: "",
			display_name:"",
			description: "",
			location: "",
			active: true,
			activation_code: ""
		};

		this.state = {
			showAccount: false,
			account: this.newAccount,
			showCallTree: false,
			showLeaveModal: false,
			accountList: null,
			accountItems: [],
			accountSystems: [],
			isLoading: false,
			showSystemLookupModal: false,
			showSipModal: false,
			showVoicmailPinModal: false,
			systems: [],
			loadingSystems: false,
			isBusy: false,
			userRole: globalStore.getState().app.userRole,
			isPristine: true,
			searchCriteria: "",
			formError: false,
			showManualSipModal: false,
			sipPasswordManual: false,
			sipIsBusy: false,
			accountPassword: "",
			callTreeFromList: false,
			callControlBanner: null,
			drawerActions: [],
			activation_code: "",
			showAccountServiceConversionModal: false,
			accountServiceConversionIsBusy: false,
			offset: 1,
			limit: 20,
			totalCount: 0,
			accountsDataCache: {}

		};
		this.newVoicemailPin = "";
		this.newSipPassword = "";
	}

	async componentDidMount() {
		if ((!this.props.accountId && !this.props.adminView) || this.props.impersonate) this.searchAccounts(null, "");
		if (this.props.accountId && !this.props.accountId.includes("search") && !this.props.adminView) this.searchAccounts(null, "");
		if (!this.props.accountId && this.props.adminView) this.setState({ searchCriteria: "", accountList: [] });

		if (this.props.accountId) {
			if (this.props.accountId.includes("search")) {
				const did = this.props.accountId.replace("search-", "");
				this.setState({ searchCriteria: did });
				this.searchAccounts(null, did);
			} else {
				let accountUUID = await this.accountController.getAccountLookup(this.props.accountId);

				let account: AccountModel = this.newAccount;
				account.did = this.props.accountId;
				account.uuid = accountUUID;
				account.account_service = this.props.accountType;
				this.setState({
					accountPassword: account.password || "",
					showAccount: true,
					account: account,
				});
			}
		}
	}

	async componentDidUpdate(previousProps: any) {
		if (previousProps !== this.props) {
			// Reset state to initial values when tab changes
			this.setState({
				offset: 1, // Set offset to 1 to start from the first page
				accountList: null, // Reset the account list
				accountsDataCache: {}, // Clear the cache if you're using one
				// ... reset any other relevant state
			}, () => {
				// After state reset, fetch accounts for the new tab
				if (!this.props.adminView && !this.props.impersonate) this.searchAccounts(null, "");
				if (!this.props.adminView && this.props.impersonate) this.searchAccounts(null, this.state.searchCriteria || "");
				if (this.props.adminView && !this.props.accountId) this.setState({searchCriteria: "", accountList: []});
			});
		}
	}
	nextPage = () => {
		this.setState(prevState => ({
			offset: prevState.offset + prevState.limit
		}), () => {
			if (this.state.accountsDataCache[this.state.offset]) {
				this.setState({
					accountList: this.state.accountsDataCache[this.state.offset]
				});
			} else {
				this.searchAccounts(null, this.state.searchCriteria);  // call with updated offset
			}
		});
	}

	previousPage = () => {
		this.setState(prevState => ({
			offset: Math.max(prevState.offset - prevState.limit, 1)
		}), () => {
			if (this.state.accountsDataCache[this.state.offset]) {
				this.setState({
					accountList: this.state.accountsDataCache[this.state.offset]
				});
			} else {
				this.searchAccounts(null, this.state.searchCriteria); // call with updated offset
			}
		});
	}

	getDisplayedRecordRange = () => {
		const { offset, accountList, totalCount } = this.state;
		if (accountList && accountList.length === 0) {
			return null;
		}
		const startIndex = offset;
		// const endIndex = Math.min(offset + limit, offset + (accountList ? accountList.length : 0));
		const endIndex = offset + (accountList ? accountList.length : 0) - 1;
		return `${startIndex}-${endIndex} of ${totalCount}`;
	}



	addAccount = () => {
		this.setState({
			account: this.newAccount,
			showAccount: true,
		});
	};

	editAccount = (account: AccountModel) => {
		this.setState({
			showAccount: true,
			account: account,
			isPristine: true,
		});

		History.push(`${this.props.adminView ? "/admin" : "/user"}/accounts/${account.account_service}/${account.did}`);

		//this below is a hack to sort the isPristine state. Will keep on looking for a proper solution
		//isPristine is set at various places in the code
		//to true, but somehow (at time) it goes to false. I think it because of timing. 
		//An event sets isPristine to false when the user starts editing a control.
		//I think this wrongly happens at times when the data is places in the controls, it wrongly sets isPristine to false
		this.setState({ isPristine: true });
		console.log("editAccount isPristine should be true ? " + this.state.isPristine )

    setTimeout(() => {
			this.setState({ isPristine: true });
			console.log("Timer1 edit account Call isPristine = true");
		}, 100);		
    setTimeout(() => {
			this.setState({ isPristine: true });
			console.log("Timer2 edit account Call isPristine = true");
		}, 2000);		

    setTimeout(() => {
			this.setState({ isPristine: true });
			console.log("Timer3 edit account Call isPristine = true");
		}, 5000);		

	};

	confirmLeave = (searchString?: string) => {
		this.setState({
			showAccount: false,
			showLeaveModal: false,
			account: this.newAccount,
			drawerActions: [],
			searchCriteria: searchString || this.state.searchCriteria,
			callControlBanner: null
		});
		this.searchAccounts(null, this.state.searchCriteria);
		History.push(`${this.props.adminView ? "/admin" : "/user"}/accounts`);
	};

	cancelAccount = (did?: string) => {
		// console.log("Close Account")
		!this.state.isPristine ? this.setState({ showLeaveModal: true }) : this.confirmLeave(did);
	};

	editCallTree = (account?: AccountModel) => {
		this.setState({
			showCallTree: true,
			account: account || this.state.account,
			callTreeFromList: !!account,
		});
	};

	cancelCallTree = () => {
		this.setState({
			showCallTree: false,
			account: this.state.callTreeFromList ? this.newAccount : this.state.account,
			callTreeFromList: false,
		});
	};

	hasChanged = (e: boolean) => {
		e !== this.state.isPristine && this.setState({ isPristine: e });
	};

	getAccountTypeText = (key: string) => {
		return globalStore.getState().lookups.accountTypes.find((type: any) => type.key === key)?.text || key;
	};

	getUsersText = (users: UserModel[]) => {
		let result = "";
		users.forEach((user, index) => {
			result += user.cn;
			if (index !== users.length - 1) {
				result += ", ";
			}
		});
		return result;
	};

	searchAccounts = (e: any, q: string) => {
		// Check if the search criteria have changed. If yes, reset the offset to 1
		const isNewSearch = q !== this.state.searchCriteria;
		const newOffset = isNewSearch ? 1 : this.state.offset;

		// Set loading state and potentially reset the offset if it's a new search
		this.setState({
			isLoading: true,
			searchCriteria: q,
			offset: newOffset // Update the offset to 1 if it's a new search
		}, () => {
			// After state update, perform the search
			this.accountController.getAccountList(this.state.searchCriteria, this.props.tab, this.state.offset, this.state.limit)
				.then((response) => {

					if (response && response.items) {
						let tableData: any[] = [];

						response.items.map((account: AccountModel) => {
							let tableItem = {
								accountType: {
									key: account.account_type,
									value: (
										<div className="row" style={{margin: "0 0 0 0"}}>
											{this.getAccountTypeText(account.account_type)}
											{account.account_service.replace(' ', '').toLowerCase() === "calltree" && (
												<Tooltip text={"Call Tree"}>
													<div className="pl-2">
														<Icon icon="sitemap" type="regular"/>
													</div>
												</Tooltip>
											)}
										</div>
									),
								},
								number: {
									key: account.did,
									value: <Button type={ButtonType.TextNaked} padded={false}
												   text={this.stringProvider.formatPhoneNumber(account.did)}
												   onClick={() => this.editAccount(account)}/>,
								},
								owner: (
									<p>
										{account.owners.length > 0 ? account.owners[0].cn + " " : ""}
										{account.owners.length > 1 && (
											<Tooltip text={this.getUsersText(account.owners)}>
										<span className="pl-2">
											<Icon icon="users" type="regular"/>
										</span>
											</Tooltip>
										)}
									</p>
								),
								admin: (
									<p>
										{account.admins.length > 0 ? account.admins[0].cn + " " : ""}
										{account.admins.length > 1 && (
											<Tooltip text={this.getUsersText(account.admins)}>
										<span className="pl-2">
											<Icon icon="users" type="regular"/>
										</span>
											</Tooltip>
										)}
									</p>
								),
								softphoneUsage: account.softclient_username && account.softclient_username?.length > 0 ? account.softclient_username[0].cn : "",
								description: {key: account.description || "", value: account.description},
								location: {key: account.location || "", value: account.location},
								service: {key: account.account_service || "", value: account.account_service},
								status: account.active ? <Icon icon={"check"} type={"regular"} color={"green"}/> :
									<Icon icon={"times"} type={"regular"} color={"red"}/>,
								account: account,
							};

							tableData.push(tableItem);

							return null;
						});

						// Cache the fetched data in accountsDataCache for the current offset
						let newCache = {...this.state.accountsDataCache};

						if (isNewSearch) {
							newCache = {}; // Clear the cache if it's a new search
						}
						// Process the items into tableData as before
						newCache[this.state.offset] = tableData;

						this.setState({
							accountList: tableData,
							accountItems: response.items,
							accountsDataCache: newCache,
							isLoading: false,
							totalCount: response.total || 0,
						});
						// console.log("This is the totalCount test: ", this.getDisplayedRecordRange());
					} else {
						this.setState({
							isLoading: false,
						});
					}
				});
		});
	};

	getAccountData = (accountData:any) => {
		this.setState({account: accountData});
		this.getDrawerActions();
	}

	migrateAccount = () => {
		if (this.state.account.zoom_migration_candidate) {
			this.props.actions.addNotification(uuidv4(), "Progress", "Account Migration", "This account is being prepared for migration, please be patient.");
			this.accountController.migrateAccount(this.state.account).then(response => {
				let drawerActions =  this.state.drawerActions;
				drawerActions.pop();
				let account = this.state.account;
				account.zoom_migration_candidate = false;
				this.setState({account: account, callControlBanner: response.call_control_banner, drawerActions: drawerActions});
				this.props.actions.addNotification(uuidv4(), "Success", "Account Migration", response.message);

			});
		} else {
			this.props.actions.addNotification(uuidv4(), "Info", "Account Migration", "Account has already been scheduled for migration");
		}
	}

	isCallTree = () => {
		return this.state.account.account_service.replace(' ', '').toLowerCase() === "calltree";
	};

	isZoomRoom = () => {
		return (this.state.account.account_service.toLowerCase() === "zoom room");
	}

	isNotZoomRoom = () => {
		return (this.state.account.account_service.toLowerCase() !== "zoom room");
	}
	completeAccountServiceConversion = async () => {
		this.setState({
			showAccountServiceConversionModal: false,
			showAccount: false,
		});
		this.searchAccounts(null, this.state.searchCriteria);

		History.push(`${this.props.adminView ? "/admin" : "/user"}/accounts/${this.state.account.account_service}/${this.state.account.did}`);
		this.setState({
			showAccount: true
		});
	};

	cancelAccountServiceConversion = () =>{
		this.setState({
			showAccountServiceConversionModal: false,
		});
	}

	regenerateActivationCode = () => {
		this.props.actions.addNotification(uuidv4(), "Progress", "Regenerate Activation Code", "Regenerating activation code.");
		this.accountController.generateActivationCode(this.state.account).then(response => {
			if (response) {
				let account = this.state.account;
				account.activation_code = response.item;
				this.setState({account: account});
				this.props.actions.addNotification(uuidv4(), "Success", "Regenerate Activation Code", "Your activation code was successfully regenerated." );
			} else {
				this.props.actions.addNotification(uuidv4(), "Error", "Regenerate Activation Code", "There was an error regenerating the activation code.",[], 1);
			}

		})
	}

	getDrawerActions = (): NavItemProps[] => {
		let drawerActions: any = [];

		const voicemailAction: NavItemProps = {
			key: "account-action-1",
			icon: "voicemail",
			text: "Reset Voicemail Passcode",
			iconOnly: false,
			onClick: () => {
				this.setState({ showVoicmailPinModal: true });
			},
		};

		// const systemLookupAction: NavItemProps = {
		// 	key: "account-action-2",
		// 	icon: "file-search",
		// 	text: "System Lookup",
		// 	iconOnly: false,
		// 	onClick: () => {
		// 		this.setState({ showSystemLookupModal: true, loadingSystems: true });
		// 		this.fetchSystemData();
		// 	},
		// };
		const passwordAction: NavItemProps = {
			key: "account-action-3",
			icon: "key",
			text: "Reset SIP Password",
			iconOnly: false,
			onClick: () => {
				if(this.state.userRole === "SUPER_USER" && this.props.adminView){
					this.setState({
						showManualSipModal: true,
						sipPasswordManual: false,
					});
				}else{
					this.setState({ showSipModal: true });
				}
			},
		};



		const callTreeAction: NavItemProps = {
			key: "account-action-4",
			icon: "folder-tree",
			text: "Configure Call Tree",
			iconOnly: false,
			onClick: this.editCallTree,
		};

		const generateActivationCode: NavItemProps = {
			key: "account-action-5",
			icon: "redo",
			text: "Regenerate Activation Code",
			iconOnly: false,
			onClick: () => {this.regenerateActivationCode()}
		};

		const migrateAccountAction: NavItemProps = {
			key: "account-action-5",
			icon: "sign-out",
			text: "Migrate Account To Zoom",
			iconOnly: false,
			onClick: () => {this.migrateAccount()}
		};

		const accountServiceConversionAction: NavItemProps = {
			key: "account-action-7",
			icon: "sign-out",
			text: this.state.account.account_service_switch,
			iconOnly: false,
			onClick: () => {
				this.setState({ showAccountServiceConversionModal: true});
			},
		};

		if (this.state.account.uuid) {
			if (this.isCallTree() === false &&
				this.state.account.account_type.toLowerCase() !== "cjp" &&
				(this.state.account.account_type.toLowerCase().includes("broadsoft") || (this.state.account.voicemail && this.state.account.voicemail_active))
			) {
				drawerActions.push(voicemailAction);
			}

			if (
				this.state.userRole !== "UNPRIV_USER" &&
				this.state.userRole !== "HELPDESK" &&
				this.props.adminView &&
				this.state.account.account_service.replace(' ', '').toLowerCase() !== "calltree" &&
				this.state.account.account_type.toLowerCase() !== "cjp" && this.isNotZoomRoom()
			) {
				drawerActions.push(passwordAction);
			}

			if (this.isCallTree()) {
				drawerActions.push(callTreeAction);
			}

			if (this.isZoomRoom() && this.props.adminView) {
				drawerActions.push(generateActivationCode);
			}

			if (this.state.account.zoom_migration_candidate) {
				drawerActions.push(migrateAccountAction);
			}

		}if (this.state.account.account_service_switch) {
			drawerActions.push(accountServiceConversionAction);
		}

		this.setState({drawerActions: drawerActions})
		return drawerActions;

	};

	navigateToCallSettings = (account: AccountModel) => {
		window.open(account.call_control_url || "", "_blank");
	};

	regenerateSipPassword = () => {
		if (this.state.account) {
			this.setState({ sipIsBusy: true });
			this.accountController.regenerateSipPassword(this.state.account).then((response) => {
				if (response && response.password) {
					let updatedAccount = this.state.account;
					updatedAccount.password = response.password;
					this.setState({ account: updatedAccount, accountPassword: response.password });
					this.props.actions.addNotification(uuidv4(), "Success", "SIP Password", "SIP Password Successfully Regenerated");
				}
				this.setState({
					showSipModal: false,
					showManualSipModal: false,
					sipIsBusy: false,
				});
				this.hasChanged(true);
			});
		}
	};

	setSipPassword = () => {
		if (this.state.account && this.newSipPassword) {
			this.setState({ sipIsBusy: true });
			this.accountController.setSipPassword(this.state.account, this.newSipPassword).then((response) => {
				if (response) {
					let updatedAccount = this.state.account;
					updatedAccount.password = this.newSipPassword;
					this.setState({ account: updatedAccount, accountPassword: this.newSipPassword });
					this.props.actions.addNotification(uuidv4(), "Success", "SIP Password", "SIP Password Successfully Regenerated");
				}
				this.setState({
					showManualSipModal: false,
					sipIsBusy: false,
				});
				this.newSipPassword = "";
				this.hasChanged(true);
			});
		}
	};

	onSuccessfulAccountCreation = (termForSearch : string) => {
		let searchBox = document.getElementsByName("searchbox").item(0) as HTMLInputElement;
		searchBox.value = termForSearch;

		this.searchAccounts(null,termForSearch);
	};

	render() {
		const ModalData = withComponent(Modal);

		const headers: IAdvancedTableColumn[] = [
			{
				id: "number/key",
				displayId: "number/value",
				name: "Number",
				sortable: true,
				options: {
					demandPopin: true,
					minWidth: 1000,
					popinText: "Number",
				},
			},
			{
				id: "accountType/key",
				displayId: "accountType/value",
				name: "Account Type",
				sortable: true,
				options: {
					demandPopin: true,
					minWidth: 1000,
					popinText: "Account Type",
				},
			},
			{
				id: "service/key",
				displayId: "service/value",
				name: "Account Service",
				sortable: true,
				options: {
					demandPopin: true,
					minWidth: 1000,
					popinText: "Account Service",
				},
			},
			{
				id: "owner",
				name: "Owner",
				options: {
					demandPopin: true,
					minWidth: 1000,
					popinText: "Owner",
				},
			},
			{
				id: "admin",
				name: "Admin",
				options: {
					demandPopin: true,
					minWidth: 1000,
					popinText: "Admin",
				},
			},
			{
				id: "softphoneUsage",
				name: "Softphone Username",
				options: {
					demandPopin: true,
					minWidth: 1000,
					popinText: "Softphone Usage",
				},
			},
			{
				id: "description/key",
				displayId: "description/value",
				name: "Description",
				sortable: true,
				options: {
					demandPopin: true,
					minWidth: 1000,
					popinText: "Description",
				},
			},
			{
				id: "location/key",
				displayId: "location/value",
				name: "Location",
				sortable: true,
				options: {
					demandPopin: true,
					minWidth: 1000,
					popinText: "Location",
				},
			},

		];

		const systemHeaders: IAdvancedTableColumn[] = [
			{
				id: "name",
				name: "System Name",
				sortable: true,
			},
			{
				id: "provisioned",
				name: "Provisioned",
			},
			{
				id: "type",
				name: "System Type",
			},
		];

		const actionButtons: ButtonProps[] = [
			{
				icon: "users",
				type: ButtonType.IconNaked,
				onClick: (e: any) => this.editAccount(e.account),
				text: "Account Details",
				state: 0,
				padded: false,
			},
			{
				icon: "phone",
				type: ButtonType.IconNaked,
				shouldDisplay: (e: any) => e.account.account_service === "user",
				onClick: (e: any) => History.push(`${this.props.adminView ? "/admin" : "/user"}/devices/search-${e.account.did}`),
				text: "View Devices",
				state: 0,
				padded: false,
			},
			{
				icon: "lock-open",
				type: ButtonType.IconNaked,
				shouldDisplay: (e: any) =>
					e.account.account_service === "user" && (e.account.account_type === "broadsoft" || e.account.account_type === "broadsoft_dev" || e.account.account_type === "sylantro"),
				onClick: (e: any) => this.setState({ account: e.account, showVoicmailPinModal: true }),
				text: "Reset Passcode",
				state: 0,
				padded: false,
			},
			{
				icon: "cog",
				type: ButtonType.IconNaked,
				shouldDisplay: (e: any) => e.account.account_service === "user",
				onClick: (e: any) => this.navigateToCallSettings(e.account),
				text: "Call Settings",
				state: 0,
				padded: false,
			},
			{
				icon: "sitemap",
				type: ButtonType.IconNaked,
				shouldDisplay: (e: any) => e.account.account_service.replace(' ', '').toLowerCase() === "calltree",
				onClick: (e: any) => this.editCallTree(e.account),
				text: "Call Tree Settings",
				state: 0,
				padded: false,
			},
		];

		const navigationStyle = {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-end',
			padding: '1em',
			paddingRight: '0em',
			marginBottom: '3em'
		};

		const buttonContainerStyle = {
			marginRight: '1em'
		};

		const finalStyle = { ...navigationStyle};


		// const hasPreviousRecords = this.state.offset > 0;
		// const hasNextRecords = (this.state.offset + this.state.limit) < this.state.totalCount;

		const hasPreviousRecords = this.state.offset > 1;
		const hasNextRecords = (this.state.offset + this.state.limit - 1) < this.state.totalCount;


		return (
			<Fragment>
				{/* This actually needs to be moved out */}

				<div className="container">
					<div className="row">
						<div className="col-lg-6 col-md-8 mx-auto">
							<div
								style={{
									display: "flex",
									justifyContent: "space-around",
								}}>
								<Textbox
									name="searchbox"
									endIcon="search"
									compact={false}
									startIcon="users"
									placeholderText="Search Accounts"
									helperText="Search by Devices, Kerberos, Description or DID"
									clearable
									type={TextboxType.IconEnd | TextboxType.IconStart}
									onEndIconClick={this.searchAccounts}
									onKeyUp={(e: any) => {
										if (e.keyCode === 13) this.searchAccounts("", e.currentTarget.value);
									}}
									width={100}
									endIconRequired={true}
									onClear={() => {
										this.setState({ searchCriteria: "", accountList: null });
										!this.props.adminView && this.searchAccounts(null, "");
									}}
									properties={{ "aria-label": "Search Accounts" }}
								/>
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
								{this.props.adminView && this.state.userRole !== "UNPRIV_USER" && (
									<span
										style={{
											whiteSpace: "nowrap",
										}}>
										<Button icon="plus" type={ButtonType.Primary} text="Create Account" onClick={this.addAccount} />
									</span>
								)}
							</div>
						</div>
					</div>
				</div>

				<Spacer size="4" />
				<Fragment>
					{this.state.isLoading ? (
						<ActionList isLoading items={[]} />
					) : (
						<Fragment>

							{!this.state.accountList && (
								<GridContainer showGutters={true}>
									<LayoutColumn colSize={12} alignment={"center"} padded>
										<Error context={ErrorContext.Component} message="Please search to display a list of accounts" type={ErrorType.FirstAction} />
									</LayoutColumn>
								</GridContainer>
							)}
							{this.state.accountList && this.state.accountList.length < 1 && this.props.adminView && (
								<GridContainer showGutters={true}>
									<LayoutColumn colSize={12} alignment={"center"} padded>
										<Error context={ErrorContext.Component} message="No accounts found. Please search to display a list of accounts" type={ErrorType.NoData} />
									</LayoutColumn>

								</GridContainer>
							)}
							{this.state.accountList && this.state.accountList.length < 1 && !this.props.adminView && (
								<GridContainer showGutters={true}>
									<LayoutColumn colSize={12} alignment={"center"} padded>
										<Error context={ErrorContext.Component} message="No accounts found" type={ErrorType.NoData} />
									</LayoutColumn>
								</GridContainer>
							)}
							{!this.props.adminView && this.props.tab === "my" && this.state.accountItems.length > 0 && this.state.accountItems?.length < 5 && (
								<div className="row">
									{this.state.accountItems.map((account) => {
										return (
											<CardEntity
												accountDetails={account}
												onNumberClick={() => this.editAccount(account)}
												myDevicesAction={() => {
													History.push(`${this.props.adminView ? "/admin" : "/user"}/devices/search-${account.did}`);
												}}
												actionButtons={[
													{
														icon: "users",
														type: ButtonType.IconNaked,
														onClick: (e: any) => this.editAccount(account),
														text: "Account Details",
														state: 0,
														padded: false,
													},
													{
														icon: "phone",
														type: ButtonType.IconNaked,
														onClick: (e: any) => History.push(`${this.props.adminView ? "/admin" : "/user"}/devices/search-${account.did}`),
														text: "View Devices",
														state: 0,
														padded: false,
													},
													{
														icon: "lock-open",
														type: ButtonType.IconNaked,
														onClick: (e: any) =>
															this.setState({
																account: account,
																showVoicmailPinModal: true,
															}),
														text: "Reset Passcode",
														state: 0,
														padded: false,
													},
													{
														icon: "cog",
														type: ButtonType.IconNaked,
														onClick: (e: any) => this.navigateToCallSettings(account),
														text: "Call Settings",
														state: 0,
														padded: false,
													},
												]}
												resetPasscodeAction={() =>
													this.setState({
														account: account,
														showVoicmailPinModal: true,
													})
												}
											/>
										);
									})}
								</div>
							)}
							{!this.props.adminView && this.state.accountList && (this.state.accountList.length > 4 || (this.props.tab === "other" && this.state.accountList.length > 0)) && (
								<Fragment>
									<AdvancedTable columns={headers} items={this.state.accountList} actionButtons={actionButtons} maxRows={10000} />
								</Fragment>
							)}
							{this.props.adminView && this.state.accountList && this.state.accountList.length > 0 && (
								<Fragment>
									<AdvancedTable columns={headers} items={this.state.accountList} actionButtons={actionButtons} maxRows={10000} />
								</Fragment>
							)}
						</Fragment>
					)}
				</Fragment>

				<Fragment>
					<div style={finalStyle}>
						<div style={{ display: 'flex', alignItems: 'center', marginRight: '1em' }}>
							<span style={{ marginRight: '1em'}}>
								{this.getDisplayedRecordRange()}
							</span>
						</div>

						<div style={{ display: 'flex', alignItems: 'center' }}>
							{hasPreviousRecords && (
								<div style={buttonContainerStyle}>
									<Button
										type={ButtonType.Primary}
										text="<"
										onClick={this.previousPage}
										state={this.state.offset === 0 ? ButtonState.Disabled : ButtonState.Enabled}
									/>
								</div>
							)}
						</div>
						{hasNextRecords && (
							<Button
								type={ButtonType.Primary}
								text=">"
								onClick={this.nextPage}
								state={ButtonState.Enabled}
							/>
						)}
					</div>

				</Fragment>

				<Drawer
					header={
						<Text
							content={this.state.account.uuid ? `Edit Account ${this.stringProvider.formatPhoneNumber(this.state.account.did)}` : "Create Account"}
							type={TextType.Heading4}
							icon="users"
						/>
					}
					type={DrawerType.Right}
					layout={DrawerLayout.Hero}
					size={DrawerSize.Medium}
					headerNavBar={<NavBar type={NavBarTypes.IconText} tabs={this.state.drawerActions} />}
					show={this.state.showAccount}
					contents={
						<Provider store={globalStore}>
							<AccountDetail
								passAccountData={this.getAccountData}
								accountId={this.state.account.uuid || ""}
								isPristine={this.hasChanged}
								sip={this.state.accountPassword}
								close={this.cancelAccount}
								onSuccessfulCreation={this.onSuccessfulAccountCreation}
								callControlBanner={this.state.callControlBanner || this.state.account.call_control_banner}
								activationCode={this.state.account.activation_code ? this.state.account.activation_code : ""}
							/>
						</Provider>
					}
					onClose={this.cancelAccount}
					footer={[]}
				/>

				<ConfirmationModal
					show={this.state.showSipModal}
					onClose={() => this.setState({ showSipModal: false })}
					name="sipModal"
					isBusy={this.state.sipIsBusy}
					title="Refresh SIP Password"
					body="Are you sure you want to refresh the SIP Password?"
					callBack={this.regenerateSipPassword}
				/>

				<ModalData
					show={this.state.showAccountServiceConversionModal}
					onClose={() => this.setState({ showAccountServiceConversionModal: false })}
					containerless={false}
					size={TemplateModalSize.Default}
					name="accountServiceConversionModal"
					isBusy={this.state.accountServiceConversionIsBusy}
					title="Account Service Conversion"
					body={
						<Provider store={globalStore}>
							<AccountServiceConversion account={this.state.account}
													  close={this.cancelAccountServiceConversion}
													  converted={this.completeAccountServiceConversion}/>
						</Provider>
					}
					footer={[]}
					header={<Text content="Account Service Conversion" type={TextType.Heading4} icon="" />}

				/>

				<ModalData
					show={this.state.showSystemLookupModal}
					onClose={() => this.setState({ showSystemLookupModal: false })}
					containerless={false}
					size={TemplateModalSize.Default}
					body={<Fragment>{this.state.loadingSystems ? <ActionList items={[]} isLoading /> : <AdvancedTable columns={systemHeaders} items={this.state.systems} />}</Fragment>}
					footer={[]}
					header={<Text content="System Lookup" type={TextType.Heading4} icon="" />}
					name={"systemLookupModal"}
				/>

				<ModalData
					show={this.state.showVoicmailPinModal}
					onClose={() => this.setState({ showVoicmailPinModal: false })}
					containerless={false}
					size={TemplateModalSize.Default}
					body={
						<Provider store={globalStore}>
							<VoicemailPasscode account={this.state.account} close={() => this.setState({ showVoicmailPinModal: false })} />
						</Provider>
					}
					footer={[]}
					header={<Text content="Set Voicemail Passcode" type={TextType.Heading4} icon="" />}
					name={"voicmailPinModal"}
				/>

				<ModalData
					show={this.state.showManualSipModal}
					onClose={() => this.setState({ showManualSipModal: false })}
					containerless={false}
					size={TemplateModalSize.Default}
					body={
						<Fragment>
							<Text content="How would you like to regenerate the SIP Password?" />
							{!this.state.sipPasswordManual && <Button text="Automatic" type={ButtonType.Primary} onClick={this.regenerateSipPassword} isBusy={this.state.sipIsBusy} />}
							{!this.state.sipPasswordManual && <Button text="Manual" type={ButtonType.TextNaked} onClick={() => this.setState({ sipPasswordManual: true })} />} <Spacer size="2" />
							{this.state.sipPasswordManual && (
								<div>
									<Label text="Manual" />
									<Textbox
										name="sipPassword"
										placeholderText="New SIP Password"
										clearable
										value={this.newSipPassword}
										onChange={(e: any) => {
											this.newSipPassword = e.currentTarget.value;
										}}
									/>
								</div>
							)}
						</Fragment>
					}
					footer={this.state.sipPasswordManual ? <Button text="Save" onClick={this.setSipPassword} isBusy={this.state.sipIsBusy} /> : []}
					header={<Text content="Change SIP Password" type={TextType.Heading4} icon="" />}
					name={"manualSIPPasswordModal"}
				/>

				<Drawer
					header={<Text content={"Edit Call Tree " + this.stringProvider.formatPhoneNumber(this.state.account.did)} type={TextType.Heading4} icon="folder-tree" />}
					type={DrawerType.Right}
					layout={DrawerLayout.Hero}
					size={DrawerSize.Medium}
					show={this.state.showCallTree}
					contents={
						<Provider store={globalStore}>
							<CallTreeDetail accountId={this.state.account.uuid || ""} account_type={this.state.account.account_type.toLowerCase()}/>
						</Provider>
					}
					onClose={this.cancelCallTree}
					footer={[]}
				/>

				<ConfirmationModal
					show={this.state.showLeaveModal}
					onClose={() => this.setState({ showLeaveModal: false })}
					name="showLeaveModal"
					title="Account Details"
					body="Are you sure you want to leave account details, all your changes will be discarded?"
					callBack={this.confirmLeave}
				/>
			</Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({
	adminView: state.app.adminView,
	impersonate: state.app.impersonate,
});

const mapDispatchToProps = (dispatch: any) => ({
	actions: bindActionCreators(notificationActionCreator, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountList);
