import {
	ActionList,
	AdvancedTable, AutoComplete,
	ButtonProps,
	ButtonState,
	ButtonType,
	DropdownItemProps,
	Error,
	ErrorContext,
	ErrorType,
	FormContainer,
	FormField,
	GridContainer,
	IAdvancedTableColumn,
	Icon,
	LayoutColumn,
	Text,
	TextType,
	Spacer,
} from "@mit/hui";
import React, { Fragment } from "react";
import DeviceController from "../api/DeviceController";
import { AccountModel } from "../api/models/AccountModel";
import { DeviceModel } from "../api/models/DeviceModel";
import { UserRoleType } from "../api/models/UserRoleModel";
import { store } from "../common/redux/store/store";
import StringProvider from "../services/StringProvider";
import AuthProvider from "../services/AuthProvider";
export const globalStore = store;

interface DeviceAssignmentState {
	device: DeviceModel;
	isLoading: boolean;
	isBusy: boolean;
	formError: boolean;
	userRole: UserRoleType;
	isAdminView: boolean;
	userDevices: DeviceModel[];
	userDevicesData: DropdownItemProps[];
	macList: any[];
	originalMacList: any[];
}

class DeviceAssignment extends React.Component<any, DeviceAssignmentState> {
	deviceController: DeviceController;
	stringProvider: StringProvider;
	_isMounted: boolean;
	authProvider: AuthProvider

	constructor(props: any) {
		super(props);

		//This class did not take any special action on timeout
		//It will continue to act in such a way
		const onSessionTimeout = () => {}
		this.authProvider = new AuthProvider(onSessionTimeout)
		this.deviceController = new DeviceController(this.authProvider);
		this.stringProvider = new StringProvider();
		this._isMounted = false;

		this.state = {
			device: {
				model: "",
				mac: "",
				description: "",
				owners: [],
				account_type: "",
				admins: [],
				active: false,
				audio_codes: false,
			},
			isLoading: false,
			isBusy: false,
			formError: false,
			userRole: globalStore.getState().app.userRole,
			isAdminView: globalStore.getState().app.adminView,
			userDevices: [],
			userDevicesData: [],
			originalMacList: [],
			macList:[]
		};
	}

	componentDidMount() {
		const fetchDevices = async () => {
			this.setState({ isLoading: true });
			this.deviceController.getUserDevices().then((response) => {
				let devices = response.items;
				if (devices) {
					this.setState({ userDevices: devices, userDevicesData: this.generateDropdownItems(devices) });
					let macList = this.getMacList();
					console.log("key::macList", macList);

					this.setState({macList: macList, originalMacList: macList});

					console.log("key::originalMacList",this.setState({macList: macList, originalMacList: macList}))

				}
				this.setState({ isLoading: false });
			});
		};

		//Call
		(!this.state.isAdminView || this.state.userRole === "UNPRIV_USER") && fetchDevices();
	}

	generateDropdownItems = (devices: DeviceModel[]) => {
		const dropdownData: DropdownItemProps[] = [];
		devices.forEach((device: DeviceModel) => {
			dropdownData.push({ text: device.mac, icon: "" });
		});
		return dropdownData;
	};

	assignDevice = (event: Event) => {

		/**
		 * We have to prevent the page from reloading when the enter key is pressed.
		 * For some reason this generates an event whilst a click on the button does not
		 * event.preventDefault() squashes the reload, but presents problems when
		 * there is no event.
		 */
		if(event){
			// console.log("we have an event");
			try{
				event.preventDefault()
			}catch{
				console.log("DeviceAssignments.assignDevice had error with event.preventDefault event =", event);
			}
		}
		if (this.state.device && this.state.device.uuid) {
			this.setState({ isBusy: true });
			this.deviceController
				.assignToAccount(this.state.device.uuid, {
					did: this.props.account.did,
					mwi: this.props.account.mwi,
				})
				.then((response) => {
					if (response && this.state.device && this.state.device.uuid && this.state.device.assigned_accounts) {
						const assignedAccounts = this.state.device.assigned_accounts;
						const newAccountIndex = this.state.device.assigned_accounts.findIndex((account) => account.new);
						let newAccount = this.state.device.assigned_accounts.find((account) => account.new);
						if (newAccount && response.line_port) {
							newAccount.line_port = response.line_port;
							assignedAccounts[newAccountIndex] = newAccount;
						}
						this.deviceController.updateDeviceAssignments(this.state.device.uuid, this.pruneAssignedAccounts(assignedAccounts)).then((accounts) => {
							if (accounts) {
								this.props.assignDevice(response ? this.state.device : null);
								this.setState({ isBusy: false });
								this.props.close();
							} else {
								this.setState({ isBusy: false });
							}
						});
					} else {
						this.setState({ isBusy: false });
					}
				});
		}
	};

	pruneAssignedAccounts = (accounts: AccountModel[]) => {
		return accounts.map((account) => ({ did: account.did, line_port: account.line_port }));
	};

	deviceLookup = () => {

		if (this.state.device.mac && this.state.device.mac.match(/^([0-9a-fA-F]{2}[.:-]?){5}([0-9a-fA-F]{2})$/)) {
			this.setState({ isLoading: true });
			this.deviceController.deviceLookup(this.state.device.mac).then((response) => {
				if (response) {
					this.deviceController.getDevice(response, this.state.isAdminView).then((response) => {
						if (response) {
							let account = this.props.account;
							account.new = true;
							response.assigned_accounts = response.assigned_accounts || [];
							response.assigned_accounts?.push(account);
							this.setState({
								device: response,
								isLoading: false,
							});
						} else {
							this.setState({ isLoading: false });
						}
					});
				} else {
					this.setState({ isLoading: false });
				}
			});
		}
	};

	setDevice = async (e: any) => {

		this.setState({ isLoading: true });
		const device = this.state.userDevices.find((device) => device.mac === e.text);
		if (device && device.uuid) {
			this.deviceController.getDevice(device.uuid, this.state.isAdminView).then((response) => {
				if (response) {
					let account = this.props.account;
					account.new = true;
					response.assigned_accounts = response.assigned_accounts || [];
					response.assigned_accounts?.push(account);
					this.setState({
						device: response,
						isLoading: false,
					});
				} else {
					this.setState({ isLoading: false });
				}
			});
		} else {
			this.setState({ isLoading: false });
		}
	};

	moveUp = (e: any) => {
		const fromIndex = e.order - 1;
		const toIndex = e.order !== 1 ? e.order - 2 : this.state.device.assigned_accounts?.length || 0 - 1;
		this.updateAccountPostition(fromIndex, toIndex);
	};

	moveDown = (e: any) => {
		const fromIndex = e.order - 1;
		const toIndex = e.order !== this.state.device.assigned_accounts?.length ? e.order : 0;
		this.updateAccountPostition(fromIndex, toIndex);
	};

	getMacList = () => this.state.userDevices.map((device) => {
		return { icon: "user", text: device.mac, secondaryText: device.description };
	});

	updateAccountPostition = (fromIndex: number, toIndex: number) => {
		if (this.state.device.assigned_accounts) {
			let updatedDevice: DeviceModel = this.state.device;
			let account: AccountModel = this.state.device.assigned_accounts[fromIndex] || null;
			if (account) {
				updatedDevice.assigned_accounts?.splice(fromIndex, 1);
				updatedDevice.assigned_accounts?.splice(toIndex, 0, account);
				this.setState({ device: updatedDevice });
			}
		}
	};

	render() {
		const headers: IAdvancedTableColumn[] = [
			{
				id: "order",
				name: "Order",
			},
			{
				id: "did",
				name: "Account DID",
			},
			{
				id: "new",
				name: "",
			},
		];

		const actionButtons: ButtonProps[] = [
			{
				icon: "arrow-up",
				type: ButtonType.IconNaked,
				text: "Move up",
				shouldDisplay: (e: any) => this.state.device.assigned_accounts && this.state.device.assigned_accounts?.length > 1,
				onClick: this.moveUp,
				state: ButtonState.Enabled,
			},
			{
				icon: "arrow-down",
				type: ButtonType.IconNaked,
				text: "Move down",
				shouldDisplay: (e: any) => this.state.device.assigned_accounts && this.state.device.assigned_accounts?.length > 1,
				onClick: this.moveDown,
				state: ButtonState.Enabled,
			},
		];

		let tableData: any[] = [];
		this.state.device.assigned_accounts?.forEach((account, index) => {
			let tableItem = {
				order: index + 1,
				did: this.stringProvider.formatPhoneNumber(account.did),
				account: account,
				new: account.new && <Icon icon={"star"} type={"regular"} color={"yellow"} />,
			};
			tableData.push(tableItem);
		});

		// let macList = this.state.userDevices.map((device) => {
		// 	return { icon: "", text: device.mac, secondaryText: device.description };
		// });

		const textStyle = {
			paddingTop: 'calc(0.75rem + 1px)',
			paddingBottom: 'calc(0.75rem + 1px)',
			marginBottom: 0,
			fontSize: 'inherit', // You can specify a size here if needed
			lineHeight: 1.5,
		};

		if (this.state.isLoading) return <ActionList isLoading items={[]} />;
		return (
			<div className={"assignAccountToDeviceModal"}>
				<Fragment>
					<FormContainer
						action={onsubmit = (ev: Event) => {this.assignDevice(ev)}}
						submitText="Add"
						actionIsBusy={this.state.isBusy}
						secondaryAction={() => this.props.close()}
						secondaryActionText="Cancel"
						isValid={(e: any) => this.setState({ formError: !e })}
						formValidationTrigger="onChange"
						id="deviceAssignForm">
						{/*<div style={{padding:"1.5em 0em 1.6em 0.1em"}}>*/}
						<GridContainer showGutters={true}>
							<LayoutColumn colSize={12} alignment={"left"}>
								{this.state.isAdminView && this.state.userRole !== "UNPRIV_USER" ? (
									<FormField
										id="macAddress"
										editor="textbox"
										value={this.state.device.mac}
										onChange={(e: any) => {
											let updatedDevice = this.state.device;
											updatedDevice.mac = e.currentTarget.value;
											this.setState({ device: updatedDevice });
										}}
										label="MAC Address"
										labelDisplay="ontop"
										items={this.state.macList}
										onBlur={this.deviceLookup}
										pattern={{
											value: /^([0-9a-fA-F]{2}[.:-]?){5}([0-9a-fA-F]{2})$/,
											message: "MAC Address format",
										}}
										required={true}
										properties={{
											clearable: true,
											onClear: () => {
												let updatedDevice = this.state.device;
												updatedDevice.mac = "";
												updatedDevice.uuid = "";
												this.setState({ device: updatedDevice });
											},
										}}
									/>
								) : this.state.userDevices && this.state.userDevices.length > 0 ? (
									<div style={{padding:"0em 0em 1em 0em"}}>
										<div style={textStyle} >
								        MAC Address
										</div>
									<Spacer size='1' />
									<AutoComplete
										name='macAddressList'
										items={this.state.macList}
										type={"dropdown"}
										creatableHelperText="This item will be added to the database."
										value={this.state.device.mac}
										onChange={this.setDevice}
										onClear={() => {
											let updatedDevice = this.state.device;
											updatedDevice.mac = "";
											updatedDevice.uuid = "";
											this.setState({ device: updatedDevice });
										}}
										searchOptions={{
											name: "macAddressListSearch",
											placeholderText: "MAC Address",
											onKeyUp: (e) =>{
												e.persist()
												e.target.blur()
												let macList = this.state.originalMacList.filter(o => Object.keys(o).some(k => o[k].toLowerCase().includes(e.target.value.toLowerCase())))
												this.setState({macList: macList})
												function focusElement() { e.target.focus() }
												setTimeout(() => { focusElement() }, 100)
												//Intercept enter and tab presses on the search bar
												if(e.keyCode === 9 || e.keyCode === 13){
													e.preventDefault();
													e.stopPropagation();
												}
											},
										}}
										disabled={!this.state.userDevices || this.state.userDevices.length <= 0}
										showItemsOnFocus
									/>
									</div>
								) : (
									<Error context={ErrorContext.Component} message="You have no devices to assign to this account" type={ErrorType.FirstAction} />
								)}

								{this.state.device && this.state.device.uuid && (
									<div>
										<FormField id="deviceDescription" value={this.state.device.description} editor="textbox" label="Description" labelDisplay="ontop" readonly={true} />
										<FormField id="deviceModel" value={this.state.device.model} editor="textbox" label="Device Model" labelDisplay="ontop" readonly={true} />
										<Text type={TextType.Heading5} content="Assigned Accounts" />
										{this.state.device.assigned_accounts && this.state.device.assigned_accounts.length > 0 ? (
											<AdvancedTable columns={headers} items={tableData} actionButtons={actionButtons} maxRows={1000} />
										) : (
											<Error context={ErrorContext.Component} message="No accounts assigned to device" type={ErrorType.FirstAction} />
										)}
									</div>
								)}
							</LayoutColumn>
						</GridContainer>
						{/*</div>*/}
					</FormContainer>
				</Fragment>
			</div>

		);
	}
}

export default DeviceAssignment;
