import appConfig from "../app-config";
import AuthProvider from "../services/AuthProvider";
import { store } from "../common/redux/store/store";
import { uuidv4 } from "../common/redux/actions/notification";
import { NotificationModel } from "../common/redux/reducers/notification";
import { dispatch_addNotification } from "../common/redux/actions/notification";
import { GridContainer, LayoutColumn, Text } from "@mit/hui";
import React from "react";
import StringProvider from "../services/StringProvider";
export const globalStore = store;

class BaseController {
	readonly baseUrl: string;
	readonly stringProvider: StringProvider;
	readonly authProvider: AuthProvider

	constructor(authProvider: AuthProvider,path?: string) {
		this.baseUrl = appConfig.api.hostname + "/" + (path ? path : appConfig.api.paths["network-infrastructure"]);
		this.stringProvider = new StringProvider();
		this.authProvider = authProvider
	}

	//Common
	generateHeaders = async (addImpersonatedHeader?: boolean): Promise<RequestInit> => {
		let headers: Headers = new Headers({});

		let token = await this.authProvider.getToken();

		headers.append("Authorization", "Bearer " + token);

		if (addImpersonatedHeader && globalStore.getState().app.impersonate) headers.append("X-Impersonated-User", globalStore.getState().app.impersonate);

		return {
			method: "GET",
			headers: headers,
		};
	};

	generateQuery = (query: any): string => {
		let queryString = "";
		if (query) {
			let esc = encodeURIComponent;
			queryString =
				"?" +
				Object.keys(query)
					.map((k) => esc(k) + "=" + esc(query[k]))
					.join("&");
		}
		return queryString;
	};

	getErrorFooterInformation = (error: any): any => {
		let footer: any = [];
		if (error.other_mac) {
			// footer = (
			// 	<GridContainer>
			// 		<LayoutColumn colSize={12}>
			// 			<Text content={error.other_mac}></Text>
			// 		</LayoutColumn>
			// 	</GridContainer>
			// );
		} else if (error.message.data && error.message.data.length > 0) {
			footer = (
				<GridContainer>
					<LayoutColumn colSize={12}>
						{error.message.data.map((data: any) => {
							return <Text content={`${data.range_start} - ${data.range_end}`}></Text>;
						})}
					</LayoutColumn>
				</GridContainer>
			);
		}
		return footer;
	};

	handleAPIError = async (response?: any, omitError?: boolean) => {
		let error = await response;
		if (error && !omitError) {
			let message = "";
			let subtext = "";
			if (error.message.text) {
				message = error.message.text;
			} else if (error.other_mac) {
				message = error.message.replace("__LINK__", error.other_mac);
			} else {
				message = error.message;
			}

			if (error.error) {
				subtext = this.stringProvider.capitalizeFirstLetter(error.error);
			} else {
				subtext = "AWS";
			}

			const notification: NotificationModel = {
				show: true,
				title: "Error",
				message: message,
				subtext: subtext,
				id: uuidv4(),
				footer: this.getErrorFooterInformation(error),
				type: 1,
			};
			store.dispatch(dispatch_addNotification(notification));
		}

		return null;
	};

	handleAPIResponse = (response: any, omitError?: boolean) => {
		if (response.status >= 200 && response.status < 300) {
			return response.json();
		} else {
			return this.handleAPIError(response.json(), omitError);
		}
	};

	//GET
	get = async (path: string, query?: any, addImpersonateHeader?: boolean, omitError?: boolean): Promise<any> => {
		try {
			let response = await fetch(this.baseUrl + path + this.generateQuery(query), await this.generateHeaders(addImpersonateHeader));
			return this.handleAPIResponse(response, omitError);
		} catch (e) {
			this.handleAPIError(omitError);
		}
	};

	//GET with Json Check - Temp untill digital Id gets fixed
	getJsonCheck = async (path: string, query?: any, addImpersonateHeader?: boolean, omitError?: boolean): Promise<any> => {
		try {
			let response = await fetch(this.baseUrl + path + this.generateQuery(query), await this.generateHeaders(addImpersonateHeader));
			if (response.status >= 200 && response.status < 300) {
				return response
					.json()
					.then((json) => json)
					.catch(() => null);
			} else {
				return this.handleAPIError(null, omitError);
			}
		} catch (e) {
			this.handleAPIError(omitError);
		}
	};

	//Files
	getImage = async (path: string): Promise<any> => {
		try {
			let response = await fetch(this.baseUrl + path, await this.generateHeaders());
			if (response.status >= 200 && response.status < 300) {
				return response.blob().then((data) => URL.createObjectURL(data));
			} else {
				return this.handleAPIError(response.json());
			}
		} catch (e) {
			this.handleAPIError();
		}
	};

	getFile = async (path: string, acceptType: string): Promise<any> => {
		try {
			let response = await fetch(this.baseUrl + path, {
				method: "GET",
				headers: new Headers({
					Accept: acceptType,
					Authorization: "Bearer " + (await this.authProvider.getToken()),
				}),
			});
			if (response.status >= 200 && response.status < 300) {
				return response.blob();
			} else {
				return this.handleAPIError(response.json());
			}
		} catch (e) {
			this.handleAPIError();
		}
	};

	postFile = async (path: string, fileName: string, file: any): Promise<any> => {
		let token = await this.authProvider.getToken();
		const options = {
			method: "POST",
			headers: new Headers({
				Authorization: "Bearer " + token,
				Accept: "*/*",
				"Content-Type": "binary/octet-stream",
				// "Content-Filename": fileName,
			}),
			body: file,
		};

		try {
			let response = await fetch(this.baseUrl + path, options);
			return this.handleAPIResponse(response);
		} catch (e) {
			this.handleAPIError();
		}
	};

	//POST
	post = async (path: string, body?: any, omitError?: boolean): Promise<any> => {
		let token = await this.authProvider.getToken();
		const options = {
			method: "POST",
			headers: new Headers({
				Authorization: "Bearer " + token,
				Accept: "application/json",
				"Content-Type": "application/json;charset=UTF-8",
			}),
			body: body ? JSON.stringify(body) : undefined,
		};

		try {
			let response = await fetch(this.baseUrl + path, options);
			return this.handleAPIResponse(response, omitError);
		} catch (e) {
			this.handleAPIError(omitError);
		}
	};

	//PUT
	put = async (path: string, body: any): Promise<any> => {
		let token = await this.authProvider.getToken();
		const options = {
			method: "PUT",
			headers: new Headers({
				Authorization: "Bearer " + token,
				Accept: "application/json",
				"Content-Type": "application/json;charset=UTF-8",
			}),
			body: JSON.stringify(body),
		};

		try {
			let response = await fetch(this.baseUrl + path, options);
			return this.handleAPIResponse(response);
		} catch (e) {
			this.handleAPIError();
		}
	};

	//DELETE
	delete = async (path: string, query?: any, omitError?: boolean): Promise<any> => {
		let token = await this.authProvider.getToken();
		const options = {
			method: "DELETE",
			headers: new Headers({
				Authorization: "Bearer " + token,
			}),
		};
		try {
			let response = await fetch(this.baseUrl + path + this.generateQuery(query), options);
			if (response.status >= 200 && response.status < 300) {
				return { status: response.status };
			} else {
				return this.handleAPIError(response.json(), omitError);
			}
		} catch (e) {
			this.handleAPIError(omitError);
		}
	};

	deleteWithResponse = async (path: string, query?: any): Promise<any> => {
		let token = await this.authProvider.getToken();
		const options = {
			method: "DELETE",
			headers: new Headers({
				Authorization: "Bearer " + token,
			}),
		};
		try {
			let response = await fetch(this.baseUrl + path + this.generateQuery(query), options);
			if (response.status >= 200 && response.status < 300) {
				return response.json();
			} else {
				return this.handleAPIError(response.json());
			}
		} catch (e) {
			this.handleAPIError();
		}
	};
}

export default BaseController;
