// eslint-disable-next-line
import React from "react";
import { BasicLayout, NavBar, NavBarTypes, NavItemState } from "@mit/hui";
import "./Accounts.css";
import AccountList from "./AccountList";
import { connect } from "react-redux";
interface IAccountsState {
  tab: string;
}
class Accounts extends React.Component<any, IAccountsState> {
  constructor(props: any) {
    super(props);

    this.state = {
      tab: "my",
    };
  }

  render() {
    let Id = this.props.match.params.id;
    let type = this.props.match.params.type;

    return (
      <BasicLayout
        fullWidth
        content={
          <AccountList
            tab={this.state.tab}
            accountId={Id}
            accountType={type}
          />
        }
        contentTitle={"Accounts"}
        startState={true}
        navigation={
          !this.props.adminView ? (
            <NavBar
              type={NavBarTypes.Tabs}
              tabs={[
                {
                  icon: "",
                  text: "My Accounts",
                  iconOnly: false,
                  state:
                    this.state.tab === "my"
                      ? NavItemState.Active
                      : NavItemState.None,
                  onClick: () => this.setState({ tab: "my" }),
                },
                {
                  icon: "",
                  text: "Other Accounts",
                  iconOnly: false,
                  state:
                    this.state.tab === "other"
                      ? NavItemState.Active
                      : NavItemState.None,
                  onClick: () => this.setState({ tab: "other" }),
                },
              ]}
            />
          ): <div></div>
        }
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  adminView: state.app.adminView,
  impersonate: state.app.impersonate,
});

export default connect(mapStateToProps)(Accounts);
