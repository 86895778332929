import { ActionList, DropdownItemProps, FormContainer, FormField, GridContainer, LayoutColumn } from "@mit/hui";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AudioCodesPortModel, DeviceModel } from "../api/models/DeviceModel";
import { PairModel } from "../api/models/PairModel";
import PairController from "../api/PairController";
import StringProvider from "../services/StringProvider";
import * as notificationActionCreator from "../common/redux/actions/notification";
import { uuidv4 } from "../common/redux/actions/notification";
import AuthProvider from "../services/AuthProvider";

interface IPortAssignmentProps {
	device: DeviceModel;
	port: AudioCodesPortModel;
	close: (response?: any) => void;
	actions?: any;
}

interface PortAssignmentState {
	pair: PairModel;
	device: DeviceModel;
	port: AudioCodesPortModel;
	switchSiteData: DropdownItemProps[];
	framePartitionData: DropdownItemProps[];
	isLoading: boolean;
	isBusy: boolean;
	formError: boolean;
	audio_codes_port: number;
	audio_codes_hostname: string;
}

class PortAssignment extends React.Component<IPortAssignmentProps, PortAssignmentState> {
	pairController: PairController;
	stringProvider: StringProvider;
	_isMounted: boolean;
	newPair: PairModel;
	authProvider: AuthProvider

	constructor(props: any) {
		super(props);

		//This class did not take any special action on timeout
		//It will continue to act in such a way
		const onSessionTimeout = () => {}
		this.authProvider = new AuthProvider(onSessionTimeout)
		this.pairController = new PairController(this.authProvider);
		this.stringProvider = new StringProvider();
		this._isMounted = false;

		this.newPair = {
			switch_site: "",
			frame_partition: "",
			pair_number: 0,
			status: "",
			notes: null,
		};

		this.state = {
			device: this.props.device,
			port: this.props.port,
			pair: this.newPair,
			isLoading: false,
			isBusy: false,
			formError: false,
			switchSiteData: [],
			framePartitionData: [],
			audio_codes_hostname: this.props.device.hostname || "",
			audio_codes_port: this.props.port.port,
		};
	}

	componentDidMount() {
		this._isMounted = true;
		const fetchLookups = async () => {
			const switchSites = await this.pairController.getSwitchSites();
			const framePartitions = await this.pairController.getFramePartitions();
			this._isMounted &&
				this.setState({
					switchSiteData: this.generateDropdownItems(this.stringProvider.listCapitalizeAll(switchSites)),
					framePartitionData: this.generateDropdownItems(this.stringProvider.listCapitalizeFirst(framePartitions)),
				});
		};
		this._isMounted && fetchLookups();
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	generateDropdownItems = (items: string[]) => {
		const dropdownData: DropdownItemProps[] = [];
		items.forEach((item: string) => {
			dropdownData.push({ text: item, icon: "" });
		});
		return dropdownData;
	};

	findPair = () => {
		if (!this.state.formError) {
			this.setState({ isBusy: true });
			this.pairController.findPair(this.state.pair.switch_site, this.state.pair.frame_partition, this.state.pair.pair_number.toString()).then((response: PairModel[]) => {
				if (response.length === 1) {
					this.setState({
						pair: response[0],
						isBusy: false,
					});
				} else {
					this.setState({
						isBusy: false,
						pair: this.newPair,
					});
				}
			});
		}
	};

	assignPair = () => {
		if (!this.state.formError) {
			this.setState({ isBusy: true });
			this.pairController.findPair(this.state.pair.switch_site, this.state.pair.frame_partition, this.state.pair.pair_number.toString()).then((response: PairModel[]) => {
				if (response.length === 1) {
					this.setState({
						pair: response[0],
					});
					let updatedPair = this.state.pair;
					updatedPair.audio_codes_hostname = this.state.audio_codes_hostname;
					updatedPair.audio_codes_port = this.state.audio_codes_port;
					this.pairController.updatePair(this.state.pair).then((response) => {
						this.setState({ isBusy: false });
						this.props.close(response);
					});
				} else {
					this.props.actions.addNotification(uuidv4(), "Error", "Pair not found", "The pair you are trying to assign to this port does not exist", [], 1);
					this.setState({
						isBusy: false,
					});
				}
			});
		}
	};

	render() {
		if (this.state.isLoading) return <ActionList isLoading items={[]} />;
		return (
			<Fragment>
				<FormContainer
					action={this.assignPair}
					submitText="Assign Pair"
					actionIsBusy={this.state.isBusy}
					isValid={(e: any) => this.setState({ formError: !e })}
					formValidationTrigger="onChange"
					id="portAssignFrom">
					<GridContainer showGutters={true}>
						<LayoutColumn colSize={12}>
							<FormField
								id="switchSite"
								editor="dropdown"
								options={this.state.switchSiteData}
								label="Switch Site"
								labelDisplay="ontop"
								value={this.state.pair.switch_site}
								onChange={(e: any) => {
									let updatedPair = this.state.pair;
									updatedPair.switch_site = e.text;
									this.setState({ pair: updatedPair });
								}}
								required={true}
							/>
							<FormField
								id="framePartition"
								editor="dropdown"
								options={this.state.framePartitionData}
								label="Frame Partition"
								labelDisplay="ontop"
								value={this.state.pair.frame_partition}
								onChange={(e: any) => {
									let updatedPair = this.state.pair;
									updatedPair.frame_partition = e.text;
									this.setState({ pair: updatedPair });
								}}
								required={true}
							/>
							<FormField
								id="pairNumber"
								value={this.state.pair.pair_number || ""}
								onChange={(e: any) => {
									let updatedPair = this.state.pair;
									updatedPair.pair_number = e.currentTarget.value;
									this.setState({ pair: updatedPair });
								}}
								editor="textbox"
								label="Pair Number"
								labelDisplay="ontop"
								properties={{ numericOnly: true }}
								required={true}
							/>
						</LayoutColumn>
					</GridContainer>
					{this.state.pair.uuid && (
						<GridContainer showGutters={true}>
							<LayoutColumn colSize={12}>
								<FormField id="port" value={this.state.audio_codes_port} editor="textbox" label="AudioCodes Port" labelDisplay="ontop" readonly={true} />
								<FormField id="port" value={this.state.audio_codes_hostname} editor="textbox" label="Device Hostname" labelDisplay="ontop" readonly={true} />
							</LayoutColumn>
						</GridContainer>
					)}
				</FormContainer>
			</Fragment>
		);
	}
}

const mapDispatchToProps = (dispatch: any) => ({
	actions: bindActionCreators(notificationActionCreator, dispatch),
});

export default connect(null, mapDispatchToProps)(PortAssignment);
