import { ActionList, DropdownItemProps, FormContainer, FormField, GridContainer, LayoutColumn } from "@mit/hui";
import React, { Fragment } from "react";
import DeviceController from "../api/DeviceController";
import { PairedDevice } from "../api/models/DeviceModel";
import { store } from "../common/redux/store/store";
import AuthProvider from "../services/AuthProvider";
export const globalStore = store;

interface IDevicePairingProps {
	deviceId: string;
	close: (device?: PairedDevice) => void;
}

interface DevicePairingState {
	mac: string;
	device: PairedDevice;
	isLoading: boolean;
	formError: boolean;
	isBusy: boolean;
	newDevice: boolean;
	deviceModelData: DropdownItemProps[];
}

class DevicePairing extends React.Component<IDevicePairingProps, DevicePairingState> {
	deviceController: DeviceController;
	_isMounted: boolean;
	authProvider: AuthProvider

	constructor(props: any) {
		super(props);

		//This class did not take any special action on timeout
		//It will continue to act in such a way
		const onSessionTimeout = () => {}
		this.authProvider = new AuthProvider(onSessionTimeout)
		this.deviceController = new DeviceController(this.authProvider);
		this._isMounted = false;

		this.state = {
			mac: "",
			device: {},
			isLoading: false,
			formError: false,
			isBusy: false,
			newDevice: false,
			deviceModelData: this.generateDropdownItems(globalStore.getState().lookups.deviceModels),
		};
	}

	generateDropdownItems = (items: any[]): DropdownItemProps[] => {
		const dropdownData: DropdownItemProps[] = [];
		items.forEach((item: any) => {
			if (item.hw_pairing && item.hw_pairing.pairable && item.hw_pairing.pairing_role === "secondary") {
				dropdownData.push({ text: item.key, icon: "" });
			}
		});
		return dropdownData;
	};

	deviceLookup = () => {
		if ((this.props.deviceId && this.state.mac, this.state.mac.match(/^([0-9a-fA-F]{2}[.:-]?){5}([0-9a-fA-F]{2})$/))) {
			this.setState({ isLoading: true });
			this.deviceController.pairDeviceLookup(this.props.deviceId, this.state.mac).then((response) => {
				if (response && response.uuid) {
					this.setState({
						device: response,
						isLoading: false,
						newDevice: response.uuid === "new",
					});
				} else {
					this.setState({ isLoading: false, device: { mac: this.state.device.mac } });
				}
			});
		}
	};

	pairDevice = () => {
		if (this.state.device && this.state.device.uuid) {
			this.setState({ isBusy: true });
			this.deviceController.pairDevice(this.props.deviceId, this.state.device).then((response) => {
				if (response) {
					this.props.close(this.state.device);
				}
				this.setState({ isBusy: false });
			});
		}
	};

	render() {
		if (this.state.isLoading) return <ActionList isLoading items={[]} />;
		return (
			<Fragment>
				<FormContainer
					action={this.pairDevice}
					submitText="Pair Device"
					actionIsBusy={this.state.isBusy || this.state.formError}
					secondaryAction={() => this.props.close()}
					secondaryActionText={"Cancel"}
					isValid={(e: any) => this.setState({ formError: !e })}
					formValidationTrigger="onChange"
					id="devicePairForm">
					<GridContainer showGutters={true}>
						<LayoutColumn colSize={12}>
							<FormField
								id="macAddress"
								value={this.state.device.mac}
								onChange={(e: any) => {
									this.setState({ mac: e.currentTarget.value });
								}}
								editor="textbox"
								label="MAC Address"
								labelDisplay="ontop"
								onBlur={this.deviceLookup}
								pattern={{
									value: /^([0-9a-fA-F]{2}[.:-]?){5}([0-9a-fA-F]{2})$/,
									message: "MAC Address format",
								}}
								required={true}
							/>
							{this.state.device && this.state.device.uuid && (
								<div>
									<FormField
										id="deviceModel"
										editor="dropdown"
										options={this.state.deviceModelData}
										label="Device Model"
										labelDisplay="ontop"
										value={this.state.device.model}
										onChange={(e: any) => {
											let updateDevice = this.state.device;
											updateDevice.model = e.text;
											this.setState({ device: updateDevice });
										}}
										properties={{ readOnly: this.state.device.uuid !== "new" }}
										required={true}
									/>
									<FormField
										id="deviceDescription"
										value={this.state.device.description}
										editor="textbox"
										label="Description"
										labelDisplay="ontop"
										onChange={(e: any) => {
											let updatedDevice = this.state.device;
											updatedDevice.description = e.currentTarget.value;
											this.setState({ device: updatedDevice });
										}}
									/>
								</div>
							)}
						</LayoutColumn>
					</GridContainer>
				</FormContainer>
			</Fragment>
		);
	}
}

export default DevicePairing;
