import { FormContainer, FormField, GridContainer, LayoutColumn, Text } from "@mit/hui";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { store } from "../common/redux/store/store";
import AccountController from "../api/AccountController";
import { AccountModel } from "../api/models/AccountModel";
import * as notificationActionCreator from "../common/redux/actions/notification";
import { uuidv4 } from "../common/redux/actions/notification";
import AuthProvider from "../services/AuthProvider";
export const globalStore = store;

interface IVoicemailPasscodeProps {
	account: AccountModel;
	actions?: any;
	close: () => void;
}

interface VoicemailPasscodeState {
	voicemailPasscode: string;
	isBusy: boolean;
	formError: boolean;
}

class VoicemailPasscode extends React.Component<IVoicemailPasscodeProps, VoicemailPasscodeState> {
	accountController: AccountController;
	_isMounted: boolean;
	authProvider: AuthProvider

	constructor(props: any) {
		super(props);

		//This class did not take any special action on timeout
		//It will continue to act in such a way
		const onSessionTimeout = () => {}
		this.authProvider = new AuthProvider(onSessionTimeout)
		this.accountController = new AccountController(this.authProvider);
		this._isMounted = false;

		this.state = {
			voicemailPasscode: "",
			isBusy: false,
			formError: false,
		};
	}

	setVoicemailPin = async () => {
		if (!this.state.formError && this.state.voicemailPasscode.match(/^[0-9]{4,8}$/)) {
			this.setState({ isBusy: true });
			await this.accountController.setVoicemailPin(this.props.account, this.state.voicemailPasscode).then((response) => {
				if (response) {
					this.props.actions.addNotification(uuidv4(), "Success", "New Voicemail Passcode", "Voicemail Passcode Successfully Updated");
				} else {
					this.props.actions.addNotification(uuidv4(), "Error", "New Voicemail Passcode", "Voicemail Passcode was not updated", [], 1);
				}
				this.setState({
					isBusy: false,
				});
				this.props.close();
			});
		}
	};

	render() {
		return (
			<Fragment>
				<FormContainer
					action={this.setVoicemailPin}
					submitText="Submit"
					actionIsBusy={this.state.isBusy}
					isValid={(e: any) => this.setState({ formError: !e })}
					formValidationTrigger="onChange"
					id="voiceMailPinForm">
					<GridContainer showGutters={true}>
						<LayoutColumn colSize={12}>
							<FormField
								id="pin"
								value={this.state.voicemailPasscode}
								onChange={(e: any) => {
									this.setState({ voicemailPasscode: e.currentTarget.value });
								}}
								editor="textbox"
								label="New Passcode:"
								labelDisplay="ontop"
								properties={{ clearable: true }}
								pattern={{
									value: /^[0-9]{4,8}$/,
									message: "Numeric between 4 - 8",
								}}
								required={true}
							/>
						</LayoutColumn>
					</GridContainer>
					<GridContainer>
						<LayoutColumn padded alignment="left" colSize={12}>
							<Text content="To avoid receiving an error when creating your new passcode remember these validations:" />
							<Text content="- Must be 4-8 numbers" />
							<Text content="- A repeating sequence of numbers (e.g., 2222), is not valid" />
							<Text content="- Can not use the same numbers as your phone extension" />
							<Text content="- For security, avoid choosing number sequences (e.g., 1234)" />
						</LayoutColumn>
					</GridContainer>
				</FormContainer>
			</Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({
	adminView: state.app.adminView,
	impersonate: state.app.impersonate,
});

const mapDispatchToProps = (dispatch: any) => ({
	actions: bindActionCreators(notificationActionCreator, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(VoicemailPasscode);
