import React, { Fragment } from 'react';
import AppController from '../api/AppController';
import { StatusModel } from '../api/models/StatusModel';
import { ActionList, AdvancedTable, IAdvancedTableColumn, Icon } from '@mit/hui';
import AuthProvider from "../services/AuthProvider";

interface StatusState
{
    systemStatuses: StatusModel[];
    isLoading: boolean;
}
class Status extends React.Component<any, StatusState> {
    appController: AppController;
    _isMounted: boolean;
    authProvider: AuthProvider

    constructor(props: any)
    {
        super(props);
        this._isMounted = false;
        this.state = {
            systemStatuses: [],
            isLoading: true
        };

        //This class did not take any special action on timeout
        //It will continue to act in such a way
        const onSessionTimeout = () => {}
        this.authProvider = new AuthProvider(onSessionTimeout)
        this.appController = new AppController(this.authProvider);
    }

    componentDidMount()
    {
        this._isMounted = true;
        const fetchStatuses = async () =>
        {
            const systemStatues = await this.appController.getStatus();
            this._isMounted &&
                this.setState({
                    systemStatuses: systemStatues,
                    isLoading: false
                });
        };
        this._isMounted && fetchStatuses();
    }

    componentWillUnmount()
    {
        this._isMounted = false;
    }

    render()
    {
        if (this.state.isLoading)
            return <ActionList isLoading items={[]} />

        const headers: IAdvancedTableColumn[] = [
            {
                id: 'apiName',
                name: 'API Name'
            },
            {
                id: 'test/key',
                displayId: 'test/value',
                name: 'Test',
                sortable: true
            },
            {
                id: 'result',
                name: 'Result'
            },
            {
                id: 'status',
                name: 'Status'
            }
        ];

        let tableData: any[] = [];


        this.state.systemStatuses.map((system) =>
        {
            let tableItem = {
                apiName: 'Network Infrastructure',
                test: {key: system.subject, value: system.subject},
                result: system.message,
                status: system.state === 'OK' ? <Icon icon={'check'} type={'regular'} color={'green'} /> : <Icon icon={'times'} type={'regular'} color={'red'} />
            };

            tableData.push(tableItem);

            return null;
        })

        return (
            <Fragment>
                <AdvancedTable columns={headers} items={tableData} maxRows={1000} />
                {/*
                    this.state.systemStatuses.length > 0
                    && (
                        <Table>
                            <thead>
                                <tr>
                                    <th>API Name</th>
                                    <th>Test</th>
                                    <th>Result</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.systemStatuses.map((system) =>
                                {
                                    return (
                                        <tr key={system.key}>
                                            <td>Network Infrastructure</td>
                                            <td>{system.subject}</td>
                                            <td>{system.message}</td>
                                            <td
                                                style={
                                                    system.state === 'OK'
                                                        ? { color: 'green' }
                                                        : { color: 'red' }
                                                }
                                            >
                                                {system.state === 'OK' ? <FaCheck /> : <FaTimes />}{' '}
                                                {system.state}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    )*/}
            </Fragment>
        );
    }
}

export default Status;
