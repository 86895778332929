import { ActionList, DropdownItemProps, FormContainer, FormField, GridContainer, LayoutColumn, Text, TextType } from "@mit/hui";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ChangeLogModel } from "../../api/models/ChangeLogModel";
import { PairRangeModel } from "../../api/models/PairRangeModel";
import PairController from "../../api/PairController";
import StringProvider from "../../services/StringProvider";
import * as notificationActionCreator from "../../common/redux/actions/notification";
import { uuidv4 } from "../../common/redux/actions/notification";
import { store } from "../../common/redux/store/store";
import AuthProvider from "../../services/AuthProvider";
export const globalStore = store;

interface IPairRangeProps {
	pairRangeId: string;
	close: () => void;
	updatePairRange: (pairRange: PairRangeModel) => void;
	impersonate?: any;
	actions?: any;
}

interface PairRangeState {
	pairRange: PairRangeModel;
	pairRangeId: string;
	isEdit: boolean;
	switchSiteData: DropdownItemProps[];
	framePartitionData: DropdownItemProps[];
	formError: boolean;
	isLoading: boolean;
	isBusy: boolean;
	rangeOverlap: boolean;
	changeLogs: ChangeLogModel[];
}
class PairRangeDetail extends React.Component<IPairRangeProps, PairRangeState> {
	pairController: PairController;
	stringProvider: StringProvider;
	_isMounted: boolean;
	authProvider: AuthProvider

	constructor(props: any) {
		super(props);

		//This class did not take any special action on timeout
		//It will continue to act in such a way
		const onSessionTimeout = () => {}
		this.authProvider = new AuthProvider(onSessionTimeout)
		this.pairController = new PairController(this.authProvider);
		this.stringProvider = new StringProvider();

		this._isMounted = false;
		this.state = {
			pairRangeId: this.props.pairRangeId,
			isEdit: !!this.props.pairRangeId,
			pairRange: {
				switch_site: "",
				frame_partition: "",
				destination: "",
				range_start: null,
				range_end: null,
			},
			switchSiteData: [],
			framePartitionData: [],
			formError: false,
			isLoading: false,
			rangeOverlap: false,
			isBusy: false,
			changeLogs: [],
		};
	}

	generateDropdownItems = (items: string[]) => {
		const dropdownData: DropdownItemProps[] = [];
		items.forEach((item: string) => {
			dropdownData.push({ text: item, icon: "" });
		});
		return dropdownData;
	};

	componentDidMount() {
		this._isMounted = true;
		const fetchLookups = async () => {
			const switchSites = globalStore.getState().lookups.switchSites;
			const framePartitions = globalStore.getState().lookups.framePartitions;
			this._isMounted &&
				this.setState({
					switchSiteData: this.generateDropdownItems(this.stringProvider.listCapitalizeAll(switchSites)),
					framePartitionData: this.generateDropdownItems(this.stringProvider.listCapitalizeFirst(framePartitions)),
				});
		};
		const fetchRange = async () => {
			this.setState({ isLoading: true });

			const pairRange = await this.pairController.getPairRange(this.state.pairRangeId);
			this._isMounted &&
				this.setState({
					isLoading: false,
					pairRange: this.fromPairRange(pairRange),
				});
		};

		this._isMounted && fetchLookups();
		this._isMounted && this.state.isEdit && fetchRange() && this.updateChangeLog();
	}

	updateChangeLog = async () => {
		const changeLogs = await this.pairController.getPairRangeChangeLog(this.state.pairRangeId);
		this.setState({ changeLogs: changeLogs || [] });
	};

	componentWillUnmount() {
		this._isMounted = false;
	}

	validatePairRange = () => {
		if (
			this.state.pairRange.range_start &&
			this.state.pairRange.range_end &&
			Number.parseInt(this.state.pairRange.range_start.toString()) > Number.parseInt(this.state.pairRange.range_end.toString())
		) {
			this.setState({ rangeOverlap: true });
			this.props.actions.addNotification(
				uuidv4(),
				"Error",
				"Range Overlap",
				`The from range(${this.state.pairRange.range_start}) cannot exceed the to range (${this.state.pairRange.range_end})`,
				[],
				1
			);
		} else {
			this.setState({ rangeOverlap: false });
		}
	};

	createPairRange = () => {
		if (!this.state.formError && !this.state.rangeOverlap) {
			this.setState({ isBusy: true });
			this.pairController
				.createPairRange(this.toPairRange(this.state.pairRange))
				.then((response) => {
					if (response) {
						this.props.actions.addNotification(
							uuidv4(),
							"Success",
							"Create",
							`Pair range ${this.state.pairRange.range_start} - ${this.state.pairRange.range_end} was successfully created`
						);
						this.setState({
							pairRange: this.fromPairRange(response),
						});
						this.props.updatePairRange(response);
						let drawer = document.getElementsByClassName("drawer")[0];
						drawer.scroll(0, 0);
					} else {
						this.setState({
							pairRange: this.fromPairRange(this.state.pairRange),
						});
					}
					this.setState({
						isBusy: false,
					});
				})
				.catch((error) => this.setState({ isBusy: false }));
		}
	};

	updatePairRange = () => {
		if (!this.state.formError && !this.state.rangeOverlap) {
			this.setState({ isBusy: true });
			this.pairController
				.updatePairRange(this.toPairRange(this.state.pairRange))
				.then((response) => {
					if (response) {
						this.props.actions.addNotification(
							uuidv4(),
							"Success",
							"Update",
							`Pair range ${this.state.pairRange.range_start} - ${this.state.pairRange.range_end} was successfully updated`
						);
						this.setState({
							pairRange: this.fromPairRange(response),
						});
						this.props.updatePairRange(response);
						let drawer = document.getElementsByClassName("drawer")[0];
						drawer.scroll(0, 0);
						this.updateChangeLog();
					} else {
						this.setState({
							pairRange: this.fromPairRange(this.state.pairRange),
						});
					}
					this.setState({
						isBusy: false,
					});
				})
				.catch((error) => this.setState({ isBusy: false }));
		}
	};

	render() {
		if (this.state.isLoading) return <ActionList isLoading items={[]} />;
		return (
			<Fragment>
				<FormContainer
					action={() => (this.state.isEdit ? this.updatePairRange() : this.createPairRange())}
					actionIsBusy={this.state.isBusy}
					submitText="Save"
					secondaryAction={() => {
						this.props.close();
					}}
					secondaryActionText="Cancel"
					isValid={(e: any) => this.setState({ formError: !e })}
					formValidationTrigger="onChange"
					id="PairRangeForm">
					{this.state.isEdit && this.state.changeLogs && this.state.changeLogs.length > 0 ? (
						<div className="metadata mb-4">
							<GridContainer showGutters={true}>
								<LayoutColumn colSize={6}>
									<Text type={TextType.Body} content="Created By" padded={false} />
								</LayoutColumn>
								<LayoutColumn colSize={6}>
									<Text
										type={TextType.Body}
										content={
											this.state.changeLogs.filter((log) => log.action === "add")[0].editor + " " + this.state.changeLogs.filter((log) => log.action === "add")[0].action_date
										}
										padded={false}
									/>
								</LayoutColumn>
							</GridContainer>
							<GridContainer showGutters={true}>
								<LayoutColumn colSize={6}>
									<Text type={TextType.Body} content="Modified By" padded={false} />
								</LayoutColumn>
								<LayoutColumn colSize={6}>
									<Text
										type={TextType.Body}
										content={this.state.changeLogs[this.state.changeLogs.length - 1].editor + " " + this.state.changeLogs[this.state.changeLogs.length - 1].action_date}
										padded={false}
									/>
								</LayoutColumn>
							</GridContainer>
						</div>
					) : (
						<div></div>
					)}

					<GridContainer showGutters={true}>
						<LayoutColumn colSize={6}>
							<FormField
								id="switchSite"
								editor="dropdown"
								options={this.state.switchSiteData}
								label="Switch Site"
								labelDisplay="ontop"
								value={this.state.pairRange.switch_site}
								onChange={(e: any) => {
									let updatedRange = this.state.pairRange;
									updatedRange.switch_site = e.text;
									this.setState({ pairRange: updatedRange });
								}}
								properties={{ readOnly: this.state.isEdit }}
							/>
							<FormField
								id="destination"
								value={this.state.pairRange.destination}
								onChange={(e: any) => {
									let updatedRange = this.state.pairRange;
									updatedRange.destination = e.currentTarget.value;
									this.setState({ pairRange: updatedRange });
								}}
								editor="textbox"
								label="Destination"
								labelDisplay="ontop"
								required={true}
							/>
						</LayoutColumn>
						<LayoutColumn colSize={6}>
							<FormField
								id="framePartition"
								editor="dropdown"
								options={this.state.framePartitionData}
								label="Frame Partition"
								labelDisplay="ontop"
								value={this.state.pairRange.frame_partition}
								onChange={(e: any) => {
									let updatedRange = this.state.pairRange;
									updatedRange.frame_partition = e.text;
									this.setState({ pairRange: updatedRange });
								}}
								properties={{ readOnly: this.state.isEdit }}
								required={true}
							/>
						</LayoutColumn>
					</GridContainer>
					<GridContainer showGutters={true}>
						<LayoutColumn colSize={6}>
							<FormField
								id="fromRange"
								value={this.state.pairRange.range_start}
								properties={{ properties: { type: "number" } }}
								onBlur={this.validatePairRange}
								onChange={(e: any) => {
									let updatedRange = this.state.pairRange;
									updatedRange.range_start = e.currentTarget.value;
									this.setState({ pairRange: updatedRange });
								}}
								editor="textbox"
								label="Pair Range From"
								labelDisplay="ontop"
								required={true}
							/>
						</LayoutColumn>
						<LayoutColumn colSize={6}>
							<FormField
								id="toRange"
								value={this.state.pairRange.range_end}
								properties={{ properties: { type: "number" } }}
								onBlur={this.validatePairRange}
								onChange={(e: any) => {
									let updatedRange = this.state.pairRange;
									updatedRange.range_end = e.currentTarget.value;
									this.setState({ pairRange: updatedRange });
								}}
								editor="textbox"
								label="Pair Range To"
								labelDisplay="ontop"
								required={true}
							/>
						</LayoutColumn>
					</GridContainer>
				</FormContainer>
			</Fragment>
		);
	}

	//API Transformations
	toPairRange = (pairRange: PairRangeModel): PairRangeModel => {
		pairRange.frame_partition = pairRange.frame_partition.toLowerCase();
		pairRange.switch_site = pairRange.switch_site.toLowerCase();
		return pairRange;
	};

	fromPairRange = (pairRange: PairRangeModel): PairRangeModel => {
		pairRange.frame_partition = this.stringProvider.capitalizeFirstLetter(pairRange.frame_partition);
		pairRange.switch_site = pairRange.switch_site.toUpperCase();
		return pairRange;
	};
}

const mapDispatchToProps = (dispatch: any) => ({
	actions: bindActionCreators(notificationActionCreator, dispatch),
});

export default connect(null, mapDispatchToProps)(PairRangeDetail);
