const editDistance = (s1 : string, s2 : string) => {
		s1 = s1.toLowerCase();
		s2 = s2.toLowerCase();

		let costs = [];
		for (let i = 0; i <= s1.length; i++) {
			let lastValue = i;
			for (let j = 0; j <= s2.length; j++) {
				if (i === 0)
					costs[j] = j;
				else {
					if (j > 0) {
						let newValue = costs[j - 1];
						if (s1.charAt(i - 1) !== s2.charAt(j - 1))
							newValue = Math.min(Math.min(newValue, lastValue),
								costs[j]) + 1;
						costs[j - 1] = lastValue;
						lastValue = newValue;
					}
				}
			}
			if (i > 0)
				costs[s2.length] = lastValue;
		}
		return costs[s2.length];
	}

const similarity = (s1 : string, s2 : string) => {
    let longer = s1;
    let shorter = s2;

    if (s1.length < s2.length) {
        longer = s2;
        shorter = s1;
    }

    let longerLength = longer.length;

    if (longerLength === 0) {
        return 1.0;
    }
    longerLength.toFixed(2);

    return (longerLength - editDistance(longer, shorter)) / longerLength;
}

export const sortByRelevance = (searchTerm : string) =>{
    return (a : any,b : any) => {

        let aSimularityToQuery = similarity(a.secondaryText,searchTerm);
        let bSimularityToQuery = similarity(b.secondaryText,searchTerm);

        a.relevance = aSimularityToQuery;
        b.relevance = bSimularityToQuery;

        return aSimularityToQuery === bSimularityToQuery ? 0 :
               aSimularityToQuery > bSimularityToQuery ? 1 : -1
    }
}