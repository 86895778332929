import React, { Fragment } from "react";
import History from "./common/History";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { Router, Route, Switch } from "react-router-dom";
import { Loader } from "@mit/hui";
import { store } from "./common/redux/store/store";
import App from "./app/App";
import Logout from "./common/Logout";
export const globalStore = store;

class Init extends React.Component {
	render(): React.ReactNode {
		return (
			<Fragment>
				<Provider store={globalStore}>
					<Router history={History}>
						<Switch>
							<Route path="/" component={App} exact />
							<Route path="/:admin/accounts/:type?/:id?" component={App} exact />
							<Route path="/:admin/devices/:id?" component={App} exact />
							<Route path="/:admin/pairs/:id?" component={App} exact />
							<Route path="/:admin/pairs/pair-tracking/:id?" component={App} exact />
							<Route path="/:admin/pairs/pair-range-tracking/:id?" component={App} exact />
							<Route path={"/logout"} component={Logout} />
							<Route path="*">
								<Loader contactEmail={"voip-help@mit.edu"} pageNotFound={true} name="MITvoip" />
							</Route>
						</Switch>
					</Router>
				</Provider>
			</Fragment>
		);
	}
}

render(<Init />, document.getElementById("root"));
