import { FormContainer, FormField, GridContainer, LayoutColumn, Text, ActionList,Error,
	ErrorContext, ErrorType, } from "@mit/hui";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { store } from "../common/redux/store/store";
import AccountController from "../api/AccountController";
import { AccountModel } from "../api/models/AccountModel";
import * as notificationActionCreator from "../common/redux/actions/notification";
import { uuidv4 } from "../common/redux/actions/notification";
import AppController from "../api/AppController";
import {sortByRelevance} from "../searchUtilities";
import {AccountServiceConversionJobModel } from "../api/models/AccountServiceConversionJobModel";
import AuthProvider from "../services/AuthProvider";

export const globalStore = store;
const WAIT_INTERVAL = 200;
let timerID: any;

interface IAccountServiceConversionProps {
	account: AccountModel;
	actions?: any;
	close: () => void;
	converted: () => void;
}

interface AccountServiceConversionState {
	account: AccountModel;
	isBusy: boolean;
	isLoading: boolean;
	formError: boolean;
	isLoadingSoftphoneUser: boolean;
	softPhoneUserList: any[];
	accountServiceConversionMessage:any;
	isError: boolean;
}

class AccountServiceConversion extends React.Component<IAccountServiceConversionProps, AccountServiceConversionState> {
	accountController: AccountController;
	appController: AppController;
	_isMounted: boolean;
	authProvider: AuthProvider

	constructor(props: any) {
		super(props);

		//This class did not take any special action on timeout
		//It will continue to act in such a way
		const onSessionTimeout = () => {}
		this.authProvider = new AuthProvider(onSessionTimeout)
		this.accountController = new AccountController(this.authProvider);
		this.appController = new AppController(this.authProvider);
		this._isMounted = false;

		this.state = {
			account: props.account,
			isBusy: false,
			isLoading: true,
			formError: false,
			isLoadingSoftphoneUser: false,
			softPhoneUserList: [],
			accountServiceConversionMessage:"",
			isError:false,
		};
		this.getAccountServiceConversionMessage(this.props.account.uuid);
	}
	getAccountServiceConversionMessage = async (accountUUID: any) => {
		let  msg = await this.accountController.getAccountServiceConversionMessage(accountUUID);
		this.setState({ isLoading: false });
		let messageText;
		if(msg){
			messageText=msg.message;
			this.setState({ accountServiceConversionMessage: messageText });
		}else{
			this.setState({isError:true});
		}

		return messageText;
	}
	convertAccount = () => {
		if(this.state.account.account_service_switch_username_required){
			//Need kerbid to switch to user
			if(this.state.account.softclient_username){
				this.startAccountServiceConversion(this.state.account.softclient_username && 
					this.state.account.softclient_username.length > 0 ? 
					this.state.account.softclient_username[0].cn : "" );
			}
		}else{
			//No name needed to switch to service line
			this.startAccountServiceConversion(null);
		}
	}

	searchUsers = (searchTerm: string, loadingType: any, listType: any) => {

		this.appController.searchUsers(searchTerm).then((people: any) => {
			//check if status not 200 (null is return)

			if (people === null || people.name === "TypeError") {
				this.setState({ ...this.state, [loadingType]: false });
				return;
			}

			//Check if request was aborted
			if (people.name !== "AbortError") {
				const newData = people.map((itm: any) => {
					return {
						data: itm,
						icon: "user",
						text: itm.cn,
						secondaryText: itm.displayname,
						onClick: () => null,
					};
				}).sort(sortByRelevance(searchTerm));
				this.props.actions.closeNotification();
				this.setState({
					...this.state,
					[listType]: newData,
					[loadingType]: false,
				});
			}
		});
	}

	underMinimumLength = (q: string, listType: any, loadingType: any) => {
		if (q.length < 1) {
			this.setState({ ...this.state, [listType]: [], [loadingType]: false } );
			return true;
		}

		if (q.length < 3){
			this.setState( {...this.state,[loadingType]: true});
			return true;
		}

		this.setState( {...this.state,[loadingType]: false});
		return false;
	}

	bind_trailing_args = (fn : any, ...bound_args : any) => {
		return function(...args : any) {
			return fn(...args, ...bound_args);
		};
	}

	onHandleSearch = (searchTerm: string, e: any, listComponentName: string, loadingComponentName: string) =>{
		clearTimeout(timerID);
		const state = this.state;
		const loadingType = loadingComponentName as keyof typeof state;
		const listType = listComponentName as keyof typeof state;

		if( !this.underMinimumLength(searchTerm,listType,loadingType)){

			if (e.keyCode === 8 || e.keyCode === 46) return;

			this.setState({...this.state,[loadingType]: true});

			timerID = setTimeout(() => {
				this.props.actions.closeNotification();

				this.searchUsers(searchTerm,loadingType,listType);
			}, WAIT_INTERVAL);
		}
	}
	onHandleSoftphoneUserChange = (user: any) => {
		let updatedAccount: AccountModel = this.state.account;
		updatedAccount.softclient_username = user && user.data ? [user.data] : null;
		this.setState({ account: updatedAccount });
	};

	handelSoftphoneUserClear = () => {
		let updatedAccount: AccountModel = this.state.account;
		updatedAccount.softclient_username = null;
		this.setState({ account: updatedAccount });
	};

	completeAccountServiceConversion = async (job: AccountServiceConversionJobModel, firstRun?: boolean) => {
		// console.log("completeAccountServiceConversion job=", job);
		let monitorJob = await this.accountController.monitorAccountServiceConversionJob(job.uuid);
		let drawer = document.getElementsByClassName("drawer")[0];

		if (monitorJob && monitorJob.status === "done") {
			this.props.actions.addNotification(uuidv4(), "Success", "Conversion", "Account was successfully switched");
			this.setState({isBusy: false});
			this.props.converted();
			drawer.scroll(0, 0);

		} else if (monitorJob && monitorJob.status === "pending") {
			if (firstRun) {
				this.props.actions.addNotification(uuidv4(), "Progress", "Conversion", "Account service conversion still in progress, please be patient");
				drawer.scroll(0, 0);
			}
			setTimeout(() => this.completeAccountServiceConversion(job), 5000);
		} else {
			drawer.scroll(0, 0);
			this.props.actions.addNotification(uuidv4(), "Error", "Conversion", "Account was not successfully switched", [], 1);

			this.props.actions.addNotification(
				uuidv4(),
				"Error",
				"Account service conversion",
				monitorJob.error,
				[],
				1
			);
			this.setState({isBusy: false});
			this.props.converted();
		}
	};

	startAccountServiceConversion = (kerbid: any) => {
		this.setState({isBusy: true});
		this.accountController
			.startAccountServiceConversion(this.state.account.uuid, kerbid)
			.then(async (response) => {
				if (response && response.job) {
					await this.accountController.completeAccountServiceConversion(response.job.uuid);
					await this.completeAccountServiceConversion(response.job, true);
				} else {
					this.props.actions.addNotification(uuidv4(), "Error", "Conversion", "Account was not switched", [], 1);
					this.setState({isBusy: false});
					this.props.close();
				}
			})
			.catch((error) => {
				this.props.actions.addNotification(uuidv4(), "Error", "Conversion", "Account was not switched", [], 1);

				if(error?.message){
					this.props.actions.addNotification(
						uuidv4(),
						"Error",
						"Account service conversion",
						 error.message,
						[],
						1
					);
				}
				this.setState({isBusy: false});
				this.props.close();
			});
	}

	render() {
		if (this.state.isError) return <Error context={ErrorContext.Component} message={"Failed to retrieve Account Details"} type={ErrorType.Generic} />;
		if (this.state.isLoading) return <ActionList isLoading items={[]} />;
		return (
			<Fragment>
				<FormContainer
					action={this.convertAccount}
					submitText="Convert"
					actionIsBusy={this.state.isBusy}
					secondaryAction={() => this.props.close()}
					secondaryActionText="Cancel"
					isValid={(e: any) => this.setState({ formError: !e })}
					formValidationTrigger="onChange"
					id="voiceMailPinForm">
					<GridContainer>
						<LayoutColumn padded alignment="left" colSize={12}>
							<Text content={this.state.accountServiceConversionMessage} />								
						</LayoutColumn>
					</GridContainer>
					<GridContainer showGutters={true}>
						<LayoutColumn colSize={12}>
							{(this.state.account.account_service_switch && this.state.account.account_service_switch_username_required) && (
								<FormField
									id="softphoneClient"
									editor="autocomplete"
									properties={{
										clearOffSelect: true,
										onSearch: this.bind_trailing_args(this.onHandleSearch,"softPhoneUserList","isLoadingSoftphoneUser"),
										isBusy: this.state.isLoadingSoftphoneUser,
										value:
											this.state.account.softclient_username && this.state.account.softclient_username.length > 0 ? this.state.account.softclient_username[0].cn : "",
										limit: 1000,
										clearOnBlur: true,
										onClear: this.handelSoftphoneUserClear,
										onChange: this.onHandleSoftphoneUserChange,
									}}
									onChange={this.onHandleSoftphoneUserChange}
									label="Softphone Username"
									labelDisplay="ontop"
									searchOptions={{
										name: "softclientSearch",
										placeholderText: "KerbID or Name",
									}}
									items={this.state.softPhoneUserList}
									required={true}
								/>
							)}

							{/* {(this.state.account.account_service_switch && !this.state.account.account_service_switch_username_required) && (
								<GridContainer>
									<LayoutColumn padded alignment="left" colSize={12}>
										<Text content="Confirm convert to service account" />		
									</LayoutColumn>
								</GridContainer>
							)} */}
						</LayoutColumn>
					</GridContainer>
				</FormContainer>
			</Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({
	adminView: state.app.adminView,
	impersonate: state.app.impersonate,
});

const mapDispatchToProps = (dispatch: any) => ({
	actions: bindActionCreators(notificationActionCreator, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountServiceConversion);
