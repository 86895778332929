import React from 'react';
import {
  Button,
  ButtonType,
  TemplateModalSize,
  Text,
  TextType,
} from '@mit/hui';
import Modal from './Modal';

interface ConfirmationProps {
  show: boolean;
  onClose: any;
  body: string;
  isBusy?: boolean;
  title: string;
  name: string;
  callBack(): void;
}

export default class Confirmation extends React.Component<ConfirmationProps> {
  render() {
    //const ModalData = withComponent(Modal);

    return (
      <Modal
        onClose={this.props.onClose}
        show={this.props.show}
        containerless={false}
        size={TemplateModalSize.Default}
        body={<Text type={TextType.Body} content={this.props.body}></Text>}
        footer={[
          <Button
            key='Modal-no'
            text='No'
            type={ButtonType.Secondary | ButtonType.Ghost}
            onClick={this.props.onClose}
          />,
          <Button key='Modal-yes' text='Yes' onClick={this.props.callBack} isBusy={this.props.isBusy ? this.props.isBusy : false} />,
        ]}
        header={
          <Text
            content={this.props.title}
            type={TextType.Heading4}
          />
        }
        name={this.props.name}
      />
    );
  }
}
