import React from 'react';
import { NavBar, NavBarTypes } from '@mit/hui';
import { connect } from 'react-redux';

enum NavItemState
{
    None,
    Disabled,
    Active,
}

class Navigation extends React.Component<any>
{
    render()
    {
        let defaultTabData = [
            {
                key: 'main-nav-action-1',
                icon: 'users',
                iconOnly: false,
                text: 'Accounts',
                to: `${this.props.adminView ? '/admin' : '/user'}/accounts`,
                state: window.location.pathname.includes('accounts')
                    ? NavItemState.Active
                    : NavItemState.None,
            },
            {
                key: 'main-nav-action-2',
                icon: 'phone',
                iconOnly: false,
                text: 'Devices',
                to: `${this.props.adminView ? '/admin' : '/user'}/devices`,
                state: window.location.pathname.includes('devices')
                    ? NavItemState.Active
                    : NavItemState.None,
            }
        ];

        if (this.props.adminView && this.props.userRole !== 'HELPDESK' && this.props.userRole !== 'UNPRIV_USER')
            defaultTabData.push({
                key: 'main-nav-action-3',
                icon: 'grip-lines',
                iconOnly: false,
                text: 'Pairs',
                to: `${this.props.adminView ? '/admin' : '/user'}/pairs`,
                state: window.location.pathname.includes('pairs')
                    ? NavItemState.Active
                    : NavItemState.None,
            })

        return <NavBar type={NavBarTypes.IconText} tabs={defaultTabData} />;
    }
}

const mapStateToProps = (state: any) => ({
    adminView: state.app.adminView,
    userRole: state.app.userRole
});

export default connect(mapStateToProps)(Navigation);

