// eslint-disable-next-line
import React from 'react';
import
{
    BasicLayout,
    NavBar,
    NavBarTypes,
    NavItemProps,
    NavItemState,
} from '@mit/hui';
import PairList from './pairs/PairList';
import PairRangeList from './pair-ranges/PairRangeList';
import { Redirect, Route, Router, Switch } from 'react-router';
import History from '../common/History';
import { connect } from 'react-redux';

interface PairsProps
{
    pair?: string;
    match: any;
    adminView?: any;
}
class Pairs extends React.Component<PairsProps> {
    pairTabData: NavItemProps[] = [
        {
            icon: '',
            text: 'Pairs',
            iconOnly: false,
            state: window.location.pathname.includes("pair-tracking") ? NavItemState.Active : NavItemState.None,
            to: `${this.props.adminView ? '/admin' : '/user'}/pairs/pair-tracking`,
        },
        {
            icon: '',
            text: 'Pair Ranges',
            iconOnly: false,
            state: window.location.pathname.includes("pair-range-tracking") ? NavItemState.Active : NavItemState.None,
            to: `${this.props.adminView ? '/admin' : '/user'}/pairs/pair-range-tracking`,
        }
    ];

    render()
    {
        let Id = this.props.match.params.id;

        return (
            <Router history={History}>
                <BasicLayout
                    fullWidth
                    content={
                        <Switch>
                            <Route
                                exact
                                path={this.props.match.path}
                                render={() =>
                                {
                                    return (
                                        <Redirect to={`${this.props.adminView ? '/admin' : '/user'}/pairs/pair-tracking`} />
                                    );
                                }}
                            />
                            <Route exact path={`/:admin/pairs/pair-tracking/:id?`}>
                                <PairList id={Id} />
                            </Route>
                            <Route path={`/:admin/pairs/pair-range-tracking/:id?`}>
                                <PairRangeList id={Id} />
                            </Route>
                        </Switch>
                    }
                    contentTitle={'Pair Tracking'}
                    startState={true}
                    navigation={
                        <NavBar type={NavBarTypes.Tabs} tabs={this.pairTabData} />
                    }
                />
            </Router>
        );
    }
}

const mapStateToProps = (state: any) => ({
    adminView: state.app.adminView
  });
  
  export default connect(mapStateToProps)(Pairs);
