import React, { Fragment } from "react";
import {
	FormContainer,
	GridContainer,
	FormField,
	LayoutColumn,
	Button,
	ButtonType,
	DropdownItemProps,
	Text,
	TextType,
	Toggle,
	AdvancedTable,
	IAdvancedTableColumn,
	ActionList,
	Drawer,
	DrawerType,
	DrawerLayout,
	DrawerSize,
	NavBar,
	NavBarTypes,
	ButtonProps,
	ButtonState,
	Error,
	ErrorContext,
	ErrorType,
	TemplateModalSize,
	NavItemProps,
	Label,
} from "@mit/hui";
import { AudioCodesPortModel, DeviceModel, PairedDevice } from "../api/models/DeviceModel";
import DeviceController from "../api/DeviceController";
import { ChangeLogModel } from "../api/models/ChangeLogModel";
/**
 * Upgrade to HUI 1.0.139 broke the below import. 
 * I copied the original TableRowProps interface
 * and declared it lower down for use.
 */
// import { TableRowProps } from "@mit/hui/build/components/Table/TableRow";
import AppController from "../api/AppController";
import AudioCodesDetail from "./AudioCodesDetail";
import { BrowserRouter } from "react-router-dom";
import Modal from "../common/Modal";
import ConfirmationModal from "../common/ConfirmationModal";
import { withComponent } from "../common/WithComponent";
import AccountAssignment from "./AccountAssignment";
import { AccountModel } from "../api/models/AccountModel";
import { store } from "../common/redux/store/store";
import { UserRoleType } from "../api/models/UserRoleModel";
import { bindActionCreators } from "redux";
import { connect, Provider } from "react-redux";
import * as notificationActionCreator from "../common/redux/actions/notification";
import { uuidv4 } from "../common/redux/actions/notification";
import DevicePairing from "./DevicePairing";
import AccountInfo from "./AccountInfo";
import AccounRepair from "./AccountRepair";
import AccountReorder from "./AccountReorder";
import { DeviceModelModel } from "../api/models/DeviceModelModel";
import {sortByRelevance} from "../searchUtilities";
import "./Devices.css";
import AuthProvider from "../services/AuthProvider";
export const globalStore = store;

interface TableRowProps
{
    state: string, //unused still.
    items: any[]
}

interface IDeviceDetailProps {
	deviceId: string;
	close: (macaddress?: string) => void;
	isPristine: (e: boolean) => void;
	actions?: any;
	adminView?: any;
	provisioningStatus: string | undefined;
	userPassword: string | undefined;
	adminPassword: string | undefined;
}

interface DeviceDetailState {
	device: DeviceModel;
	audioCodes: AudioCodesPortModel[];
	deviceAccounts: AccountModel[];
	deviceMac: string;
	deviceUUID: string;
	changeLogs: ChangeLogModel[];
	isAudioCodes: boolean;
	isEdit: boolean;
	deviceModelData: DropdownItemProps[];
	firmwareTypeData: DropdownItemProps[];
	isLoading: boolean;
	isLoadingChangeLog: boolean;
	isError: boolean;
	isLoadingOwner: boolean;
	ownerList: any[];
	selectedPort: AudioCodesPortModel;
	showAudioCodesDetail: boolean;
	showUnassignModal: boolean;
	showDeviceDeleteModal: boolean;
	portUnassignBusy: boolean;
	formError: boolean;
	showAccountAddModal: boolean;
	showAccountDeleteModal: boolean;
	showAccountInfoModal: boolean;
	showAccountRepairModal:boolean;
	currentAccount: AccountModel;
	isAdminView: boolean;
	userRole: UserRoleType;
	isBusy: boolean;
	deleteIsBusy: boolean;
	devicePairModal: boolean;
	showDevicePairingRemoveModal: boolean;
	pairingRemovalIsBusy: boolean;
	showAccountReorderModal: boolean;
	unassignIsBusy: boolean;
	deviceModels: DeviceModelModel[];
	accountsIsLoading: boolean;
	portDrawerActions: NavItemProps[];
}

const WAIT_INTERVAL = 200;
let timerID: any;

let filterMatchesItemValue = (item: any, value: any) => {
	if (!value) return true;

	if(!item) return false;
	//Check if item is an object, with key and value pair
	if(item.value !== undefined)
		return item.value.toString().toLowerCase().indexOf(value.toLowerCase()) !== -1;
	else
		return item.toString().toLowerCase().indexOf(value.toLowerCase()) !== -1;
}

class DeviceDetail extends React.Component<IDeviceDetailProps, DeviceDetailState> {
	deviceMac: string = this.props.deviceId;
	_isMounted: boolean;
	deviceController: DeviceController;
	appController: AppController;

	newDevice: DeviceModel;
	newAccount: AccountModel;
	authProvider: AuthProvider

	ringdownStatusHeaders: IAdvancedTableColumn[] = [
		{
			id: "port/key",
			displayId: "port/value",
			name: "Port#",
			sortable: true,
			filterable: true,
			filterOptions: {
				type: "textbox",
				onFilter: (item: any, value: any) => {
					console.log("Item: ", item);
					return filterMatchesItemValue(item.port,value);
				},
			},
		},
		{
			id: "blade/key",
			displayId: "blade/value",
			name: "Blade",
			sortable: true,
			filterable: true,
			filterOptions: {
				type: "textbox",
				onFilter: (item: any, value: any) => {
					return filterMatchesItemValue(item.blade,value);
				},
			},
		},
		{
			id: "bladePort",
			name: "Blade Port",
			filterable: true,
			filterOptions: {
				type: "textbox",
				onFilter: (item: any, value: any) => {
					return filterMatchesItemValue(item.bladePort,value);

				},
				createOptionsOffItems: true,
			},
		},
		{
			id: "phoneNumber",
			name: "Phone Number",
			filterable: true,
			filterOptions: {
				type: "textbox",
				onFilter: (item: any, value: any) => {
					return filterMatchesItemValue(item.phoneNumber.props.children,value);
				},
				createOptionsOffItems: true,
			},
		},
		{
			id: "description",
			name: "Description",
			filterable: true,
			filterOptions: {
				type: "textbox",
				onFilter: (item: any, value: any) => {
					return filterMatchesItemValue(item.description,value);
				},
				createOptionsOffItems: true,
			},
			options: {
				demandPopin: true,
				minWidth: 600,
				popinText: "Description",
			},
		},
		{
			id: "ringdownStatus/key",
			displayId: "ringdownStatus/value",
			name: "Ringdown Status",
			sortable: true,
		},
		{
			id: "edit",
			name: "",
		},
	];

	constructor(props: any) {
		super(props);

		//This class did not take any special action on timeout
		//It will continue to act in such a way
		const onSessionTimeout = () => {}
		this.authProvider = new AuthProvider(onSessionTimeout)
		this.deviceController = new DeviceController(this.authProvider);
		this.appController = new AppController(this.authProvider);

		this.newDevice = {
			uuid: "",
			model: "",
			mac: "",
			description: "",
			owners: [],
			account_type: "",
			admins: [],
			active: true,
			audio_codes: false,
			mwi: true,
			encrypt: true,
			missed_call_list: true,
		};

		this.newAccount = {
			account_type: "",
			account_service: "",
			did: null,
			owners: [],
			admins: [],
			assigned_phones: [],
			devices: [],
			first_name: "",
			last_name: "",
			display_name: "",
			description: "",
			location: "",
			active: true,
		};

		this.state = {
			deviceMac: this.deviceMac,
			deviceUUID: "",
			isAudioCodes: false,
			device: this.newDevice,
			audioCodes: [],
			deviceAccounts: [],
			isEdit: !!this.deviceMac,
			changeLogs: [],
			deviceModelData: [],
			firmwareTypeData: [],
			isLoading: false,
			isLoadingChangeLog: false,
			isError: false,
			isLoadingOwner: false,
			ownerList: [],
			selectedPort: { port: 0, settings: {}, cable_pairs: [] },
			showAudioCodesDetail: false,
			showUnassignModal: false,
			showDeviceDeleteModal: false,
			portUnassignBusy: false,
			formError: false,
			showAccountAddModal: false,
			showAccountDeleteModal: false,
			showAccountInfoModal:false,
			showAccountRepairModal:false,
			currentAccount: this.newAccount,
			isAdminView: globalStore.getState().app.adminView,
			userRole: globalStore.getState().app.userRole,
			isBusy: false,
			deleteIsBusy: false,
			devicePairModal: false,
			showDevicePairingRemoveModal: false,
			pairingRemovalIsBusy: false,
			showAccountReorderModal: false,
			unassignIsBusy: false,
			deviceModels: [],
			accountsIsLoading: false,
			portDrawerActions: [],
		};
	}

	componentDidMount() {
		this._isMounted = true;
		const fetchLookupData = async () => {
			const deviceModels = globalStore.getState().lookups.deviceModels;
			const firmwareTypes = globalStore.getState().lookups.firmwareTypes;
			this._isMounted &&
				this.setState({
					deviceModels: deviceModels,
					deviceModelData: this.generateDropdownItems(deviceModels),
					firmwareTypeData: this.generateDropdownItemsFromString(firmwareTypes),
				});
		};

		const fetchDevice = async () => {
			this.setState({ isLoading: true, isLoadingChangeLog: true });

			let deviceUUID = await this.deviceController.getDeviceLookup(this.deviceMac);

			if (deviceUUID) {
				this.setState({ deviceUUID: deviceUUID });

				this.deviceController.getDevice(deviceUUID, this.state.isAdminView).then((device) => {
					if (device)
						this.setState({
							device: device,
							isAudioCodes: device.audio_codes,
							audioCodes: device.audio_codes_ports || [],
							deviceAccounts: device.assigned_accounts || [],
						});
					else this.setState({ isError: true });
					this.setState({ isLoading: false });
				});

				this.deviceController.getChangeLog(deviceUUID).then((changeLogs) => {
					if (changeLogs)
						this.setState({
							changeLogs: changeLogs,
							isLoadingChangeLog: false,
						});
				});
			}
		};

		//Calls
		this._isMounted && this.state.isEdit && fetchDevice();
		this._isMounted && fetchLookupData();
	}

	updateChangeLog = async () => {
		let changeLogs = await this.deviceController.getChangeLog(this.state.deviceUUID);
		this.setState({ changeLogs: changeLogs || [] });
		this.props.isPristine(true);
	};

	componentDidUpdate() {
		if (this.props.provisioningStatus && this.state.device && this.state.device.uuid && this.props.provisioningStatus !== "" && this.props.provisioningStatus !== this.state.device.provisioning_status) {
			console.log("componentDidUpdate 1");
			let updatedDevice = this.state.device;
			updatedDevice.provisioning_status = this.props.provisioningStatus;
			this.setState({ device: updatedDevice });
		}

		if (this.state.device && this.state.device.uuid && this.props.userPassword !== "" && this.props.userPassword !== this.state.device.user_password) {
			console.log("componentDidUpdate 2");
			let updatedDevice = this.state.device;
			updatedDevice.user_password = this.props.userPassword;
			this.setState({ device: updatedDevice });
		}

		if (this.state.device && this.state.device.uuid && this.props.adminPassword !== "" && this.props.adminPassword !== this.state.device.admin_password) {
			console.log("componentDidUpdate 3");
			let updatedDevice = this.state.device;
			updatedDevice.admin_password = this.props.adminPassword;
			this.setState({ device: updatedDevice });
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	generateDropdownItems = (items: any[]): DropdownItemProps[] => {
		const dropdownData: DropdownItemProps[] = [];
		items.forEach((item: any) => {
			dropdownData.push({ text: item.key, icon: "" });
		});
		return dropdownData;
	};

	generateDropdownItemsFromString = (items: string[]): DropdownItemProps[] => {
		const dropdownData: DropdownItemProps[] = [];
		items.forEach((item: any) => {
			dropdownData.push({ text: item, icon: "" });
		});
		return dropdownData;
	};

	generateAccountListData = (): TableRowProps[] => {
		let accountTableData: TableRowProps[] = [];
		let i = 0;

		this.state.deviceAccounts.forEach((account) => {
			accountTableData.push({
				state: "",
				items: [
					<Text type={TextType.Body} content={"" + ++i} />,
					<Text type={TextType.Body} content={account.did || ""} />,
					<Text type={TextType.Body} content={account.owners.length > 0 ? account.owners[0].cn : ""} />,
					<Text type={TextType.Body} content={account.first_name + " " + account.last_name} />,
					<Toggle
						choice="Positive"
						ariaLabel=""
						onClick={(e: any) => console.log(e)}
						onStateChanged={(e: any) => {
							console.error(e);
						}}
					/>,
					<Button type={ButtonType.IconNaked} icon="times" />,
				],
			});
		});
		return accountTableData;
	};

	generateRingDownStatusTable = (): any[] => {
		console.log("Generating ring down", this.state.audioCodes);
		let ringdownStatusTableData: any[] = [];
		this.state.audioCodes.forEach((port) => {
			ringdownStatusTableData.push({
				port: { key: port.port, value: port.port },
				blade: { key: port.blade, value: port.blade },
				bladePort: port.blade_port,
				phoneNumber: <span className="no-wrap">{port.settings.account}</span>,
				description: port.settings.description,
				ringdownStatus: {
					key: port.settings.account ? (port.settings.ringdown ? (port.settings.ringdown_enabled ? "Enabled" : "Disabled") : "N/A") : "",
					value: port.settings.account ? (port.settings.ringdown ? (port.settings.ringdown_enabled ? "Enabled" : "Disabled") : "N/A") : "",
				},
				edit: <Button type={ButtonType.IconNaked} icon="edit" onClick={() => this.editAudioCodesPort(port)}></Button>,
			});
		});

		return ringdownStatusTableData;
	};

	searchUsers = (searchTerm: string, loadingType: any, listType: any) => {

		this.appController.searchUsers(searchTerm).then((people: any) => {
			//check if status not 200 (null is return)

			if (people === null || people.name === "TypeError") {
				this.setState({ ...this.state, [loadingType]: false });
				return;
			}

			//Check if request was aborted
			if (people.name !== "AbortError") {
				const newData = people.map((itm: any) => {
					return {
						data: itm,
						icon: "user",
						text: itm.cn,
						secondaryText: itm.displayname,
						onClick: () => null,
					};
				}).sort(sortByRelevance(searchTerm));

				this.props.actions.closeNotification();
				this.setState({
					...this.state,
					[listType]: newData,
					[loadingType]: false,
				});
			}
		});
	}

	underMinimumLength = (q: string, listType: any, loadingType: any) => {
		if (q.length < 1) {
			this.setState({ ...this.state, [listType]: [], [loadingType]: false } );
			return true;
		}

		if (q.length < 3){
			this.setState( {...this.state,[loadingType]: true});
			return true;
		}

		this.setState( {...this.state,[loadingType]: false});
		return false;
	}

	bind_trailing_args = (fn : any, ...bound_args : any) => {
		return function(...args : any) {
			return fn(...args, ...bound_args);
		};
	}

	onHandleSearch = (searchTerm: string, e: any, listComponentName: string, loadingComponentName: string) =>{
		clearTimeout(timerID);
		const state = this.state;
		const loadingType = loadingComponentName as keyof typeof state;
		const listType = listComponentName as keyof typeof state;

		if( !this.underMinimumLength(searchTerm,listType,loadingType)){

			if (e.keyCode === 8 || e.keyCode === 46) return;

			this.setState({...this.state,[loadingType]: true});

			timerID = setTimeout(() => {
				this.props.actions.closeNotification();

				this.searchUsers(searchTerm,loadingType,listType);
			}, WAIT_INTERVAL);
		}
	}


	onHandleOwnerChange = (userList: any) => {
		let updatedDevice: DeviceModel = this.state.device;

		//Add New Users
		userList.forEach((user: any) => {
			if (user.data) {
				if (!updatedDevice.owners.find((owner) => owner.cn === user.data.cn)) {
					updatedDevice.owners.push(user.data);
				}
			}
		});

		//Remove users
		updatedDevice.owners = updatedDevice.owners.filter((owner) => userList.find((user: any) => user.text === owner.cn));

		this.setState({ device: updatedDevice });
	};

	editAudioCodesPort = (port: AudioCodesPortModel) => {
		this.setState({ showAudioCodesDetail: true, selectedPort: port });
		this.setPortDrawerActions(port);
	};

	cancelAudioCodesPort = () => {
		this.setState({
			showAudioCodesDetail: false,
			selectedPort: { port: 0, settings: {}, cable_pairs: [] },
			isLoading: true,
		});
		if (this.state.deviceUUID) {
			this.deviceController.getDevice(this.state.deviceUUID, this.state.isAdminView).then((device) => {
				if (device)
					this.setState({
						device: device,
						isAudioCodes: device.audio_codes,
						audioCodes: device.audio_codes_ports || this.state.audioCodes,
						isLoading: false,
					});
				else this.setState({ isError: true, isLoading: false });
			});
		}
	};

	closeDevice = (macaddress?: string) => {
		this.setState({ device: this.newDevice });
		this.props.isPristine(true);
		this.props.close(macaddress);
	};

	deleteDevice = () => {
		if (this.state.device.uuid) {
			this.setState({ deleteIsBusy: true });
			this.deviceController
				.deleteDevice(this.state.device.uuid)
				.then((response) => {
					if (response) {
						this.props.actions.addNotification(uuidv4(), "Success", "Delete", "Device was successfully deleted");
						this.closeDevice();
					}
					this.setState({ showDeviceDeleteModal: false, deleteIsBusy: false });
				})
				.catch((error) => {
					console.log("err: ", error);
					this.setState({ isBusy: false })
				});
		}
	};

	refreshDevice = async () => {
		console.log("refresh device");
		this.setState({ isLoading: true });
		if (this.state.deviceUUID) {
			this.deviceController.getDevice(this.state.deviceUUID, this.state.isAdminView).then((device) => {
				if (device)
					this.setState({
						device: device,
						isAudioCodes: device.audio_codes,
						isLoading: false,
					});
				else this.setState({ isError: true, isLoading: false });
			});
		}
	};

	showAccountInformation = (account: AccountModel) => {
		this.setState({ showAccountInfoModal: true, currentAccount: account });
	}

	showAccountRepair = (account: AccountModel) => {
		this.setState({ showAccountRepairModal: true, currentAccount: account });
	}

	repairAccount= (accounts?: AccountModel[]) => {
		this.setState({ deviceAccounts: accounts || this.state.deviceAccounts, showAccountRepairModal: false });		
		this.props.isPristine(true);
	};

	removeAccountAssignmentConfirmation = (account: AccountModel) => {
		this.setState({ showAccountDeleteModal: true, currentAccount: account });
	};

	removeAccountAssignment = () => {
		if (this.state.deviceUUID && this.state.currentAccount && this.state.currentAccount.did && this.state.currentAccount.line_port) {
			this.setState({ unassignIsBusy: true });
			this.deviceController.removeAccountAssignment(this.state.deviceUUID, this.state.currentAccount.did, this.state.currentAccount.line_port.toString()).then((response) => {
				if (response) {
					let deviceAccounts = this.state.deviceAccounts;
					deviceAccounts = deviceAccounts.filter((account) => account.did !== this.state.currentAccount.did || account.line_port !== this.state.currentAccount.line_port);
					this.setState({
						deviceAccounts: deviceAccounts,
						currentAccount: this.newAccount,
					});
					this.props.actions.addNotification(uuidv4(), "Success", "Account Removal", "Account was successfully unassigned from device");
				}
				this.setState({
					showAccountDeleteModal: false,
					unassignIsBusy: false,
				});
				this.props.isPristine(true);
			});
		}
	};

	unassignPort = () => {
		if (this.state.deviceUUID && this.state.selectedPort) {
			this.setState({ portUnassignBusy: true });
			this.deviceController.deletePortAssignment(this.state.deviceUUID, this.state.selectedPort.port.toString()).then((response) => {
				if (response) {
					this.props.actions.addNotification(uuidv4(), "Success", "Port Unassigned", "Port Unassigned Successfully");
					this.cancelAudioCodesPort();
					this.refreshDevice();
				}
				this.setState({
					showUnassignModal: false,
					portUnassignBusy: false,
				});
			});
		}
	};

	createDevice = () => {
		if (!this.state.formError) {
			this.setState({ isBusy: true });

			this.deviceController
				.createDevice(this.state.device)
				.then((response) => {
					if (response) {
						/**
						 * The device is saved by the backend in lower case
						 * and all the . - : formatting is stripped.
						 * 
						 * There is a redirect in the parent using the mac and that
						 * fails if there is a discrepancy.
						 */
						this.newDevice.mac=response.mac;
						this.setState({
							device: response,
							isBusy: false,
						});

						this.props.actions.addNotification(uuidv4(), "Success", "Created", `Device ${this.state.device.mac} was successfully created`);
						let drawer = document.getElementsByClassName("drawer")[0];
						drawer.scroll(0, 0);
						this.closeDevice(response.mac);
					}

					this.setState({
						isBusy: false,
					});
				})
				.catch((error) => this.setState({ isBusy: false }));
		}
	};

	updateDevice = () => {
		if (!this.state.formError) {
			let updatedDevice = this.state.device;
			updatedDevice.audio_codes_ports = undefined;
			updatedDevice.assigned_accounts = undefined;

			this.setState({ isBusy: true });
			this.deviceController
				.updateDevice(updatedDevice)
				.then((response) => {
					if (response) {
						this.props.actions.addNotification(uuidv4(), "Success", "Update", "Device was successfully updated");
						this.setState({
							device: response,
						});
						this.updateChangeLog();
						let drawer = document.getElementsByClassName("drawer")[0];
						drawer.scroll(0, 0);
					}
					this.setState({
						isBusy: false,
					});
					this.props.isPristine(true);
				})
				.catch((error) => this.setState({ isLoading: false, isBusy: false }));
		}
	};

	addAccount = (account: AccountModel) => {
		if (account && account.uuid) {
			let deviceAccounts = this.state.deviceAccounts;
			deviceAccounts.push(account);
			this.setState({ deviceAccounts: deviceAccounts });
			this.props.actions.addNotification(uuidv4(), "Success", "Update", "Account was successfully assigned to device");
		}
		this.setState({
			showAccountAddModal: false,
		});
		this.props.isPristine(true);
	};

	updateMWI = (account: AccountModel) => {
		this.setState({ accountsIsLoading: true });
		if (this.state.device.uuid && account.did) {
			this.deviceController.updateDeviceAssignmentMWI(this.state.device, account, !account.mwi).then((response) => {
				if (response) {
					this.props.actions.addNotification(uuidv4(), "Success", "MWI Update", `MWI for ${account.did} was successfully updated`);
					this.setState({
						accountsIsLoading: false,
					});
					this.updateChangeLog();
				} else {
					this.props.actions.addNotification(uuidv4(), "Error", "MWI Update", `MWI for ${account.did} was not updated`, [], 1);
					this.setState({
						accountsIsLoading: false,
					});
				}
				this.props.isPristine(true);
			});
		}
	};

	setPortDrawerActions = (port: AudioCodesPortModel) : NavItemProps[] => {
		const prtData : NavItemProps[] = [];
		const unlinkAction: NavItemProps = {
				keys: "port-action1",
				icon: "unlink",
				text: "Unassign Account",
				iconOnly: false,
				onClick: () => {
					this.setState({ showUnassignModal: true });
				},
			};	
		if(port.settings.account){
			prtData.push(unlinkAction);
		}
		this.setState({portDrawerActions:prtData});

		return this.state.portDrawerActions;
	}

	closeDevicePairing = (device?: PairedDevice) => {
		let updatedDevice = this.state.device;
		if (device) {
			updatedDevice.hw_pairing = { pairable: true, paired_to: device };
			this.setState({ device: updatedDevice });
			this.props.actions.addNotification(uuidv4(), "Success", "Device Pairing", `Device ${device.mac} was successfully paired`);
		}
		this.setState({ devicePairModal: false });
	};

	removeDevicePairing = () => {
		this.setState({ pairingRemovalIsBusy: true });
		this.deviceController.removePairedDevice(this.state.deviceUUID).then((response) => {
			if (response) {
				let updatedDevice = this.state.device;
				updatedDevice.hw_pairing = null;
				this.setState({ device: updatedDevice });
				this.props.actions.addNotification(uuidv4(), "Success", "Device Pairing Removal", `Device pairing was successfully removed`);
			}
			this.setState({ showDevicePairingRemoveModal: false, pairingRemovalIsBusy: false });
		});
	};

	isPairable = () => {
		return (
			this.state.device &&
			(this.state.device.model === "Polycom RealPresence Trio 8300" ||
				this.state.device.model === "Polycom RealPresence Trio 8500" ||
				this.state.device.model === "Polycom RealPresence Trio 8800")
		);
	};

	cancelAccountReorder = (accounts?: AccountModel[]) => {
		this.setState({ deviceAccounts: accounts || this.state.deviceAccounts, showAccountReorderModal: false });
		if (accounts) {
			this.props.actions.addNotification(uuidv4(), "Success", "Account Reorder", `Device accounts was successfully reordered`);
		}
		this.props.isPristine(true);
	};

	isSecondaryPairedDevice = () => {
		return this.state.device.hw_pairing && this.state.device.hw_pairing.pairable && this.state.device.hw_pairing.pairing_role === "secondary";
	};

	isSecondaryPairedModel = () => {
		const deviceModel = this.state.deviceModels.find((model) => model.key === this.state.device.model);
		return deviceModel && deviceModel.hw_pairing && deviceModel.hw_pairing.pairable && deviceModel.hw_pairing.pairing_role === "secondary";
	};

	navigateToPrimaryDevice = () => {
		this.props.isPristine(true);
		this.closeDevice(this.state.device.hw_pairing?.paired_to?.mac);
	};

	render() {
		const ModalData = withComponent(Modal);
		
		if (this.state.isError) return <Error context={ErrorContext.Page} message={"Failed to retrieve Device Details"} type={ErrorType.Generic} />;

		if (this.state.isLoading) return <ActionList isLoading items={[]} />;

		let ownerList = this.state.device.owners.map((owner) => {
			return { icon: "user", text: owner.cn, secondaryText: owner.displayname };
		});

		const headers: IAdvancedTableColumn[] = [
			{
				id: "empty1",
				name: "",
			},
			{
				id: "number",
				name: "Number",
			},
			{
				id: "linePort",
				name: "Line Port",
			},
			{
				id: "accountOwner",
				name: "Account Owner",
			},
			{
				id: "callerID",
				name: "Caller ID",
			},
			{
				id: "mwi",
				name: "MWI",
			},
		];

		let tableData: any[] = [];
		this.state.deviceAccounts.map((account, index) => {
			let tableItem = {
				empty1: index + 1,
				number: account.did,
				linePort: account.line_port,
				accountOwner: account.owners && account.owners.length > 0 ? account.owners[0].cn : "",
				callerID: `${account.first_name} ${account.last_name}`,
				mwi: (
					<Toggle
						ariaLabel=""
						choice={account.mwi ? "Positive" : "Negative"}
						onClick={(e: any) => this.updateMWI(account)}
						onStateChanged={(e: any) => {
							account.mwi = e.choice === "Positive";
						}}
					/>
				),
				account: account,
			};

			tableData.push(tableItem);
			return null;
		});

		const actionButtons: ButtonProps[] = [
			{
				icon: "lock-open",
				type: ButtonType.IconNaked,
				shouldDisplay: (e: any) => (
					e.account.sip_auth_status==="normal"
				),
				
				text: "SIP auth credentials",
				onClick: (account: any) => this.showAccountInformation(account.account),
				state: ButtonState.Enabled,
				padded: false,
			},
			{
				icon: "tools",
				type: ButtonType.IconNaked,
				shouldDisplay: (e: any) => (
					e.account.sip_auth_status==="error"
				),
				text: "This assignment is broken. Click to repair",
				onClick: (account: any) => this.showAccountRepair(account.account),
				state: ButtonState.Enabled,
				padded: false,
			},
			{
				icon: "times",
				type: ButtonType.IconNaked,
				text: "Remove",
				onClick: (account: any) => this.removeAccountAssignmentConfirmation(account.account),
				state: ButtonState.Enabled,
				padded: false,
			},
		];

		return (
			<Fragment>
				{!this.state.isEdit || (this.state.isEdit && this.state.device.uuid) ? (
					<FormContainer
						isPristine={(e) => this.props.isPristine(e)}
						action={() => (this.state.isEdit ? this.updateDevice() : this.createDevice())}
						submitText="Save"
						actionIsBusy={this.state.isBusy}

						tertiaryAction={
							this.state.isEdit && this.state.isAdminView && !this.state.isAudioCodes && (this.state.userRole ===  "VOIP_ADMIN" || this.state.userRole === "SUPER_USER")
								? () => this.setState({ showDeviceDeleteModal: true })
								: null
						}
						tertiaryActionText={this.state.isEdit && this.state.isAdminView && !this.state.isAudioCodes && (this.state.userRole ===  "VOIP_ADMIN" || this.state.userRole ==="SUPER_USER") ? "Delete Device" : ""}
						secondaryAction={this.props.close}
						secondaryActionText="Cancel"
						isValid={(e: any) => this.setState({ formError: !e })}
						formValidationTrigger="onChange"
						id="DeviceForm">
						{this.state.isLoadingChangeLog && <ActionList isLoading items={[]} />}
						{this.state.isEdit && this.state.changeLogs.length > 0 ? (
							<Fragment>
								<div className="metadata">
									<GridContainer showGutters={true}>
										<LayoutColumn colSize={6}>
											<Text type={TextType.Body} content="Created By" padded={false} />
										</LayoutColumn>
										<LayoutColumn colSize={6} alignment={"right"}>
											<Text
												type={TextType.Body}
												content={
													this.state.changeLogs.filter((log) => log.action === "add")[0].editor +
													" " +
													this.state.changeLogs.filter((log) => log.action === "add")[0].action_date
												}
												padded={false}
											/>
										</LayoutColumn>
									</GridContainer>
								</div>
							</Fragment>
						) : (
							<div></div>
						)}
						{this.state.isEdit && this.state.changeLogs.length > 0 ? (
							<div className="metadata mb-4">
								<GridContainer showGutters={true}>
									<LayoutColumn colSize={6}>
										<Text type={TextType.Body} content="Modified By" padded={false} />
									</LayoutColumn>
									<LayoutColumn colSize={6} alignment={"right"}>
										<Text
											type={TextType.Body}
											content={this.state.changeLogs[this.state.changeLogs.length - 1].editor + " " + this.state.changeLogs[this.state.changeLogs.length - 1].action_date}
											padded={false}
										/>
									</LayoutColumn>
								</GridContainer>
							</div>
						) : (
							<div></div>
						)}

						<GridContainer showGutters={true}>
							<LayoutColumn colSize={6}>
								<FormField
									id="macaddress"
									value={this.state.device.mac}
									onChange={(e: any) => {
										let updatedDevice = this.state.device;
										updatedDevice.mac = e.currentTarget.value;
										this.setState({ device: updatedDevice });
									}}
									editor="textbox"
									label="MAC Address"
									pattern={{
										value: /^([0-9a-fA-F]{2}[.:-]?){5}([0-9a-fA-F]{2})$/,
										message: "MAC Address format",
									}}
									labelDisplay="ontop"
									readonly={this.state.isEdit}
									required={true}
								/>

								{this.state.isAdminView && (
									<FormField
										id="static_ipaddr"
										value={this.state.device.static_ipaddr || ''}
										onChange={(e: any) => {
											let updatedDevice = this.state.device;
											updatedDevice.static_ipaddr = e.currentTarget.value;
											this.setState({ device: updatedDevice });
										}}
										editor="textbox"
										label="Static IP"
										pattern={{
											value: /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
											message: "IP Address format",
										}}
										labelDisplay="ontop"
										readonly={!this.state.isAdminView}
										required={false}
									/>

								)}

								<FormField
									id="description"
									value={this.state.device.description}
									onChange={(e: any) => {
										let updatedDevice = this.state.device;
										updatedDevice.description = e.currentTarget.value;
										this.setState({ device: updatedDevice });
									}}
									editor="textbox"
									label="Description"
									labelDisplay="ontop"
								/>
							</LayoutColumn>
							<LayoutColumn colSize={6}>
								<FormField
									id="model"
									editor="dropdown"
									options={this.state.deviceModelData}
									label="Model"
									labelDisplay="ontop"
									value={this.state.device.model}
									properties={{ readOnly: this.state.isEdit }}
									onChange={(e: any) => {
										let updatedDevice = this.state.device;
										updatedDevice.model = e.text;
										this.setState({ device: updatedDevice });
									}}
								/>
								{this.state.isAudioCodes && !this.isSecondaryPairedDevice() && (
									<FormField
										id="accountType"
										value={this.state.device.account_type}
										onChange={(e: any) => {
											let updatedDevice = this.state.device;
											updatedDevice.account_type = e.currentTarget.value;
											this.setState({ device: updatedDevice });
										}}
										editor="textbox"
										label="Account Type"
										labelDisplay="ontop"
										readonly={true}
									/>
								)}
								<GridContainer showGutters={true}>
									{!this.state.isAudioCodes && !this.isSecondaryPairedDevice() && (
										<LayoutColumn colSize={4}>
											<FormField
												id="missedCallToggle"
												editor="toggle"
												choice={this.state.device.missed_call_list ? "Positive" : "Negative"}
												label="Missed Call List"
												labelDisplay="ontop"
												value={this.state.device.missed_call_list}
												properties={{
													onStateChanged: (e: any) => {
														let updatedDevice = this.state.device;
														updatedDevice.missed_call_list = e.choice === "Positive";
														this.setState({ device: updatedDevice });
													},
												}}
											/>
										</LayoutColumn>
									)}
									{!this.state.isAudioCodes && this.state.isAdminView && this.state.userRole !== "UNPRIV_USER" && (
										<LayoutColumn colSize={4}>
											<FormField
												id="deviceToggle"
												editor="toggle"
												choice={this.state.device.active ? "Positive" : "Negative"}
												label="Device Status"
												labelDisplay="ontop"
												value={this.state.device.active}
												properties={{
													onStateChanged: (e: any) => {
														let updatedDevice = this.state.device;
														updatedDevice.active = e.choice === "Positive";
														this.setState({ device: updatedDevice });
													},
												}}
											/>
										</LayoutColumn>
									)}
								</GridContainer>
							</LayoutColumn>
						</GridContainer>

						{!this.state.isAudioCodes && !this.isSecondaryPairedDevice() && (
							<GridContainer showGutters={true}>
								<LayoutColumn colSize={6}>
									{this.isSecondaryPairedModel() ? (
										<Text content="The selected model is a secondary device to which an owner cannot be assigned" />
									) : (
										<FormField
											id="ownersList"
											editor="autocomplete"
											properties={{
												type: "tags",
												onSearch: this.bind_trailing_args(this.onHandleSearch,"ownerList","isLoadingOwner"),
												isBusy: this.state.isLoadingOwner,
												value: ownerList,
												fullWidthTags: true,
												onChange: this.onHandleOwnerChange,
												limit: 1000,
											}}
											label="Device Owners"
											labelDisplay="ontop"
											searchOptions={{
												name: "acoundAdminsSearch",
												placeholderText: "KerbID or Name",
											}}
											items={this.state.ownerList}
										/>
									)}
								</LayoutColumn>
								{!this.state.isAudioCodes && this.state.isAdminView && this.state.userRole !== "UNPRIV_USER" && !this.isSecondaryPairedDevice() && (
									<LayoutColumn colSize={4}>
										<FormField
											id="encryptionToggle"
											editor="toggle"
											choice={this.state.device.encrypt ? "Positive" : "Negative"}
											label="Encryption"
											labelDisplay="ontop"
											value={this.state.device.encrypt}
											properties={{
												onStateChanged: (e: any) => {
													let updatedDevice = this.state.device;
													updatedDevice.encrypt = e.choice === "Positive";
													this.setState({ device: updatedDevice });
												},
											}}
										/>
									</LayoutColumn>
								)}
							</GridContainer>
						)}

						{!this.state.isAudioCodes && this.state.isEdit && this.state.isAdminView && this.state.userRole !== "UNPRIV_USER" && !this.isSecondaryPairedDevice() && (
							<GridContainer showGutters={true}>
								<LayoutColumn colSize={6}>
									<FormField
										id="firmware"
										editor="dropdown"
										options={this.state.firmwareTypeData}
										label="Firmware"
										labelDisplay="ontop"
										value={this.state.device.firmware_type}
										onChange={(e: any) => {
											let updatedDevice = this.state.device;
											updatedDevice.firmware_type = e.text;
											this.setState({ device: updatedDevice });
										}}
									/>
								{  this.state.userRole !== "HELPDESK" && ( 	
									<FormField id="adminPassword" value={this.state.device.admin_password} editor="textbox" label="Admin Password" labelDisplay="ontop" readonly={true} />
								)}
								</LayoutColumn>
								{  this.state.userRole !== "HELPDESK" && (
									<LayoutColumn colSize={6}>
										<FormField
											id="provisioningKey"
											value={this.state.device.provisioning_status}
											editor="textbox"
											label="Provisioning Key Status"
											labelDisplay="ontop"
											readonly={true}
										/>
										<FormField id="userPassword" value={this.state.device.user_password} editor="textbox" label="User Password" labelDisplay="ontop" readonly={true} />
									</LayoutColumn>
								)}
							</GridContainer>
						)}

						{this.isSecondaryPairedDevice() && (
							<GridContainer showGutters={true}>
								<LayoutColumn colSize={12}>
									<br />
									<Label text={this.state.device.hw_pairing?.paired_to ? "This device is currently paired to" : "This device is not currently paired"} />
									{this.state.device.hw_pairing?.paired_to && (
										<Button text={this.state.device.hw_pairing?.paired_to.mac} type={ButtonType.TextNaked} onClick={this.navigateToPrimaryDevice} />
									)}
								</LayoutColumn>
							</GridContainer>
						)}
					</FormContainer>
				) : null}

				{!this.state.isAudioCodes && this.state.isEdit && this.isPairable() && !this.isSecondaryPairedDevice() && (
					<div>
						<hr />
						<GridContainer showGutters={true}>
							<LayoutColumn colSize={6}>
								<Text type={TextType.Heading5} content="Paired Device" />
							</LayoutColumn>
							<LayoutColumn colSize={6}>
								{this.state.device.hw_pairing && this.state.device.hw_pairing.paired_to ? (
									<Button
										text="Remove Paired Device"
										type={ButtonType.TextNaked}
										icon="trash"
										onClick={() => {
											this.setState({ showDevicePairingRemoveModal: true });
										}}
									/>
								) : (
									<Button text="Pair New Device" type={ButtonType.TextNaked} icon="plus" onClick={() => this.setState({ devicePairModal: true })} />
								)}
							</LayoutColumn>
						</GridContainer>
						<GridContainer showGutters={true}>
							<LayoutColumn colSize={12}>
								<br />
								<p>
									This device can be paired with another device (e.g. to support video conference calling). Please see{" "}
									<a href="http://kb.mit.edu/confluence/display/istcontrib/MITVoip+Conference+Room+Tools+and+Configuration" target="_blank" rel="noopener noreferrer">
										MITvoip Device Pairing
									</a>{" "}
									for more information.
								</p>
							</LayoutColumn>
						</GridContainer>
						{this.state.device.hw_pairing && this.state.device.hw_pairing.paired_to ? (
							<GridContainer showGutters={true}>
								<LayoutColumn colSize={4}>
									<FormField id="pairedDeviceMac" value={this.state.device.hw_pairing?.paired_to?.mac} editor="textbox" label="MAC Address" labelDisplay="ontop" readonly={true} />
								</LayoutColumn>
								<LayoutColumn colSize={4}>
									<FormField id="pairedDeviceModel" value={this.state.device.hw_pairing?.paired_to?.model} editor="textbox" label="Model" labelDisplay="ontop" readonly={true} />
								</LayoutColumn>
								<LayoutColumn colSize={4}>
									<FormField
										id="pairedDeviceDescription"
										value={this.state.device.hw_pairing?.paired_to?.description}
										editor="textbox"
										label="Description"
										labelDisplay="ontop"
										readonly={true}
									/>
								</LayoutColumn>
							</GridContainer>
						) : (
							<GridContainer showGutters={true}>
								<LayoutColumn colSize={12}>
									<Error context={ErrorContext.Component} message={"No paired device found"} type={ErrorType.NoData} />
								</LayoutColumn>
							</GridContainer>
						)}
					</div>
				)}

				<br />
				<hr />

				{this.state.isAudioCodes && (
					<div>
						<GridContainer showGutters={true}>
							<LayoutColumn colSize={6}>
								<Text content="AudioCodes" type={TextType.Heading5} />
							</LayoutColumn>
						</GridContainer>
						<GridContainer showGutters={true}>
							<LayoutColumn colSize={12}>
								<AdvancedTable showFilterButton maxRows={300} columns={this.ringdownStatusHeaders} items={this.generateRingDownStatusTable()} />
							</LayoutColumn>
						</GridContainer>
					</div>
				)}

				{!this.state.isAudioCodes && this.state.isEdit && !this.isSecondaryPairedDevice() && (
					<div>
						<GridContainer showGutters={true}>
							<LayoutColumn colSize={6}>
								<Text content="Account Assignments" type={TextType.Heading5} />
							</LayoutColumn>
							<LayoutColumn colSize={6}>
								<GridContainer showGutters={true}>
									<LayoutColumn colSize={12}>
										<Button icon="plus" type={ButtonType.TextNaked} text="Add Account" onClick={() => this.setState({ showAccountAddModal: true })}></Button>
									</LayoutColumn>
									{this.state.device.assigned_accounts && this.state.deviceAccounts.length > 1 && (
										<LayoutColumn colSize={12}>
											<br />
											<Button icon="sync" type={ButtonType.TextNaked} text="Reorder Accounts" onClick={() => this.setState({ showAccountReorderModal: true })}></Button>
										</LayoutColumn>
									)}
								</GridContainer>
							</LayoutColumn>
						</GridContainer>
						<GridContainer showGutters={true}>
							<LayoutColumn colSize={12} >
								<br />
								{this.state.accountsIsLoading ? (
									<ActionList isLoading items={[]} />
								) : (
									<div className="device-detail-table">
									<AdvancedTable columns={headers} items={tableData} maxRows={1000} actionButtons={actionButtons} />
									</div>
								)}
							</LayoutColumn>
							{this.state.deviceAccounts.length < 1 && (
								<LayoutColumn colSize={12}>
									<Error context={ErrorContext.Component} message="No Accounts assigned to this device" type={ErrorType.NoData} />
								</LayoutColumn>
							)}
						</GridContainer>
					</div>
				)}

				{this.state.showAudioCodesDetail && (
					<Drawer
						header={<Text content={"Update Port " + this.state.selectedPort.port} type={TextType.Heading4} />}
						type={DrawerType.Right}
						layout={DrawerLayout.Hero}
						size={DrawerSize.Medium}
						show={this.state.showAudioCodesDetail}
						headerNavBar={
							<BrowserRouter>
								<NavBar type={NavBarTypes.IconText} tabs={this.state.portDrawerActions} />
							</BrowserRouter>
						}
						contents={
							<Provider store={globalStore}>
								<AudioCodesDetail portId={this.state.selectedPort.port.toString()} 
								device={this.state.device} close={this.cancelAudioCodesPort} 
								changeLogs={this.state.changeLogs} 
								portDrawerActions={this.setPortDrawerActions} />
							</Provider>
						}
						onClose={this.cancelAudioCodesPort}
						footer={[]}
					/>
				)}

				{this.state.showAccountAddModal && <ModalData
					show={this.state.showAccountAddModal}
					onClose={() => this.setState({ showAccountAddModal: false })}
					containerless={false}
					size={TemplateModalSize.Default}
					body={
						<Provider store={globalStore}>
							<AccountAssignment addAccount={this.addAccount} deviceId={this.state.deviceUUID} close={() => this.setState({ showAccountAddModal: false })} />
						</Provider>
					}
					footer={[]}
					header={<Text content="Add Account to Device" type={TextType.Heading4} icon="" />}
					name={"accountAddModal"}
				/>}
				
				{this.state.showAccountReorderModal && <ModalData
					show={this.state.showAccountReorderModal}
					onClose={() => this.setState({ showAccountReorderModal: false })}
					containerless={false}
					size={TemplateModalSize.Default}
					body={<AccountReorder deviceId={this.state.deviceUUID} accounts={this.state.deviceAccounts || []} close={this.cancelAccountReorder} />}
					footer={[]}
					header={<Text content="Reorder Accounts" type={TextType.Heading4} icon="" />}
					name={"accountReorderModal"}
				/>}
				
				{this.state.devicePairModal && <ModalData
					show={this.state.devicePairModal}
					onClose={() => this.setState({ devicePairModal: false })}
					containerless={false}
					size={TemplateModalSize.Default}
					body={<DevicePairing deviceId={this.state.deviceUUID} close={this.closeDevicePairing} />}
					footer={[]}
					header={<Text content="Pair a Device" type={TextType.Heading4} icon="" />}
					name={"devicePairModal"}
				/>}
				
				{this.state.showUnassignModal && <ConfirmationModal
					show={this.state.showUnassignModal}
					onClose={() => this.setState({ showUnassignModal: false })}
					name="unassignModal"
					isBusy={this.state.portUnassignBusy}
					title="Unassign Account from Port"
					body="Are you sure you want to unassign the account from the port?"
					callBack={this.unassignPort}
				/>}
				
				{this.state.showDeviceDeleteModal && <ConfirmationModal
					show={this.state.showDeviceDeleteModal}
					onClose={() => this.setState({ showDeviceDeleteModal: false })}
					name="deleteModal"
					title="Delete Device"
					isBusy={this.state.deleteIsBusy}
					body="Are you sure you want to delete this device?"
					callBack={this.deleteDevice}
				/>}
				
				{this.state.showAccountDeleteModal && <ConfirmationModal
					show={this.state.showAccountDeleteModal}
					onClose={() => this.setState({ showAccountDeleteModal: false })}
					name="accountRemovalModal"
					isBusy={this.state.unassignIsBusy}
					title="Unassign account"
					body={"Are you sure you want to remove this appearance of account " + this.state.currentAccount.did + " from this device?"}
					callBack={this.removeAccountAssignment}
				/>}

				{this.state.showAccountInfoModal && <ModalData
					show={this.state.showAccountInfoModal}
					onClose={() => this.setState({ showAccountInfoModal: false })}
					containerless={false}
					size={TemplateModalSize.Default}
					body={
						<Provider store={globalStore}>
							<AccountInfo url={this.state.currentAccount.sip_auth_url+""} close={() => this.setState({ showAccountInfoModal: false })}  />
						</Provider>
					}
					footer={[]}
					header={<Text content="SIP auth credentials" type={TextType.Heading4} icon="" />}
					name={"showAccountInfoModal"}
				/>}
				{this.state.showAccountRepairModal && <ModalData
					show={this.state.showAccountRepairModal}
					onClose={this.repairAccount}
					// onClose={() => {	
					// 					this.setState({ showAccountRepairModal: false });
					// 					this.props.isPristine(true);
					// 				}}
					containerless={false}
					size={TemplateModalSize.Default}
					body={
						<Provider store={globalStore}>
							<AccounRepair url={this.state.currentAccount.sip_auth_url+""} repair={this.repairAccount}  accounts={this.state.deviceAccounts || []} repairedAccountDid={this.state.currentAccount.did+""}/>
						</Provider>
					}
					footer={[]}
					header={<Text content="Account Repair" type={TextType.Heading4} icon="" />}
					name={"showAccountRepairModal"}
					
				/>}
				
				{this.state.showDevicePairingRemoveModal && <ConfirmationModal
					show={this.state.showDevicePairingRemoveModal}
					onClose={() => this.setState({ showDevicePairingRemoveModal: false })}
					name="pairingRemoval"
					title="Remove paired device"
					isBusy={this.state.pairingRemovalIsBusy}
					body={"Are you sure you want to remove the paired device from " + this.state.device.mac + "?"}
					callBack={this.removeDevicePairing}
				/>}
				
			</Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({
	adminView: state.app.adminView,
});

const mapDispatchToProps = (dispatch: any) => ({
	actions: bindActionCreators(notificationActionCreator, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeviceDetail);
