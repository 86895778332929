import { FormContainer, FormField, GridContainer, LayoutColumn, Error,
	ErrorContext, ErrorType, ActionList } from "@mit/hui";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { store } from "../common/redux/store/store";
import DeviceController from "../api/DeviceController";
import * as notificationActionCreator from "../common/redux/actions/notification";
import AuthProvider from "../services/AuthProvider";
export const globalStore = store;

interface IAccountInfoProps {
	url: string;
	close: () => void;
}

interface AccountInfoState {
	isLoading: boolean;
	formError: boolean;
	sipAuthItem: any;
	isError: boolean;
}

class AccountInfo extends React.Component<IAccountInfoProps, AccountInfoState> {
	deviceController: DeviceController;
	_isMounted: boolean;
	authProvider: AuthProvider

	constructor(props: any) {
		super(props);

		//This class did not take any special action on timeout
		//It will continue to act in such a way
		const onSessionTimeout = () => {}
		this.authProvider = new AuthProvider(onSessionTimeout)
		this.deviceController = new DeviceController(this.authProvider);
		this._isMounted = false;

		this.state = {
			isLoading: true,
			formError: false,
			sipAuthItem:"",
			isError:false,
		};
		this.getSipAuth(this.props.url);
	}

	// componentDidMount() {
	// 	this.getSipAuth(this.props.url);
	// 	this._isMounted = true;
	// }

	getSipAuth = async (url:string) => {
		// console.log("AccountInfo.getSipAuth url =  ",url)
		let sip = await this.deviceController.getSipAuth(url);
		this.setState({ isLoading: false });
		this.setState({ sipAuthItem: sip });
		// console.log("sip =",sip);
		if(sip==null){
			this.setState({isError:true});
		}
		return sip;
	}

	close = () => {
		this.props.close();
	}
	
	render() {
		if (this.state.isError) return <Error context={ErrorContext.Component} message={"Failed to retrieve Account Details"} type={ErrorType.Generic} />;
		if (this.state.isLoading) return <ActionList isLoading items={[]} />;
		return (
			<Fragment>
				<FormContainer
					action={this.close}
					submitText="Close"
					// actionIsBusy={this.state.isLoading}
					isValid={(e: any) => this.setState({ formError: !e })}
					formValidationTrigger="onChange"
					id="AccountInfoForm">
					<GridContainer showGutters={true}>
						<LayoutColumn colSize={12}>
							{this.state.sipAuthItem.length > 0 && (
								<div>
									{this.state.sipAuthItem.map((item:any, index:any) => {
										return (
											<FormField
												id={item[0]}
												value={item[1]}
												editor="textbox"
												label={item[0]+':'}
												labelDisplay="nextto"
												readonly={true}
												key={index}
											/>
										);
									})}
								</div>	
							)}
						</LayoutColumn>
					</GridContainer>
				</FormContainer>
			</Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({
	adminView: state.app.adminView,
	impersonate: state.app.impersonate,
});

const mapDispatchToProps = (dispatch: any) => ({
	actions: bindActionCreators(notificationActionCreator, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountInfo);