import appConfig from '../app-config';
import BaseController from './BaseController';
import { ProfileModel } from './models/ProfileModel';
import AuthProvider from "../services/AuthProvider";

class ProfileController extends BaseController {
  constructor(authProvider: AuthProvider) {
    super(authProvider,appConfig.api.paths['digital-id']);
  }

  getProfile = async (): Promise<ProfileModel> => {
    return await this.get('/profile').then((response) =>
      response ? response : null
    );
  };

  getProfileByKerb = async (kerb: string): Promise<ProfileModel> => {
    return await this.getJsonCheck('/profile/' + kerb).then((response) =>
      response ? response : null
    );
  };

  getPictureByKerb = async (kerb: string): Promise<any> => {
    return await this.getImage('/picture/' + kerb).then((response) =>
      response ? response : null
    );
  };

  getPicture = async (): Promise<any> => {
    return await this.getImage('/picture').then((response) =>
      response ? response : null
    );
  };
}

export default ProfileController;
