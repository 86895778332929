// eslint-disable-next-line
import React, { Fragment } from "react";
import PairController from "../../api/PairController";
import {
	Button,
	ButtonType,
	Drawer,
	DrawerLayout,
	DrawerSize,
	DrawerType,
	Text,
	Textbox,
	TextboxType,
	TextType,
	Spacer,
	ActionList,
	GridContainer,
	LayoutColumn,
	Error,
	ErrorContext,
	ErrorType,
	AdvancedTable,
	IAdvancedTableColumn,
	ButtonProps,
} from "@mit/hui";
import { PairRangeModel } from "../../api/models/PairRangeModel";
import PairRangeDetail from "./PairRangeDetail";
import ConfirmationModal from "../../common/ConfirmationModal";
import StringProvider from "../..//services/StringProvider";
import History from "../../common/History";
import { connect, Provider } from "react-redux";
import { bindActionCreators } from "redux";
import { store } from "../../common/redux/store/store";
import * as notificationActionCreator from "../../common/redux/actions/notification";
import { uuidv4 } from "../../common/redux/actions/notification";
import AuthProvider from "../../services/AuthProvider";
export const globalStore = store;

interface PairRangeStatus {
	showPairRange: boolean;
	pairRangeList: PairRangeModel[] | null;
	pairRange: PairRangeModel;
	showDeletePairRangeModal: boolean;
	isLoading: boolean;
	isBusy: boolean;
	searchCriteria: string;
}

interface IPairRangeListState {
	id: string;
	adminView?: any;
	impersonate?: any;
	actions?: any;
}

class PairRangeList extends React.Component<IPairRangeListState, PairRangeStatus> {
	pairController: PairController;
	stringProvider: StringProvider;
	newPairRange: PairRangeModel;
	_isMounted: boolean;
	authProvider: AuthProvider

	constructor(props: any) {
		super(props);

		//This class did not take any special action on timeout
		//It will continue to act in such a way
		const onSessionTimeout = () => {}
		this.authProvider = new AuthProvider(onSessionTimeout)
		this.pairController = new PairController(this.authProvider);
		this.stringProvider = new StringProvider();

		this.newPairRange = {
			switch_site: "",
			frame_partition: "",
			destination: "",
			range_start: 0,
			range_end: 0,
		};

		this.state = {
			showPairRange: false,
			pairRangeList: null,
			pairRange: this.newPairRange,
			showDeletePairRangeModal: false,
			isLoading: false,
			isBusy: false,
			searchCriteria: "",
		};
	}

	componentDidMount() {
		this._isMounted = true;

		if (!this.props.adminView || this.props.impersonate) this.searchPairRanges(null, "");

		if (this.props.id) {
			let pairRange = this.newPairRange;
			pairRange.uuid = this.props.id;
			this.setState({
				showPairRange: true,
				pairRange: pairRange,
			});
		}
	}

	componentDidUpdate(previousProps: any) {
		if (previousProps !== this.props) {
			if (!this.props.adminView || this.props.impersonate) this.searchPairRanges(null, "");
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	addPairRange = () => {
		this.setState({
			showPairRange: true,
			pairRange: this.newPairRange,
		});
	};

	editPairRange = (pairRange: PairRangeModel) => {
		this.setState({
			showPairRange: true,
			pairRange: pairRange,
		});

		History.push(`${this.props.adminView ? "/admin" : "/user"}/pairs/pair-range-tracking/${pairRange.uuid}`);
	};

	cancelPairRange = () => {
		this.setState({
			showPairRange: false,
			pairRange: this.newPairRange,
		});
		this.searchPairRanges(null, this.state.searchCriteria);
		History.push(`${this.props.adminView ? "/admin" : "/user"}/pairs/pair-range-tracking`);
	};

	searchPairRanges = async (e: any, q: string) => {
		this.setState({ isLoading: true, searchCriteria: q });

		const pairRangeList = await this.pairController.getPairRangeList(q);
		this.setState({
			isLoading: false,
			pairRangeList: pairRangeList,
		});
	};

	deletePairRangeConfirm = (pairRange: PairRangeModel) => {
		this.setState({
			pairRange: pairRange,
			showDeletePairRangeModal: true,
		});
	};

	deletePairRange = () => {
		this.setState({ isBusy: true });
		this.pairController.deletePairRange(this.state.pairRange).then((response) => {
			if (response) {
				this.props.actions.addNotification(uuidv4(), "Success", "Delete", `Pair range ${this.state.pairRange.range_start} - ${this.state.pairRange.range_end} was successfully deleted`);
				this.searchPairRanges(null, this.state.searchCriteria);
			}
			this.setState({
				pairRange: this.newPairRange,
				showDeletePairRangeModal: false,
				isBusy: false,
			});
		});
	};

	updatePairRange = (pairRange: PairRangeModel) => {
		this.setState({ pairRange: pairRange });
	};

	render() {
		const headers: IAdvancedTableColumn[] = [
			{
				id: "switchSite/key",
				displayId: "switchSite/value",
				name: "Switch Site",
				sortable: true,
				filterable: true,
				filterOptions: {
					type: "dropdown",
					onFilter: (item: any, value: any) => {
						if (!value) return true;

						if (item.switchSite && item.switchSite.toLowerCase().indexOf(value.toLowerCase()) !== -1) return true;

						return false;
					},
					createOptionsOffItems: true,
				},
			},
			{
				id: "framePartition/key",
				displayId: "framePartition/value",
				name: "Frame Partition",
				sortable: true,
				filterable: true,
				filterOptions: {
					type: "dropdown",
					onFilter: (item: any, value: any) => {
						if (!value) return true;

						if (item.framePartition && item.framePartition.toLowerCase().indexOf(value.toLowerCase()) !== -1) return true;

						return false;
					},
					createOptionsOffItems: true,
				},
			},
			{
				id: "destination/key",
				displayId: "destination/value",
				name: "Destination",
				sortable: true,
			},
			{
				id: "pairRangeFrom/key",
				displayId: "pairRangeFrom/value",
				name: "Pair Range From",
				sortable: true,
			},
			{
				id: "pairRangeTo/key",
				displayId: "pairRangeTo/value",
				name: "Pair Range To",
				sortable: true,
			},
		];

		let tableData: any[] = [];

		this.state.pairRangeList &&
			this.state.pairRangeList.map((pairRange) => {
				let tableItem = {
					switchSite: { key: pairRange.switch_site, value: pairRange.switch_site.toUpperCase() },
					framePartition: { key: pairRange.frame_partition, value: this.stringProvider.capitalizeFirstLetter(pairRange.frame_partition) },
					destination: { key: pairRange.destination, value: pairRange.destination },
					pairRangeFrom: { key: pairRange.range_start, value: pairRange.range_start },
					pairRangeTo: { key: pairRange.range_end, value: pairRange.range_end },
					pairRange: pairRange,
				};

				tableData.push(tableItem);

				return null;
			});

		const actionButtons: ButtonProps[] = [
			{
				icon: "edit",
				type: ButtonType.IconNaked,
				onClick: (e: any) => this.editPairRange(e.pairRange),
				text: "Edit",
				state: 0,
				padded: false,
			},
			{
				icon: "trash",
				type: ButtonType.IconNaked,
				onClick: (e: any) => this.deletePairRangeConfirm(e.pairRange),
				text: "Remove",
				state: 0,
				padded: false,
			},
		];

		return (
			<Fragment>
				{/* This actually needs to be moved out */}
				<div className="container">
					<div className="row">
						<div className="col-lg-6 col-md-8 mx-auto">
							<div
								style={{
									display: "flex",
									justifyContent: "space-around",
								}}>
								<Textbox
									name="searchbox"
									compact={false}
									endIcon="search"
									startIcon="grip-lines"
									placeholderText="Search Pair Ranges"
									type={TextboxType.IconEnd | TextboxType.IconStart}
									onEndIconClick={this.searchPairRanges}
									onKeyUp={(e: any) => {
										if (e.keyCode === 13) this.searchPairRanges("", e.currentTarget.value);
									}}
									width={100}
									clearable
									onClear={() => this.setState({ searchCriteria: "", pairRangeList: null })}
									helperText="Search by pair number, range start or range end"
									endIconRequired={true}
									properties={{ "aria-label": "Search Pair Ranges" }}
								/>
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
								<span
									style={{
										whiteSpace: "nowrap",
									}}>
									<Button icon="plus" type={ButtonType.Primary} text="Create Pair Range" onClick={this.addPairRange} />
								</span>
							</div>
						</div>
					</div>
				</div>
				<Spacer size="4" />
				<Fragment>
					{this.state.isLoading ? (
						<ActionList isLoading items={[]} />
					) : (
						<Fragment>
							{!this.state.pairRangeList && (
								<GridContainer showGutters={true}>
									<LayoutColumn colSize={12} alignment={"center"} padded>
										<Error context={ErrorContext.Component} message="Please search to display a list of pair ranges" type={ErrorType.FirstAction} />
									</LayoutColumn>
								</GridContainer>
							)}
							{this.state.pairRangeList && this.state.pairRangeList.length < 1 && (
								<GridContainer showGutters={true}>
									<LayoutColumn colSize={12} alignment={"center"} padded>
										<Error context={ErrorContext.Component} message="No pair ranges found. Please search to display a list of pair ranges" type={ErrorType.NoData} />
									</LayoutColumn>
								</GridContainer>
							)}
							{this.state.pairRangeList && this.state.pairRangeList.length > 0 && (
								<Fragment>
									<AdvancedTable showFilterButton columns={headers} items={tableData} maxRows={10000} id="pairRangeTable" actionButtons={actionButtons} />
								</Fragment>
							)}
						</Fragment>
					)}
				</Fragment>

				<Drawer
					header={
						<Text
							content={this.state.pairRange.uuid ? "Edit Pair Range " + this.state.pairRange.range_start + " - " + this.state.pairRange.range_end : "Create Pair Range"}
							type={TextType.Heading4}
							icon="grip-lines"
						/>
					}
					type={DrawerType.Right}
					layout={DrawerLayout.Hero}
					size={DrawerSize.Medium}
					show={this.state.showPairRange}
					contents={
						<Provider store={globalStore}>
							<PairRangeDetail pairRangeId={this.state.pairRange.uuid || ""} updatePairRange={this.updatePairRange} close={this.cancelPairRange} />
						</Provider>
					}
					onClose={this.cancelPairRange}
					footer={[]}
				/>

				<ConfirmationModal
					show={this.state.showDeletePairRangeModal}
					onClose={() => this.setState({ showDeletePairRangeModal: false })}
					name="deletePairRangeModal"
					title="Delete Pair Range"
					isBusy={this.state.isBusy}
					body={`Are you sure you want to delete this pair range?`}
					callBack={this.deletePairRange}></ConfirmationModal>
			</Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({
	adminView: state.app.adminView,
	impersonate: state.app.impersonate,
});

const mapDispatchToProps = (dispatch: any) => ({
	actions: bindActionCreators(notificationActionCreator, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PairRangeList);
