import React, { Fragment } from 'react';
import { ButtonType, ActionList, AdvancedTable, IAdvancedTableColumn, ButtonProps, Error, ErrorContext, ErrorType, LayoutColumn, GridContainer } from '@mit/hui';
import ReportController from '../api/ReportController';
import { ReportTypeModel } from '../api/models/ReportTypeModel';
import AuthProvider from "../services/AuthProvider";

interface ReportState
{
    reportTypes: ReportTypeModel[];
    isLoading: boolean;
}
class Reports extends React.Component<any, ReportState> {
    reportController: ReportController;
    _isMounted: boolean;
    authProvider: AuthProvider

    constructor(props: any)
    {
        super(props);

        //This class did not take any special action on timeout
        //It will continue to act in such a way
        const onSessionTimeout = () => {}
        this.authProvider = new AuthProvider(onSessionTimeout)
        this.reportController = new ReportController(this.authProvider);
        this._isMounted = false;
        this.state = {
            reportTypes: [],
            isLoading: true
        };
    }

    componentDidMount()
    {
        this._isMounted = true;
        const fetchReportTypes = async () =>
        {
            const reportTypes = await this.reportController.getReportTypes();
            this._isMounted &&
                this.setState({
                    reportTypes: reportTypes,
                    isLoading: false
                });
        };
        this._isMounted && fetchReportTypes();
    }

    componentWillUnmount()
    {
        this._isMounted = false;
    }

    downloadCSV = (reportType: ReportTypeModel) =>
    {
        this.reportController.downloadCSV(reportType);
    };

    downloadPipeFile = (reportType: ReportTypeModel) =>
    {
        this.reportController.downloadPipeFile(reportType);
    };

    render()
    {
        if (this.state.isLoading)
            return <ActionList isLoading items={[]} />

        const headers: IAdvancedTableColumn[] = [
            {
                id: 'report/key',
                displayId: 'report/value',
                name: 'Report',
                sortable: true
            }
        ];

        let tableData: any[] = [];

        this.state.reportTypes.map((reportType) =>
        {
            let tableItem = {
                report: {key:reportType.text, value: reportType.text},
                reportType: reportType
            };

            tableData.push(tableItem);

            return null;
        })

        const actionButtons: ButtonProps[] = [
            {
                icon: 'file-csv',
                type: ButtonType.IconNaked,
                onClick: (e: any) => this.downloadCSV(e.reportType),
                text: 'CSV File',
                state: 0,
                padded: false
            },
            {
                icon: 'file-powerpoint',
                type: ButtonType.IconNaked,
                onClick: (e: any) => this.downloadPipeFile(e.reportType),
                text: 'Pipe File',
                state: 0,
                padded: false
            }
        ];

        return (
            <Fragment>
                <AdvancedTable columns={headers} items={tableData} actionButtons={actionButtons} maxRows={1000} />
                {this.state.reportTypes.length < 1 && (
								<GridContainer showGutters={true}>
									<LayoutColumn colSize={12} alignment={"center"} padded>
										<Error context={ErrorContext.Component} message="You do not have access to any reports" type={ErrorType.NoData} />
									</LayoutColumn>
								</GridContainer>
							)}
                {
                /*
                    <Table>
                        <thead>
                            <tr>
                                <th>Report</th>
                                <th>Download</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.reportTypes.map((reportType) =>
                            {
                                return (
                                    <tr key={reportType.id}>
                                        <td>{reportType.text}</td>
                                        <td>
                                            <Button
                                                type={ButtonType.IconNaked}
                                                icon='file-csv'
                                                onClick={() => this.downloadCSV(reportType)}
                                            />
                                            <Button
                                                type={ButtonType.IconNaked}
                                                icon='file-powerpoint'
                                                onClick={() => this.downloadPipeFile(reportType)}
                                            />
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                */}
            </Fragment>
        );
    }
}

export default Reports;
