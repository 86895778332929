import Amplify, { Auth } from "aws-amplify";
import appConfig from "../app-config";

Amplify.configure(appConfig.amplify);
class AuthProvider {

	private readonly onSessionTimeout: () => void;

	//The passed in function will be called when the users' session is expired and requires log-in
	constructor(onSessionTimeout: () => void) {
		this.onSessionTimeout = onSessionTimeout
	}

	getToken = async (): Promise<string | null> => {

		const currentSession = await this.getCurrentSession()

		if(currentSession)
			return currentSession.getAccessToken().getJwtToken()

		console.log("No session found, calling time out")
		//No longer a valid session
		this.onSessionTimeout()
		return null
	};

	//Call federated sign in and return the user credentials if success
	async signIn(): Promise<any | null> {
		console.log("Signing in")
		const options = {
			customProvider: appConfig.signInProvider,
		}

		const currentSession = await this.getCurrentSession()

		if (currentSession != null)
			return currentSession

		//No current session, sign in
		const signInResult = await Auth.federatedSignIn(options)
			.then((_) => {
				console.log("login success")

				//Trigger refreshing after signing in every < hour
				const refresh: number = 3_500_000
				setInterval(() => this.refreshToken(),refresh)

				return this.getCurrentSession();
			})
			.catch((err1) => {
				console.log("login err", err1)
				return null
			});

		//Wait for amplify to handle log in
		await new Promise(resolve => setTimeout(resolve, 50000))

		return signInResult
	}

	getCurrentSession(): Promise<any | null>{
		//Auth.currentSession should automatically refresh the token if appropriate
		return Auth.currentSession()
			.then((res) => {

				console.log("Current session found: ", res)
				return res
			})
			.catch( () => {
				console.log("Current session not found")
				return null
			});
	}

	async refreshToken() {
		console.log("Attempting to refresh token")
		try {
			const cognitoUser = await Auth.currentAuthenticatedUser();
			const currentSession = cognitoUser.signInUserSession
			const refreshToken = currentSession.getRefreshToken()
			cognitoUser.refreshSession(refreshToken, (err: any, session: { idToken: any; refreshToken: any; accessToken: any; }) => {
				console.log("Refreshed session")
			});
		} catch (e) {
			console.log('Unable to refresh Token', e);
		}
	}

	logout = async (): Promise<any> => {
		await Auth.signOut();
	};

}

export default AuthProvider;
