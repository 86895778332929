import BaseController from './BaseController';
import { ReportTypeModel } from './models/ReportTypeModel';
import { ResponseListModel } from './models/ResponseModel';
import { saveAs } from 'file-saver';
import moment from 'moment';

class ReportController extends BaseController {
  getReportTypes = async (): Promise<ReportTypeModel[]> => {
    return await this.get('/report_types').then((response: ResponseListModel) =>
      response ? response.items : null
    );
  };

  downloadCSV = async (reportType: ReportTypeModel): Promise<any> => {
    return await this.getFile('/reports/' + reportType.id, 'text/csv').then(
      (blob) => {
        saveAs(
          blob,
          reportType.id + '_' + moment().format('YYYYMMDD') + '.csv'
        );
      }
    );
  };

  downloadPipeFile = async (reportType: ReportTypeModel): Promise<any> => {
    return await this.getFile('/reports/' + reportType.id, 'text/plain').then(
      (blob) => {
        saveAs(
          blob,
          reportType.id + '_' + moment().format('YYYYMMDD') + '.txt'
        );
      }
    );
  };
}

export default ReportController;
