import { AdvancedTable, ButtonProps, ButtonState, ButtonType, FormContainer, GridContainer, IAdvancedTableColumn, LayoutColumn } from "@mit/hui";
import React, { Fragment } from "react";
import DeviceController from "../api/DeviceController";
import { AccountModel } from "../api/models/AccountModel";
import StringProvider from "../services/StringProvider";
import AuthProvider from "../services/AuthProvider";

interface AccountReorderState {
	accounts: AccountModel[];
	deviceId: string;
	isBusy: boolean;
}

interface IAccountReorderProps {
	accounts: AccountModel[];
	deviceId: string;
	close: (accounts?: AccountModel[]) => void;
}

class AccountReorder extends React.Component<IAccountReorderProps, AccountReorderState> {
	stringProvider: StringProvider;
	deviceController: DeviceController;
	_isMounted: boolean;
	authProvider: AuthProvider


	constructor(props: any) {
		super(props);
		this.stringProvider = new StringProvider();

		//This class did not take any special action on timeout
		//It will continue to act in such a way
		const onSessionTimeout = () => {}
		this.authProvider = new AuthProvider(onSessionTimeout)
		this.deviceController = new DeviceController(this.authProvider);
		this._isMounted = false;

		this.state = {
			accounts: this.props.accounts,
			deviceId: this.props.deviceId,
			isBusy: false,
		};
	}

	moveUp = (e: any) => {
		const fromIndex = e.order - 1;
		const toIndex = e.order !== 1 ? e.order - 2 : this.state.accounts.length || 0 - 1;
		this.updateAccountPostition(fromIndex, toIndex);
	};

	moveDown = (e: any) => {
		const fromIndex = e.order - 1;
		const toIndex = e.order !== this.state.accounts.length ? e.order : 0;
		this.updateAccountPostition(fromIndex, toIndex);
	};

	updateAccountPostition = (fromIndex: number, toIndex: number) => {
		let updatedAccounts: AccountModel[] = this.state.accounts;
		let account: AccountModel = updatedAccounts[fromIndex] || null;
		if (account) {
			updatedAccounts.splice(fromIndex, 1);
			updatedAccounts.splice(toIndex, 0, account);
			this.setState({ accounts: updatedAccounts });
		}
	};

	updateAccounts = () => {
		if (this.state.accounts && this.state.deviceId) {
			this.setState({ isBusy: true });
			this.deviceController.updateDeviceAssignments(this.state.deviceId, this.pruneAssignedAccounts(this.state.accounts)).then((accounts) => {
				if (accounts) {
					this.setState({ isBusy: false });
					this.props.close(this.state.accounts);
				} else {
					this.setState({ isBusy: false });
				}
			});
		}
	};

	pruneAssignedAccounts = (accounts: AccountModel[]) => {
		return accounts.map((account) => ({ did: account.did, line_port: account.line_port }));
	};

	render() {
		const headers: IAdvancedTableColumn[] = [
			{
				id: "order",
				name: "Order",
			},
			{
				id: "did",
				name: "Account DID",
			},
			{
				id: "new",
				name: "",
			},
		];

		const actionButtons: ButtonProps[] = [
			{
				icon: "arrow-up",
				type: ButtonType.IconNaked,
				text: "Move up",
				onClick: this.moveUp,
				state: ButtonState.Enabled,
			},
			{
				icon: "arrow-down",
				type: ButtonType.IconNaked,
				text: "Move down",
				onClick: this.moveDown,
				state: ButtonState.Enabled,
			},
		];

		let tableData: any[] = [];
		this.state.accounts?.forEach((account, index) => {
			let tableItem = {
				order: index + 1,
				did: this.stringProvider.formatPhoneNumber(account.did),
				account: account,
			};
			tableData.push(tableItem);
		});

		return (
			<Fragment>
				<FormContainer
					action={this.updateAccounts}
					submitText="Update"
					actionIsBusy={this.state.isBusy}
					secondaryAction={() => this.props.close()}
					secondaryActionText="Cancel"
					formValidationTrigger="onChange"
					id="accountReorderFrom">
					<GridContainer showGutters={true}>
						<LayoutColumn colSize={12}>
							<AdvancedTable columns={headers} items={tableData} actionButtons={actionButtons} maxRows={1000} />
						</LayoutColumn>
					</GridContainer>
				</FormContainer>
			</Fragment>
		);
	}
}

export default AccountReorder;
