import React, { Fragment } from "react";
import {
	Profile,
	DropdownItemProps,
	DropDownState,
	TemplateModalSize,
	GridContainer,
	LayoutColumn,
	Textbox,
	TextType,
	Text,
	TextboxType,
	ButtonBar,
	ButtonState,
	ButtonType,
	ButtonBarType,
} from "@mit/hui";
import { connect } from "react-redux";
import AuthProvider from "../services/AuthProvider";
import Modal from "./Modal";
import { bindActionCreators } from "redux";
import * as appActionCreator from "../common/redux/actions/app";
import * as notificationActionCreator from "../common/redux/actions/notification";
import ProfileController from "../api/ProfileController";
import History from "../common/History";
import { uuidv4 } from "../common/redux/actions/notification";

interface IProfileViewState {
	showImpersonateModal: boolean;
	searchQuery: string;
	isBusy: boolean;
}

class ProfileView extends React.Component<any, IProfileViewState> {
	authProvider: AuthProvider;
	profileController: ProfileController;

	constructor(props: any) {
		super(props);

		//This class did not take any special action on timeout
		//It will continue to act in such a way
		const onSessionTimeout = () => {}
		this.authProvider = new AuthProvider(onSessionTimeout)
		this.profileController = new ProfileController(this.authProvider);

		this.state = {
			showImpersonateModal: false,
			searchQuery: "",
			isBusy: false,
		};
		
		if(props.roles && (props.roles.VOIP_SUPERUSER || props.roles.VOIP_ADMIN)){
			this.setAdminView();
		}
	}

	getRole(): any {
		if (this.props.roles && this.props.roles.VOIP_SUPERUSER) return "superadmin";

		if (this.props.roles && this.props.roles.VOIP_ADMIN) return "admin";

		if (this.props.roles && this.props.roles.HELPDESK) return "helpdesk";

		if (this.props.roles && !this.props.roles.HELPDESK && !this.props.roles.VOIP_ADMIN && !this.props.roles.VOIP_SUPERUSER) return "default";

		return "default";
	}

	getRoleText(): any {
		if (this.props.roles && this.props.roles.VOIP_SUPERUSER) return "Super User";

		if (this.props.roles && this.props.roles.VOIP_ADMIN) return "Admin";

		if (this.props.roles && this.props.roles.HELPDESK) return "Helpdesk";

		if (this.props.roles && !this.props.roles.HELPDESK && !this.props.roles.VOIP_ADMIN && !this.props.roles.VOIP_SUPERUSER) return "User";

		return "User";
	}
	impersonateUser = async () => {
		if (this.state.searchQuery) {
			this.setState({ isBusy: true });
			let user = await this.profileController.getProfileByKerb(this.state.searchQuery);
			let picture = await this.profileController.getPictureByKerb(this.state.searchQuery);

			if (user) {
				this.props.actions.changeImpersonateUser(user);
				this.props.actions.changeImpersonatePicture(picture);
				this.props.actions.changeImpersonate(this.state.searchQuery);
				this.setState({
					searchQuery: "",
					showImpersonateModal: false,
					isBusy: false,
				});
			} else {
				this.props.notificationActions.addNotification(uuidv4(), "Error", "Impersonate", "User was not found.  Enter a valid Kerberos username", [], 1);
				this.setState({ isBusy: false });
			}
		}
	};

	setAdminView = () => {
		let pathname = document.location.pathname;
		if(document.location.pathname.startsWith("/user/") ){
			pathname = document.location.pathname.replace("/user/", "/admin/");
		}

		History.push(pathname);

		this.profileController.getProfile().then((data) => {
			if (data) this.props.actions.changeUser(data);
		});
		this.profileController.getPicture().then((data) => {
			if (data) this.props.actions.changePicture(data);
		});
		this.props.actions.changeImpersonate("");
		this.props.actions.changeAdminView(true);
	}

	render() {
		const subMenuData: DropdownItemProps[] = [];

		if (this.props.roles.VOIP_ADMIN || this.props.roles.VOIP_SUPERUSER || this.props.roles.HELPDESK)
			if (this.props.adminView) {
				subMenuData.push({
					icon: "user",
					text: "Exit Admin View",
					state: DropDownState.None,
					onClick: () => {
						let pathname = document.location.pathname.replace("admin", "user");
						History.push(pathname);
						this.props.actions.changeAdminView(false);
					},
				});
			} else {
				subMenuData.push({
					icon: "user",
					text: "Admin View",
					state: DropDownState.None,
					onClick: () => {
						this.setAdminView()
					},
				});
			}

		//add impersonation when SUPER_USER VOIP_ADMIN HELPDESK
		if (this.props.roles && (this.props.roles.VOIP_SUPERUSER || this.props.roles.VOIP_ADMIN || this.props.roles.HELPDESK) && !this.props.impersonate && !this.props.adminView)
			subMenuData.push({
				icon: "people-arrows",
				text: "Impersonate",
				state: DropDownState.None,
				onClick: () => {
					this.setState({ showImpersonateModal: true });
				},
			});

		//add signout
		subMenuData.push({
			icon: "sign-out",
			text: "Logout",
			state: DropDownState.None,
			onClick: () => {
				this.authProvider.logout().then((data) => {});
			},
		});

		return (
			<Fragment>
				<Profile
					name={this.props.user.display_name ? " " + this.props.user.display_name : ""}
					role={this.getRole()}
					roleTooltipText={this.getRoleText()}
					subtitle={this.props.user.department && this.props.user.department.replace("Information Systems & Technology", "IS&T")}
					imageUrl={this.props.picture ? this.props.picture : "/images/MIT-red.svg"}
					submenu={subMenuData}
					impersonateActive={this.props.impersonate}
					impersonateSubtitle={this.props.impersonate && this.props.impersonateUser.department && this.props.impersonateUser.department.replace("Information Systems & Technology", "IS&T")}
					impersonateImageUrl={this.props.impersonatePicture ? this.props.impersonatePicture : "/images/MIT-red.svg"}
					onExitImpersonation={() => {
						this.props.actions.changeImpersonateUser({
							kerberos: "",
							first_name: "",
							last_name: "",
							display_name: "please wait...",
							email: "",
							department: "",
						});
						this.props.actions.changeImpersonatePicture("");
						this.props.actions.changeImpersonate("");
					}}
					impersonateName={this.props.impersonateUser.display_name ? " " + this.props.impersonateUser.display_name : ""}
				/>
				<Modal
					containerless={false}
					show={this.state.showImpersonateModal}
					onClose={() => this.setState({ showImpersonateModal: false })}
					body={
						<GridContainer>
							<LayoutColumn alignment={"center"} colSize={12}>
								<Textbox
									type={TextboxType.IconStart}
									onEndIconClick={this.impersonateUser}
									onKeyUp={(e: any) => {
										if (e.keyCode === 13) this.impersonateUser();
									}}
									name="impersonateTExt"
									endIconRequired={true}
									placeholderText="Please enter kerb, MIT ID or name"
									startIcon="users"
									onChange={(e: any) => {
										this.setState({ searchQuery: e.currentTarget.value });
									}}
									properties={{
										"aria-label": "Please enter kerb, MIT ID or name",
									}}
								/>
							</LayoutColumn>
						</GridContainer>
					}
					footer={
						<ButtonBar
							type={ButtonBarType.Padded}
							alignment={"right"}
							buttons={[
								{
									isBusy: this.state.isBusy,
									icon: "people-arrows",
									text: "Impersonate",
									onClick: this.impersonateUser,
									state: ButtonState.Enabled,
									type: ButtonType.Primary,
								},
							]}
						/>
					}
					name="impersonateModal"
					header={<Text type={TextType.Heading5} content="Impersonate" />}
					size={TemplateModalSize.Default}
				/>
			</Fragment>
		);
	}
}

const mapDispatchToProps = (dispatch: any) => ({
	actions: bindActionCreators(appActionCreator, dispatch),
	notificationActions: bindActionCreators(notificationActionCreator, dispatch),
});

const mapStateToProps = (state: any) => ({
	user: state.app.user,
	impersonateUser: state.app.impersonateUser,
	picture: state.app.picture,
	impersonatePicture: state.app.impersonatePicture,
	roles: state.app.roles,
	impersonate: state.app.impersonate,
	adminView: state.app.adminView,
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileView);
