//App
export const CHANGE_USER = "CHANGE_USER";
export const CHANGE_IMPERSONATE_USER = "CHANGE_IMPERSONATE_USER";
export const CHANGE_PICTURE = "CHANGE_PICTURE";
export const CHANGE_IMPERSONATE_PICTURE = "CHANGE_IMPERSONATE_PICTURE";
export const CHANGE_ROLES = "CHANGE_ROLES";
export const CHANGE_USER_ROLE = "CHANGE_USER_ROLE";
export const CHANGE_IMPERSONATE = "CHANGE_IMPERSONATE";
export const CHANGE_ADMINVIEW = "CHANGE_ADMINVIEW";

//Notification
export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const CLOSE_NOTIFICATION = "HIDE_NOTIFICATION";

//Lookups
export const CHANGE_ACCOUNT_TYPES = "CHANGE_ACCOUNT_TYPES";
export const CHANGE_VOICEMAIL_TYPES = "CHANGE_VOICEMAIL_TYPES";
export const CHANGE_DEVICE_MODELS = "CHANGE_DEVICE_MODELS";
export const CHANGE_FIRMWARE_TYPES = "CHANGE_FIRMWARE_TYPES";
export const CHANGE_SWITCH_SITES = "CHANGE_SWITCH_SITES";
export const CHANGE_FRAME_PARTITIONS = "CHANGE_FRAME_PARTITIONS";
export const CHANGE_PAIR_STATUSES = "CHANGE_PAIR_STATUSES";
