import { ActionList, FormContainer, FormField, GridContainer, LayoutColumn, ValidationUtility } from "@mit/hui";
import React, { Fragment } from "react";
import AccountController from "../api/AccountController";
import DeviceController from "../api/DeviceController";
import { AccountModel } from "../api/models/AccountModel";
import { UserRoleType } from "../api/models/UserRoleModel";
import { store } from "../common/redux/store/store";
import AuthProvider from "../services/AuthProvider";
export const globalStore = store;

interface AccountAssignmentState {
	account: AccountModel;
	isLoading: boolean;
	isBusy: boolean;
	formError: boolean;
	userRole: UserRoleType;
	isAdminView: boolean;
	isAccountChanged: boolean;
}

class AccountAssignment extends React.Component<any, AccountAssignmentState> {
	acccountController: AccountController;
	deviceController: DeviceController;
	newAccount: AccountModel;
	_isMounted: boolean;
	authProvider: AuthProvider

	constructor(props: any) {
		super(props);

		//This class did not take any special action on timeout
		//It will continue to act in such a way
		const onSessionTimeout = () => {}
		this.authProvider = new AuthProvider(onSessionTimeout)
		this.acccountController = new AccountController(this.authProvider);
		this.deviceController = new DeviceController(this.authProvider);
		this._isMounted = false;

		this.newAccount = {
			account_type: "",
			account_service: "",
			did: null,
			owners: [],
			admins: [],
			assigned_phones: [],
			devices: [],
			first_name: "",
			last_name: "",
			display_name: "",
			description: "",
			location: "",
			active: true,
		};

		this.state = {
			account: this.newAccount,
			isLoading: false,
			isBusy: false,
			formError: false,
			userRole: globalStore.getState().app.userRole,
			isAdminView: globalStore.getState().app.adminView,
			isAccountChanged: false,
		};
	}

	addAccount = (event: Event) => {
		/**
		 * We have to prevent the page from reloading when the enter key is pressed.
		 * For some reason this generates an event whilst a click on the button does not
		 * event.preventDefault() squashes the reload, but presents problems when 
		 * there is no event.
		 */
		if(event){
			// console.log("we have an event");
			try{
				event.preventDefault()
			}catch{
				console.log("AccountAssignment.addAccount had error with event.preventDefault event =", event);
			}
		}
		// console.log("AccountAssignment.addAccount event =", event);
		// console.log("AccountAssignment.addAccount this.state.account =", this.state.account);
		// console.log("AccountAssignment.addAccount this.state.account.uuid =", this.state.account.uuid);
		// console.log("AccountAssignment.addAccount this.props.deviceId =", this.props.deviceId);
		
		
		if (this.state.account && this.state.account.uuid && this.props.deviceId &&!this.state.isAccountChanged) {
			// console.log("if");
			let updatedAccount = this.state.account;
			this.setState({ isBusy: true });

			this.deviceController
				.assignToAccount(this.props.deviceId, {
					did: this.state.account.did,
					mwi: this.state.account.mwi,
				})
				.then((response) => {

					if (response) {
						updatedAccount.line_port = response.line_port;
						this.props.addAccount(updatedAccount);
					} else {
						this.props.addAccount(null);
					}
					this.setState({ isBusy: false });
				});
		}else{
			// console.log("else");
			this.accountLookup();
		}
	};

	changeAccount = (e: any) => {
		// console.log("AccountAssignment.changeAccount ");
		let updatedAccount = this.state.account;
		updatedAccount.did = e.currentTarget.value;
		this.setState({ account: updatedAccount });
		this.setState({isAccountChanged: true});
	}

	accountLookup = () => {
		// console.log("AccountAssignment.accountLookup ");
		if (this.state.account.did && !!this.state.account.did.match(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/)) {
			this.setState({ isLoading: true });
			this.acccountController.getAccountLookup(this.state.account.did).then((response) => {
				if (response) {
					this.acccountController.getAccount(response, this.state.isAdminView).then((response) => {
						if (response) {
							this.setState({
								account: response,
								isLoading: false,
							});
						} else {
							this.setState({ isLoading: false });
						}
					});
				} else {
					this.setState({ isLoading: false });
				}
			});
		}
		this.setState({isAccountChanged: false});
	};

	render() {
		if (this.state.isLoading) return <ActionList isLoading items={[]} />;
		return (
			<Fragment>
				<FormContainer
					action={onsubmit = (ev: Event) => {this.addAccount(ev)}}
					submitText="Add Account"
					actionIsBusy={this.state.isBusy}
					secondaryAction={() => this.props.close()}
					secondaryActionText={"Cancel"}
					isValid={(e: any) => this.setState({ formError: !e })}
					formValidationTrigger="onChange"
					id="accountAssignForm">
					<GridContainer showGutters={true}>
						<LayoutColumn colSize={12}>
							<FormField
								id="accountNumber"
								value={this.state.account.did}
								// onChange={(e: any) => {
								// 	let updatedAccount = this.state.account;
								// 	updatedAccount.did = e.currentTarget.value;
								// 	this.setState({ account: updatedAccount });
								// }}
								onChange={(e:any) => this.changeAccount(e)}
								editor="textbox"
								label="Phone Number"
								labelDisplay="ontop"
								properties={{ onchange: this.accountLookup /*, onsubmit: this.addAccount*/}}
								pattern={{
									value: ValidationUtility.phonePattern,
									message: "Phone format",
								}}
								required={true}
							/>
							{this.state.account && this.state.account.uuid && (
								<div>
									<FormField id="accountDescription" value={this.state.account.description} editor="textbox" label="Description" labelDisplay="ontop" readonly={true} />
									<FormField id="accountOwner" value={this.state.account.owners[0]?.cn || ""} editor="textbox" label="Account Owner" labelDisplay="ontop" readonly={true} />
									<FormField
										id="callerId"
										value={`${this.state.account.first_name} ${this.state.account.last_name}`}
										editor="textbox"
										label="Caller ID"
										labelDisplay="ontop"
										readonly={true}
									/>
								</div>
							)}
						</LayoutColumn>
					</GridContainer>
				</FormContainer>
			</Fragment>
		);
	}
}

export default AccountAssignment;
