import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { NotificationModel } from '../common/redux/reducers/notification';
import * as notificationActionCreator from '../common/redux/actions/notification';
import {
  ToastProps,
  ToastContainer,
  ToastContainerProps,
  ToastPosition,
} from '@mit/hui';
import { bindActionCreators } from 'redux';

class Notification extends React.Component<any> {
  render() {
    let toastData: ToastContainerProps = {
      position: ToastPosition.TopCenter,
      toasts: [],
    };
    const iconData: any = {
      type: 'regular',
      icon: 'bell',
      padded: true,
    };

    this.props.notifications.forEach((notification: NotificationModel) => {
      let toastItem: ToastProps = {
        icon: iconData,
        title: notification.title,
        subtext: notification.subtext,
        message: notification.message,
        show: true,
        footer: notification.footer,
        type: notification.type,
        onClose: () => {
          this.props.actions.closeNotification(notification.id);
        },
      };

      toastData.toasts.push(toastItem);
    });

    return (
      <Fragment>
        <ToastContainer {...toastData} />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: any) => ({
  notifications: state.notification.notifications,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(notificationActionCreator, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
