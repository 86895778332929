import { FormContainer, GridContainer, LayoutColumn, ActionList } from "@mit/hui";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { store } from "../common/redux/store/store";
import DeviceController from "../api/DeviceController";
import * as notificationActionCreator from "../common/redux/actions/notification";
import { AccountModel } from "../api/models/AccountModel";
import AuthProvider from "../services/AuthProvider";
export const globalStore = store;

interface IAccountRepairProps {
	url: string;
	repair: (accounts?: AccountModel[]) => void;
	accounts: AccountModel[];
	repairedAccountDid: string;
}

interface AccountRepairState {
	isLoading: boolean;
	formError: boolean;
	sipAuthItem: any;
	isError: boolean;
	accounts: AccountModel[];
	repairedAccountDid: string;
}

class AccountRepair extends React.Component<IAccountRepairProps, AccountRepairState> {
	deviceController: DeviceController;
	_isMounted: boolean;
	authProvider: AuthProvider

	constructor(props: any) {
		super(props);


		//This class did not take any special action on timeout
		//It will continue to act in such a way
		const onSessionTimeout = () => {}
		this.authProvider = new AuthProvider(onSessionTimeout)
		this.deviceController = new DeviceController(this.authProvider);
		this._isMounted = false;

		this.state = {
			isLoading: true,
			formError: false,
			sipAuthItem:"",
			isError:false,
			accounts: this.props.accounts,
			repairedAccountDid: this.props.repairedAccountDid,
		};
		this.getSipAuth(this.props.url);
	}

	getSipAuth = async (url:string) => {
		let sip = await this.deviceController.repairSip(url);
		this.setState({ isLoading: false });
		this.setState({ sipAuthItem: sip });
		if(sip==null){
			this.setState({isError:true});
		}else{
			let updatedAccounts: AccountModel[] = this.state.accounts;
			// console.log("updatedAccounts =",updatedAccounts);
			for (let i=0;i<updatedAccounts.length;i++){
				if(updatedAccounts[i].did===this.props.repairedAccountDid){
					updatedAccounts[i].sip_auth_message=this.state.sipAuthItem.sip_auth_message;
					updatedAccounts[i].sip_auth_status=this.state.sipAuthItem.sip_auth_status;
					updatedAccounts[i].sip_auth_url=this.state.sipAuthItem.sip_auth_url;
				}
			}
			// console.log("updatedAccounts =",updatedAccounts);
			this.setState({ accounts: updatedAccounts });
		}
		return sip;
	}

	close = () => {
		// console.log("close repair");
		this.props.repair(this.state.accounts);
	}
	
	render() {
		if (this.state.isLoading) return <ActionList isLoading items={[]} />;
		return (
			<Fragment>
				<FormContainer
					action={() => this.props.repair(this.state.accounts)}
					submitText="Close"
					isValid={(e: any) => this.setState({ formError: !e })}
					formValidationTrigger="onChange"
					id="AccountRepairForm">
					<GridContainer showGutters={true}>
						<LayoutColumn colSize={12}>
						{this.state.isError && (
							<div>
								<p>There was an error in repairing the assignment of this device.</p>
							</div>	
						)}
						{!this.state.isError && (
							<div>
								<p>Device assignment repaired successfully</p>
							</div>	
						)}
						</LayoutColumn>
					</GridContainer>
				</FormContainer>
			</Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({
	adminView: state.app.adminView,
	impersonate: state.app.impersonate,
});

const mapDispatchToProps = (dispatch: any) => ({
	actions: bindActionCreators(notificationActionCreator, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountRepair);