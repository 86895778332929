import React, { Fragment } from "react";
import {
	Button,
	ButtonType,
	Text,
	DrawerType,
	Drawer,
	NavItemProps,
	DrawerLayout,
	DrawerSize,
	NavBarTypes,
	NavBar,
	Textbox,
	TextboxType,
	GridContainer,
	LayoutColumn,
	Error,
	TemplateModalSize,
	ErrorContext,
	ErrorType,
	Spacer,
	Label,
	ActionList,
	AdvancedTable,
	IAdvancedTableColumn,
	ButtonProps,
	Icon,
	Tooltip, ButtonState,
} from "@mit/hui";
import DeviceController from "../api/DeviceController";
import { DeviceModel } from "../api/models/DeviceModel";
import DeviceDetail from "./DeviceDetail";
import Modal from "../common/Modal";
import ConfirmationModal from "../common/ConfirmationModal";
import { withComponent } from "../common/WithComponent";
import { connect, Provider } from "react-redux";
import History from "../common/History";
import { store } from "../common/redux/store/store";
import { UserRoleType } from "../api/models/UserRoleModel";
import { bindActionCreators } from "redux";
import * as notificationActionCreator from "../common/redux/actions/notification";
import { uuidv4 } from "../common/redux/actions/notification";
import StringProvider from "../services/StringProvider";
import {AccountJobModel, AccountModel, UserModel} from "../api/models/AccountModel";
import AuthProvider from "../services/AuthProvider";
export const globalStore = store;

interface IDeviceListProps {
	Id: string;
	adminView?: any;
	impersonate?: any;
	actions?: any;
}

interface DeviceState {
	showDevice: boolean;
	device: DeviceModel;
	showLeaveModal: boolean;
	deviceList: DeviceModel[] | null;
	isLoading: boolean;
	showRebootModal: boolean;
	showKeyModal: boolean;
	showClearKeyModal: boolean;
	showPasswordModal: boolean;
	showMacModal: boolean;
	showPortAssignmentsModal: boolean;
	userRole: UserRoleType;
	isPristine: boolean;
	searchCriteria: string;
	isBusy: boolean;
	provisioningStatus: string | undefined;
	userPass: string | undefined;
	adminPass: string | undefined;
	offset: number;
	limit: number;
	devicesDataCache: { [key: number]: DeviceModel[] };
	totalCount: number;
}


class DeviceList extends React.Component<IDeviceListProps, DeviceState> {
	deviceController: DeviceController;
	stringProvider: StringProvider;
	newDevice: DeviceModel;
	newMacAddress: string;
	authProvider: AuthProvider

	constructor(props: any) {
		super(props);

		//This class did not take any special action on timeout
		//It will continue to act in such a way
		const onSessionTimeout = () => {}
		this.authProvider = new AuthProvider(onSessionTimeout)
		this.deviceController = new DeviceController(this.authProvider);
		this.stringProvider = new StringProvider();

		this.newDevice = {
			model: "",
			mac: "",
			description: "",
			owners: [],
			account_type: "",
			admins: [],
			active: false,
			audio_codes: false,
		};

		this.state = {
			showDevice: false,
			showLeaveModal: false,
			device: this.newDevice,
			deviceList: null,
			isLoading: false,
			showRebootModal: false,
			showKeyModal: false,
			showClearKeyModal: false,
			showPasswordModal: false,
			showMacModal: false,
			showPortAssignmentsModal: false,
			userRole: globalStore.getState().app.userRole,
			isPristine: true,
			searchCriteria: "",
			isBusy: false,
			provisioningStatus: "",
			userPass: "",
			adminPass: "",
			offset: 1,
			limit: 20,
			devicesDataCache: {},
			totalCount: 0
		};

		this.newMacAddress = "";
	}

	async componentDidMount() { 
		if (this.props.Id && !this.props.Id.includes("search") && !this.props.adminView) this.searchDevices(null, "");
		if (!this.props.Id && !this.props.adminView) this.searchDevices(null, "");
		if (!this.props.Id && this.props.adminView) this.setState({ searchCriteria: "", deviceList: [] });

		if (this.props.Id) {
			if (this.props.Id.includes("search")) {
				const number = this.props.Id.replace("search-", "");
				this.searchDevices(null, number);
			} else {
				let deviceUUID = await this.deviceController.getDeviceLookup(this.props.Id);

				let device: DeviceModel = this.newDevice;
				device.uuid = deviceUUID;
				device.mac = this.props.Id;
				this.setState({
					showDevice: true,
					device: device,
					provisioningStatus: device.provisioning_status,
					userPass: device.user_password || "",
					adminPass: device.admin_password || "",
				});
			}
		}
	}

	componentDidUpdate(previousProps: any) {
		if (previousProps !== this.props) {
			if (!this.props.adminView && !this.props.impersonate) this.searchDevices(null, "");
			if (!this.props.adminView && this.props.impersonate) this.searchDevices(null, this.state.searchCriteria || "");
			if (!this.props.Id && !this.props.adminView) this.searchDevices(null, "");
			if (this.props.adminView && !this.props.Id) this.setState({ searchCriteria: "", deviceList: [] });

			if (this.props.Id && !this.props.Id.includes("search") && !this.props.adminView) this.searchDevices(null, "");
			if (this.props.Id) {
				if (this.props.Id.includes("search")) {
					const number = this.props.Id.replace("search-", "");
					this.searchDevices(null, number);
				}
			}
		}
		//console.log("Enter componentDidUpdate this.state.isPristine = " + this.state.isPristine);
	}

	isSecondaryPairedDevice = () => {
		return this.state.device.hw_pairing && this.state.device.hw_pairing.pairable && this.state.device.hw_pairing.pairing_role === "secondary";
	};

	nextPage = () => {
		this.setState(prevState => ({
			offset: prevState.offset + prevState.limit
		}), () => {
			if (this.state.devicesDataCache[this.state.offset]) {
				this.setState({
					deviceList: this.state.devicesDataCache[this.state.offset]
				});
			} else {
				this.searchDevices(null, this.state.searchCriteria);  // call with updated offset
			}
		});
	}

	previousPage = () => {
		this.setState(prevState => ({
			offset: Math.max(prevState.offset - prevState.limit, 1)
		}), () => {
			if (this.state.devicesDataCache[this.state.offset]) {
				this.setState({
					deviceList: this.state.devicesDataCache[this.state.offset]
				});
			} else {
				this.searchDevices(null, this.state.searchCriteria); // call with updated offset
			}
		});
	}

	getDisplayedRecordRange = () => {
		const { offset, deviceList, totalCount } = this.state;
		if (deviceList && deviceList.length === 0) {
			return null;
		}
		const startIndex = offset;
		// const endIndex = Math.min(offset + limit, offset + (accountList ? accountList.length : 0));
		const endIndex = offset + (deviceList ? deviceList.length : 0) - 1;
		return `${startIndex}-${endIndex} of ${totalCount}`;
	}


	getDrawerActions = (): NavItemProps[] => {
		let drawerActions: any = [];

		const rebootAction: NavItemProps = {
			icon: "power-off",
			text: "Reboot Device",
			iconOnly: false,
			onClick: () => this.setState({ showRebootModal: true }),
		};

		const keyResetAction: NavItemProps = {
			icon: "redo-alt",
			text: "Regenerate Provisioning Key",
			iconOnly: false,
			onClick: () => this.setState({ showKeyModal: true }),
		};

		const keyClearAction: NavItemProps = {
			icon: "backspace",
			text: "Clear Provisioning Key",
			iconOnly: false,
			onClick: () => this.setState({ showClearKeyModal: true }),
		};

		const passwordResetAction: NavItemProps = {
			icon: "key",
			text: "Regenerate Passwords",
			iconOnly: false,
			onClick: () => this.setState({ showPasswordModal: true }),
		};

		const replaceMacAction: NavItemProps = {
			icon: "sync",
			text: "Update MAC Address",
			iconOnly: false,
			onClick: () => this.setState({ showMacModal: true }),
		};

		const pushPortAssignmentsAction: NavItemProps = {
			icon: "arrow-alt-from-left",
			text: "Push Port Assignments to Device",
			iconOnly: false,
			onClick: () => this.setState({ showPortAssignmentsModal: true }),
		};

		const downloadAction: NavItemProps = {
			icon: "download",
			text: "Download Bootstrap Audiocodes Config",
			iconOnly: false,
			onClick: () => {
				this.deviceController.fetchAudiocodesBoostrapConfig(this.state.device);
			},
		};

		if (this.state.device.uuid) {
			if (!this.state.device.audio_codes) {
				drawerActions.push(rebootAction);
			}
			if (!this.state.device.audio_codes && this.props.adminView && this.state.userRole === "SUPER_USER") {
				drawerActions.push(keyResetAction);
			}
			if (!this.state.device.audio_codes && this.props.adminView && this.state.userRole === "SUPER_USER") {
				drawerActions.push(keyClearAction);
			}
			if (!this.state.device.audio_codes && this.props.adminView && this.state.userRole === "SUPER_USER") {
				drawerActions.push(passwordResetAction);
			}
			if (this.state.device.audio_codes) {
				drawerActions.push(replaceMacAction);
			}
			if (this.state.device.audio_codes && this.props.adminView && this.state.userRole === "SUPER_USER") {
				drawerActions.push(pushPortAssignmentsAction);
			}
			if (this.state.device.audio_codes) {
				drawerActions.push(downloadAction);
			}
		}

		// if (!this.state.device.uuid || this.isSecondaryPairedDevice()) {
		// 	return [];
		// } else if (this.state.device.audio_codes && this.state.userRole === "SUPER_USER") {
		// 	return [replaceMacAction, pushPortAssignmentsAction, downloadAction];
		// } else if (this.state.device.audio_codes && this.state.userRole !== "SUPER_USER") {
		// 	return [replaceMacAction, downloadAction];
		// } else if (this.state.userRole === "UNPRIV_USER" || !this.props.adminView) {
		// 	return [rebootAction];
		// } else if (this.props.adminView && this.state.userRole === "SUPER_USER") {
		// 	return [rebootAction, keyResetAction, keyClearAction, passwordResetAction];
		// } else {
		// 	return [rebootAction, passwordResetAction];
		// }

		return drawerActions;
	};

	createDevice = () => {
		this.setState({
			device: this.newDevice,
			showDevice: true,
		});
	};

	editDevice = (device: DeviceModel) => {
		this.setState({
			device: device,
			showDevice: true,
			isPristine: true,
		});
		this.setState({ isPristine: true });
		console.log("editDevice isPristine should be true ? " + this.state.isPristine )

		//this below is a hack to sort the isPristine state. Will keep on looking for a proper solution
		//isPristine is set at various places in the code
		//to true, but somehow (at time) it goes to false. I think it because of timing. 
		//An event sets isPristine to false when the user starts editing a control.
		//I think this wrongly happens at times when the data is places in the controls, it wrongly sets isPristine to false
    setTimeout(() => {
			this.setState({ isPristine: true });
			console.log("Timer1 edit device Call isPristine = true");
		}, 100);		
    setTimeout(() => {
			this.setState({ isPristine: true });
			console.log("Timer2 edit device Call isPristine = true");
		}, 2000);		
    setTimeout(() => {
			this.setState({ isPristine: true });
			console.log("Timer3 edit device Call isPristine = true");
		}, 5000);		

		History.push(`${this.props.adminView ? "/admin" : "/user"}/devices/${device.mac}`);
	};

	confirmLeave = (searchString?: string) => {
		this.setState({
			showDevice: false,
			showLeaveModal: false,
			device: this.newDevice,
			searchCriteria: searchString || this.state.searchCriteria,
		});

		this.searchDevices(null, this.state.searchCriteria);
		History.push(`${this.props.adminView ? "/admin" : "/user"}/devices`);
	};

	cancelDevice = (mac?: string) => {
		//console.log("cancelDevice = this.state.isPristine = " + this.state.isPristine);		
		!this.state.isPristine ? this.setState({ showLeaveModal: true }) : this.confirmLeave(mac);

		this.setState({ isPristine: true });
		console.log("cancelDevice = reset isPristine to true ? " + this.state.isPristine);		

	};

	hasChanged = (e: boolean) => {
		console.log("hasChanged in DeviceList = " + e);		
		e !== this.state.isPristine && this.setState({ isPristine: e });
	};


	searchDevices = (e: any, q: string) => {
		// Check if the search criteria have changed. If yes, reset the offset to 1
		const isNewSearch = q !== this.state.searchCriteria;
		const newOffset = isNewSearch ? 1 : this.state.offset;

		// Reset the pagination state when initiating a new search
		this.setState({
			isLoading: true,
			searchCriteria: q,
			offset: newOffset   // Clear previous search results

		}, () => {
			// After state update, perform the search
			this.deviceController.getDeviceList(this.state.searchCriteria, this.state.offset, this.state.limit)
				.then((response) => {
					if (response && response.items) {
						// Cache the fetched data in devicesDataCache for the current offset
						let newDeviceCache = { ...this.state.devicesDataCache };

						if (isNewSearch) {
							newDeviceCache = {}; // Clear the cache if it's a new search
						}

						newDeviceCache[this.state.offset] = response.items;

						this.setState({
							deviceList: response.items,
							devicesDataCache: newDeviceCache,
							isLoading: false,
							totalCount: response.total || 0,
						});
					} else {
						this.setState({
							isLoading: false,
						});
					}
				})
				.catch((error) => {
					console.error('Error fetching devices:', error);
					this.setState({ isLoading: false });
				});
		});
	};




	completeDeviceReboot = async (response: any, pollCount: number) => {

		console.log('PollCount: ' + pollCount);
		console.log(response);

		let drawer = document.getElementsByClassName("drawer")[0];

		this.deviceController.pollDeviceRebootStatus(response.uuid)
			.then(resp => {
				if (resp.status === "pending"){


					if (pollCount === 5) {
						this.props.actions.addNotification(uuidv4(), "Progress", "Reboot", "Reboot still in progress, please be patient", [], 3);
					}

					if (pollCount < 5) {
						setTimeout(() => this.completeDeviceReboot(resp, ++pollCount), 1000); //2 second sleep before polling again
					}

					else if (pollCount < 10) {
						drawer.scroll(0,0);

						setTimeout(() => this.completeDeviceReboot(resp, ++pollCount), 2000); //2 second sleep before polling after 5 attempts
					}
					else {
						setTimeout(() => this.completeDeviceReboot(resp, ++pollCount), 4000); //4 second sleep before polling after 10 attempts
					}
				}

				else if (resp.status === "done") {
					drawer.scroll(0,0);
					this.props.actions.addNotification(uuidv4(), "Success", "Reboot", "Device successfully rebooted", [], 2);
					this.setState({ isBusy: false });
				}

				else if (resp.status === "error") {
					drawer.scroll(0,0);
					this.props.actions.addNotification(uuidv4(), "Error", "Reboot", resp.error , [], 1);
				}

			})
			.catch(err => {
				console.log(err)
				this.props.actions.addNotification(uuidv4(), "Error", "Reboot", err.error, [], 1);
			})
	};

	rebootDevice = () => {

		if (this.state.device.uuid) {
			this.setState({ isBusy: true });
			this.deviceController
				.rebootDevice(this.state.device.uuid)
				.then((response) => {
					if (response && response.item && response.item.job) {

						this.deviceController.initDeviceRebootStatus(response.item.job.uuid)
							.then(response => {
							})
							.catch(err => {
								this.props.actions.addNotification(uuidv4(), "Error", "Reboot", "Something went wrong. Please refresh and try again", [], 4);
							});

						this.completeDeviceReboot(response.item.job, 1);
						this.props.actions.addNotification(uuidv4(), "Info", "Reboot", "Reboot process started, please be patient");
					} else if (response && response.error) {
						this.props.actions.addNotification(uuidv4(), "Error", "Reboot", this.stringProvider.capitalizeFirstLetter(response.error), [], 1);
					}
					this.setState({ showRebootModal: false, isBusy: false });
				})
				.catch(() => {
					this.setState({ showRebootModal: false, isBusy: false });
				});
		}
	};

	regenerateProvisioningKey = () => {
		if (this.state.device.uuid) {
			this.setState({ isBusy: true });
			this.deviceController
				.regenerateProvisioningKey(this.state.device.uuid)
				.then((response) => {
					if (response && response.provisioning_status) {
						let updatedDevice = this.state.device;
						updatedDevice.provisioning_status = response.provisioning_status;
						this.setState({ device: updatedDevice, provisioningStatus: response.provisioning_status });
						this.props.actions.addNotification(uuidv4(), "Success", "Provisioning Key", "Provisioning key successfully regenerated");
					}
					this.setState({ showKeyModal: false, isBusy: false });
				})
				.catch(() => {
					this.setState({ showKeyModal: false, isBusy: false });
				});
		}
	};

	clearProvisioningKey = () => {
		if (this.state.device.uuid) {
			this.setState({ isBusy: true });
			this.deviceController
				.clearProvisioningKey(this.state.device.uuid)
				.then((response) => {
					if (response && response.provisioning_status) {
						let updatedDevice = this.state.device;
						updatedDevice.provisioning_status = response.provisioning_status;
						this.setState({ device: updatedDevice, provisioningStatus: response.provisioning_status });
						this.props.actions.addNotification(uuidv4(), "Success", "Provisioning Key", "Provisioning key successfully cleared");
					}
					this.setState({ showClearKeyModal: false, isBusy: false });
				})
				.catch(() => {
					this.setState({ showClearKeyModal: false, isBusy: false });
				});
		}
	};

	regeneratePassword = () => {
		if (this.state.device.uuid) {
			this.setState({ isBusy: true });
			this.deviceController
				.regeneratePasswords(this.state.device.uuid)
				.then((response) => {
					if (response) {
						let updatedDevice = this.state.device;
						updatedDevice.admin_password = response.admin_password;
						updatedDevice.user_password = response.user_password;
						this.setState({ device: updatedDevice, userPass: response.user_password, adminPass: response.admin_password });
						this.props.actions.addNotification(uuidv4(), "Success", "Passwords", "Passwords were successfully regenerated");
					}
					this.setState({ showPasswordModal: false, isBusy: false });
				})
				.catch(() => {
					this.setState({ showPasswordModal: false, isBusy: false });
				});
		}
	};

	completePortSync = async (job: AccountJobModel, firstRun?: boolean) => {
		let monitorJob = await this.deviceController.monitorDeviceJob(job.uuid);
		let drawer = document.getElementsByClassName("drawer")[0];

		if (monitorJob && monitorJob.status === "done") {
			this.props.actions.addNotification(uuidv4(), "Success", "Port Assignments", "Port Assignments Successfully Pushed");
			this.setState({
				isBusy: false,
			});
			drawer.scroll(0, 0);
			this.setState({ showPortAssignmentsModal: false, isBusy: false });
		} else if (monitorJob && monitorJob.status === "pending") {
			if (firstRun) {
				this.props.actions.addNotification(uuidv4(), "Progress", "Port Assignments", "Port Assignments sync still in progress, please be patient");
				drawer.scroll(0, 0);
			}
			setTimeout(() => this.completePortSync(job), 5000);
		} else {
			drawer.scroll(0, 0);
			this.props.actions.addNotification(uuidv4(), "Error", "Port Assignments", "Port Assignments was not pushed", [], 1);
			this.setState({
				isBusy: false,
				showPortAssignmentsModal: false,
			});
		}
	};

	pushPortAssignments = () => {
		if (this.state.device.uuid) {
			this.setState({ isBusy: true });
			this.deviceController.pushPortAssignments(this.state.device).then((response) => {
				if (response && response.job) {
					this.deviceController.completePortSync(response.job.uuid);
					this.completePortSync(response.job, true);
				} else {
					this.props.actions.addNotification(uuidv4(), "Error", "Port Assignments", "Port Assignments was not pushed", [], 1);
					this.setState({ showPortAssignmentsModal: false, isBusy: false });
				}
			});
		}
	};

	setMacAddress = async () => {
		if (this.newMacAddress && this.newMacAddress.match(/^([0-9a-fA-F]{2}[.:-]?){5}([0-9a-fA-F]{2})$/)) {
			this.setState({ isBusy: true });
			await this.deviceController.setMacAddress(this.state.device, this.newMacAddress).then((response) => {
				if (response && response.mac) {
					let updatedDevice = this.state.device;
					updatedDevice.mac = response.mac;
					this.setState({ showDevice: false, showMacModal: false, isBusy: false });
					this.props.actions.addNotification(uuidv4(), "Success", "New MAC Address", "MAC Address Successfully Updated");

					this.newMacAddress = "";
					History.push(`${this.props.adminView ? "/admin" : "/user"}/devices/${response.mac}`);
					this.setState({
						device: updatedDevice,
						showDevice: true,
					});
				} else {
					this.newMacAddress = "";
					this.setState({ isBusy: false, showMacModal: false });
					this.props.actions.addNotification(uuidv4(), "Error", "New MAC Address", "MAC Address not successfully Updated", [], 1);
				}
			});
		}
	};

	generateTableHeader = () => {
		const headers: IAdvancedTableColumn[] = [
			{
				id: "model/key",
				displayId: "model/value",
				name: "Model",
				sortable: true,
				filterable: true,
				filterOptions: {
					type: "dropdown",
					onFilter: (item: any, value: any) => {
						if (!value) return true;

						if (item.model.key && item.model.key.toLowerCase().indexOf(value.toLowerCase()) !== -1) return true;

						return false;
					},
					createOptionsOffItems: true,
				},
			},
			{
				id: "macAddress",
				name: "MAC Address",
			},
			{
				displayId: "firmware/value",
				id: "firmware/key",
				name: "Firmware",
				sortable: true,
			},
			{
				id: "owner/key",
				displayId: "owner/value",
				name: "Owner",
				sortable: true,
			},
			{
				id: "description/key",
				displayId: "description/value",
				name: "Description",
				sortable: true,
			},
			{
				id: "status",
				name: "Status",
			},
		];

		// if (this.state.userRole === "UNPRIV_USER" || !this.state.isAdmin) {
		// 	const firmWareIndex = headers.findIndex((x) => x.id === "firmware");
		// 	if (firmWareIndex !== undefined) headers.splice(firmWareIndex, 1);

		// 	const ownerIndex = headers.findIndex((x) => x.id === "owner");
		// 	if (ownerIndex !== undefined) headers.splice(ownerIndex, 1);

		// 	const statusIndex = headers.findIndex((x) => x.id === "status");
		// 	if (statusIndex !== undefined) headers.splice(statusIndex, 1);
		// }

		return headers;
	};

	getUsersText = (users: UserModel[]) => {
		let result = "";
		users.forEach((user, index) => {
			result += user.cn;
			if (index !== users.length - 1) {
				result += ", ";
			}
		});
		return result;
	};

	render() {
		const ModalData = withComponent(Modal);

		let tableData: any[] = [];

		this.state.deviceList &&
			this.state.deviceList.map((device) => {
				let tableItem = {
					model: {
						value: (
							<div className="row">
								{device.model}
								{device.hw_pairing?.pairable && (
									<Tooltip text={device.hw_pairing?.paired_to?.mac ? "Paired to " + device.hw_pairing?.paired_to?.mac : "Pairable device"}>
										<div className="pl-2">
											<Icon icon="link" type="regular" />
										</div>
									</Tooltip>
								)}
							</div>
						),
						key: device.model,
					},
					macAddress: (
						<span className="no-wrap">
							<Button
								type={ButtonType.TextNaked}
								padded={false}
								text={device.mac}
								onClick={() => {
									this.editDevice(device);
								}}
							/>
						</span>
					),
					firmware: { key: device.firmware_type || "", value: device.firmware_type },
					owner: {
						key: device.owners && device.owners.length > 0 ? device.owners[0].cn : "",
						value: (
							<p>
								{device.owners.length > 0 ? device.owners[0].cn + " " : ""}
								{device.owners.length > 1 && (
									<Tooltip text={this.getUsersText(device.owners)}>
										<span className="pl-2">
											<Icon icon="users" type="regular" />
										</span>
									</Tooltip>
								)}
							</p>
						),
					},
					description: { key: device.description || "", value: device.description },
					status: device.active ? <Icon icon={"check"} type={"regular"} color={"green"} /> : <Icon icon={"times"} type={"regular"} color={"red"} />,
					device: device,
				};

				tableData.push(tableItem);

				return null;
			});

		const actionButtons: ButtonProps[] = [
			{
				icon: "cog",
				type: ButtonType.IconNaked,
				onClick: (e: any) => this.editDevice(e.device),
				text: "Device Details",
				state: 0,
				padded: false,
			},
			{
				icon: "power-off",
				shouldDisplay: (e: any) => !e.device.audio_codes,
				type: ButtonType.IconNaked,
				onClick: (e: any) => this.setState({ device: e.device, showRebootModal: true }),
				text: "Reboot Device",
				state: 0,
				padded: false,
			},
		];

		const navigationStyle = {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-end',
			padding: '1em',
			paddingRight: '0em',
			marginBottom: '3em'
		};

		const buttonContainerStyle = {
			marginRight: '1em'
		};

		const finalStyle = { ...navigationStyle};


		// const hasPreviousRecords = this.state.offset > 0;
		// const hasNextRecords = (this.state.offset + this.state.limit) < this.state.totalCount;

		const hasPreviousRecords = this.state.offset > 1;
		const hasNextRecords = (this.state.offset + this.state.limit - 1) < this.state.totalCount;

		return (
			<Fragment>
				<Drawer
					header={<Text content={this.state.device.mac ? `Edit Device ${this.state.device.mac}` : "Create Device"} type={"h4"} icon="phone" />}
					footer={[]}
					type={DrawerType.Right}
					layout={DrawerLayout.Hero}
					size={DrawerSize.Medium}
					headerNavBar={<NavBar type={NavBarTypes.IconText} tabs={this.getDrawerActions()} />}
					show={this.state.showDevice}
					contents={
						<Provider store={globalStore}>
							<DeviceDetail
								isPristine={this.hasChanged}
								deviceId={this.state.device.mac || ""}
								provisioningStatus={this.state.provisioningStatus}
								userPassword={this.state.userPass}
								adminPassword={this.state.adminPass}
								close={this.cancelDevice}
							/>
						</Provider>
					}
					onClose={this.cancelDevice}
				/>
				<div className="container">
					<div className="row">
						<div className="col-lg-6 col-md-8 mx-auto">
							<div
								style={{
									display: "flex",
									justifyContent: "space-around",
								}}>
								<Textbox
									name="searchbox"
									compact={false}
									endIcon="search"
									startIcon="phone"
									clearable
									placeholderText="Search Devices"
									helperText= {this.props.adminView ? "Search by Account, Kerberos, Description, MAC Address or IP Address" : "Search by Account, Kerberos, Description or MAC Address"}
									type={TextboxType.IconEnd | TextboxType.IconStart}
									onEndIconClick={this.searchDevices}
									onKeyUp={(e: any) => {
										if (e.keyCode === 13) this.searchDevices("", e.currentTarget.value);
									}}
									onClear={() => {
										this.setState({ searchCriteria: "", deviceList: null });
										!this.props.adminView && this.searchDevices(null, "");
									}}
									value={this.props.Id && this.props.Id.includes("search") ? this.props.Id.replace("search-", "") : ""}
									width={100}
									endIconRequired={true}
									properties={{ "aria-label": "Search Devices" }}
								/>
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
								<span
									style={{
										whiteSpace: "nowrap",
									}}>
									{this.props.adminView && this.state.userRole !== "UNPRIV_USER" && this.state.userRole !== "HELPDESK" && (
										<Button type={ButtonType.Primary} outlined={true} text="Create Device" onClick={this.createDevice} icon="plus" />
									)}
								</span>
							</div>
						</div>
					</div>
				</div>

				<Spacer size="4" />
				{this.state.isLoading ? (
					<ActionList isLoading items={[]} />
				) : (
					<Fragment>
						{!this.state.deviceList && (
							<GridContainer showGutters={true}>
								<LayoutColumn colSize={12} alignment={"center"} padded>
									<Error context={ErrorContext.Component} message="Please search to display a list of devices" type={ErrorType.FirstAction} />
								</LayoutColumn>
							</GridContainer>
						)}
						{this.state.deviceList && this.state.deviceList.length < 1 && (
							<GridContainer showGutters={true}>
								<LayoutColumn colSize={12} alignment={"center"} padded>
									<Error context={ErrorContext.Component} message="No devices found. Please search to display a list of devices" type={ErrorType.NoData} />
								</LayoutColumn>
							</GridContainer>
						)}
						{this.state.deviceList && this.state.deviceList.length > 0 && (
							<Fragment>
								{this.state.userRole === "UNPRIV_USER" || !this.props.adminView ? (
									<AdvancedTable columns={this.generateTableHeader()} items={tableData} maxRows={10000} actionButtons={actionButtons} />
								) : (
									<AdvancedTable showFilterButton columns={this.generateTableHeader()} items={tableData} maxRows={10000} actionButtons={actionButtons} />
								)}
							</Fragment>
						)}

						<Fragment>
							<div style={finalStyle}>
								<div style={{ display: 'flex', alignItems: 'center', marginRight: '1em' }}>
							<span style={{ marginRight: '1em'}}>
								{this.getDisplayedRecordRange()}
							</span>
								</div>

								<div style={{ display: 'flex', alignItems: 'center' }}>
									{hasPreviousRecords && (
										<div style={buttonContainerStyle}>
											<Button
												type={ButtonType.Primary}
												text="<"
												onClick={this.previousPage}
												state={this.state.offset === 0 ? ButtonState.Disabled : ButtonState.Enabled}
											/>
										</div>
									)}
								</div>
								{hasNextRecords && (
									<Button
										type={ButtonType.Primary}
										text=">"
										onClick={this.nextPage}
										state={ButtonState.Enabled}
									/>
								)}
							</div>

						</Fragment>

					</Fragment>


				)}

				<ModalData
					show={this.state.showMacModal}
					onClose={() => this.setState({ showMacModal: false })}
					containerless={false}
					size={TemplateModalSize.Default}
					body={
						<Fragment>
							<Label text="Enter MAC Address for the new AudioCodes Device:" />
							<Textbox
								name="macAddress"
								placeholderText="MAC Address"
								clearable
								value={this.newMacAddress}
								pattern={{
									value: /^([0-9a-fA-F]{2}[.:-]?){5}([0-9a-fA-F]{2})$/,
									message: "MAC Address format",
								}}
								onChange={(e: any) => {
									this.newMacAddress = e.currentTarget.value;
								}}
							/>
						</Fragment>
					}
					footer={<Button text="Submit" onClick={this.setMacAddress} isBusy={this.state.isBusy} />}
					header={<Text content="Update MAC Address" type={"h4"} icon="" />}
					name={"macAddressModal"}
				/>

				<ConfirmationModal
					show={this.state.showRebootModal}
					onClose={() => this.setState({ showRebootModal: false })}
					name="rebootModal"
					title="Reboot Device"
					isBusy={this.state.isBusy}
					body="Are you sure you want to reboot this device?"
					callBack={this.rebootDevice}
				/>

				<ConfirmationModal
					show={this.state.showKeyModal}
					onClose={() => this.setState({ showKeyModal: false })}
					name="keyModal"
					isBusy={this.state.isBusy}
					title="Regenerate Provisioning Key"
					body="Are you sure you want to regenerate this provisioning key?"
					callBack={this.regenerateProvisioningKey}
				/>

				<ConfirmationModal
					show={this.state.showClearKeyModal}
					onClose={() => this.setState({ showClearKeyModal: false })}
					name="keyClearModal"
					title="Clear Provisioning Key"
					isBusy={this.state.isBusy}
					body="Are you sure you want to clear this provisioning key?"
					callBack={this.clearProvisioningKey}
				/>

				<ConfirmationModal
					show={this.state.showPasswordModal}
					onClose={() => this.setState({ showPasswordModal: false })}
					name="passwordModal"
					title="Regenerate Passwords"
					isBusy={this.state.isBusy}
					body="Are you sure you want to regenerate this device’s passwords?"
					callBack={this.regeneratePassword}
				/>

				<ConfirmationModal
					show={this.state.showPortAssignmentsModal}
					onClose={() => this.setState({ showPortAssignmentsModal: false })}
					name="portAssignmentsModal"
					title="Sync Database Config to AudioCodes Devices"
					isBusy={this.state.isBusy}
					body={"Are you sure you want to apply the MITvoip database configuration to AudioCodes device " + this.state.device.description + "?"}
					callBack={this.pushPortAssignments}
				/>

				<ConfirmationModal
					show={this.state.showLeaveModal}
					onClose={() => this.setState({ showLeaveModal: false })}
					name="showLeaveModal"
					title="Device Details"
					body="Are you sure you want to leave device details, all your changes will be discarded?"
					callBack={this.confirmLeave}
				/>
			</Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({
	adminView: state.app.adminView,
	impersonate: state.app.impersonate,
});

const mapDispatchToProps = (dispatch: any) => ({
	actions: bindActionCreators(notificationActionCreator, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeviceList);
