// eslint-disable-next-line
import React, { Fragment } from "react";
import PairController from "../../api/PairController";
import { PairModel } from "../../api/models/PairModel";
import {
	Button,
	ButtonType,
	Drawer,
	DrawerLayout,
	DrawerSize,
	DrawerType,
	Text,
	Textbox,
	TextboxType,
	TextType,
	GridContainer,
	LayoutColumn,
	ErrorContext,
	Error,
	ErrorType,
	Spacer,
	ActionList,
	AdvancedTable,
	IAdvancedTableColumn,
	ButtonProps,
	FormField,
	DropdownItemProps,
	FormContainer,
} from "@mit/hui";
import PairDetail from "./PairDetail";
import StringProvider from "../../services/StringProvider";
import History from "../../common/History";
import { connect, Provider } from "react-redux";
import { store } from "../../common/redux/store/store";
import AuthProvider from "../../services/AuthProvider";
export const globalStore = store;

interface PairSearchCriteria {
	q: string;
	switch_site?: string;
	frame_partition?: string;
	destination?: string;
	status?: string;
	range_start?: string;
	range_end?: string;
}
interface PairState {
	showPair: boolean;
	pair: PairModel;
	pairList: PairModel[] | null;
	isLoading: boolean;
	searchCriteria: PairSearchCriteria;
	showAdvancedSearch: boolean;
	switchSiteData: DropdownItemProps[];
	framePartitionData: DropdownItemProps[];
	statusData: DropdownItemProps[];
}

interface IPairListProps {
	id: string;
	adminView?: any;
	impersonate?: any;
}

class PairList extends React.Component<IPairListProps, PairState> {
	pairController: PairController;
	stringProvider: StringProvider;
	newPair: PairModel;
	_isMounted: boolean;
	authProvider: AuthProvider

	constructor(props: any) {
		super(props);

		//This class did not take any special action on timeout
		//It will continue to act in such a way
		const onSessionTimeout = () => {}
		this.authProvider = new AuthProvider(onSessionTimeout)
		this.pairController = new PairController(this.authProvider);
		this.stringProvider = new StringProvider();

		this.newPair = {
			switch_site: "",
			frame_partition: "",
			pair_number: 0,
			status: "",
			notes: null,
		};

		this.state = {
			showPair: false,
			pair: this.newPair,
			pairList: null,
			isLoading: false,
			searchCriteria: { q: "" },
			showAdvancedSearch: false,
			switchSiteData: [],
			framePartitionData: [],
			statusData: [],
		};
	}

	componentDidMount() {
		this._isMounted = true;

		if (!this.props.adminView || this.props.impersonate) this.searchPair();

		if (this.props.id) {
			let pair = this.newPair;
			pair.uuid = this.props.id;
			this.setState({
				showPair: true,
				pair: pair,
			});
		}

		const fetchLookups = () => {
			const switchSites = globalStore.getState().lookups.switchSites;
			const framePartitions = globalStore.getState().lookups.framePartitions;
			const pairStatuses = globalStore.getState().lookups.pairStatues;
			this._isMounted &&
				this.setState({
					switchSiteData: this.generateDropdownItems(this.stringProvider.listCapitalizeAll(switchSites)),
					framePartitionData: this.generateDropdownItems(this.stringProvider.listCapitalizeFirst(framePartitions)),
					statusData: this.generateValueDropdownItems(pairStatuses),
				});
		};

		this._isMounted && fetchLookups();
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	componentDidUpdate(previousProps: any) {
		if (previousProps !== this.props) {
			if (!this.props.adminView || this.props.impersonate) this.searchPair();
		}
	}

	generateDropdownItems = (items: string[]) => {
		const dropdownData: DropdownItemProps[] = [];
		items.forEach((item: string) => {
			dropdownData.push({ text: item, icon: "" });
		});
		return dropdownData;
	};

	generateValueDropdownItems = (items: any[]) => {
		const dropdownData: DropdownItemProps[] = [];
		items.forEach((item: any) => {
			dropdownData.push({
				text: this.stringProvider.capitalizeFirstLetter(item.value),
				icon: "",
			});
		});
		return dropdownData;
	};

	editPair = (pair: PairModel) => {
		this.setState({
			showPair: true,
			pair: pair,
		});

		History.push(`${this.props.adminView ? "/admin" : "/user"}/pairs/pair-tracking/${pair.uuid}`);
	};

	cancelPair = () => {
		this.setState({
			showPair: false,
			pair: this.newPair,
		});
		this.searchPair();

		History.push(`${this.props.adminView ? "/admin" : "/user"}/pairs/pair-tracking`);
	};

	searchPair = async () => {
		this.setState({ isLoading: true });
		const pairList = await this.pairController.getPairList(this.state.searchCriteria);
		this.setState({ pairList: pairList, isLoading: false });
	};

	toggleAdvancedSearch = () => {
		const newSearchCriteria = { q: this.state.searchCriteria.q };
		this.setState({ showAdvancedSearch: !this.state.showAdvancedSearch, searchCriteria: newSearchCriteria });
	};

	render() {
		const headers: IAdvancedTableColumn[] = [
			{
				id: "switchSite/key",
				displayId: "switchSite/value",
				name: "Switch Site",
				sortable: true,
				filterable: true,
				filterOptions: {
					type: "dropdown",
					onFilter: (item: any, value: any) => {
						if (!value) return true;

						if (item.switchSite && item.switchSite.toLowerCase().indexOf(value.toLowerCase()) !== -1) return true;

						return false;
					},
					createOptionsOffItems: true,
				},
			},
			{
				id: "framePartition/key",
				displayId: "framePartition/value",
				name: "Frame Partition",
				sortable: true,
				filterable: true,
				filterOptions: {
					type: "dropdown",
					onFilter: (item: any, value: any) => {
						if (!value) return true;

						if (item.framePartition && item.framePartition.toLowerCase().indexOf(value.toLowerCase()) !== -1) return true;

						return false;
					},
					createOptionsOffItems: true,
				},
			},
			{
				id: "pairNumber/key",
				displayId: "pairNumber/value",
				name: "Pair Number",
				sortable: true,
			},
			{
				id: "status/key",
				displayId: "status/value",
				name: "Status",
				sortable: true,
				filterable: true,
				filterOptions: {
					type: "dropdown",
					onFilter: (item: any, value: any) => {
						if (!value) return true;

						if (item.status && item.status.toLowerCase().indexOf(value.toLowerCase()) !== -1) return true;

						return false;
					},
					createOptionsOffItems: true,
				},
			},
			{
				id: "pairRange/key",
				displayId: "pairRange/value",
				name: "Pair Range",
				sortable: true,
			},
			{
				id: "destination",
				name: "Pair Range Destination",
				filterable: true,
				filterOptions: {
					type: "dropdown",
					onFilter: (item: any, value: any) => {
						if (!value) return true;

						if (item.destination && item.destination.toLowerCase().indexOf(value.toLowerCase()) !== -1) return true;

						return false;
					},
					createOptionsOffItems: true,
				},
			},
			{
				id: "device/key",
				displayId: "device/value",
				name: "Device",
				sortable: true,
			},
			{
				id: "port/key",
				displayId: "port/value",
				name: "Port",
				sortable: true,
			},
			{
				id: "did",
				name: "DID",
			},
		];

		let tableData: any[] = [];

		this.state.pairList &&
			this.state.pairList.map((pair) => {
				let tableItem = {
					switchSite: { key: pair.switch_site, value: pair.switch_site.toUpperCase() },
					framePartition: { key: pair.frame_partition, value: this.stringProvider.capitalizeFirstLetter(pair.frame_partition) },
					pairNumber: { key: pair.pair_number, value: <Button type={ButtonType.TextNaked} padded={false} text={pair.pair_number.toString()} onClick={() => this.editPair(pair)} /> },
					status: { key: pair.status, value: this.stringProvider.capitalizeFirstLetter(pair.status) },
					pairRange: { key: pair.pair_range ? pair.pair_range.range_start : "", value: pair.pair_range ? pair.pair_range?.range_start + " - " + pair.pair_range?.range_end : "N/A" },
					destination: pair.pair_range?.destination || "",
					device: { key: pair.audio_codes_hostname || "", value: <Button type={ButtonType.TextNaked} padded={false} text={pair.audio_codes_hostname || ""} onClick={() => {}} /> },
					port: { key: pair.audio_codes_port, value: pair.audio_codes_port },
					did: this.stringProvider.formatPhoneNumber(pair.audio_codes_user?.did),
					pair: pair,
				};

				tableData.push(tableItem);

				return null;
			});

		const actionButtons: ButtonProps[] = [
			{
				icon: "edit",
				type: ButtonType.IconNaked,
				onClick: (e: any) => this.editPair(e.pair),
				text: "Edit",
				state: 0,
				padded: false,
			},
		];

		return (
			<Fragment>
				<Drawer
					header={<Text content={"Edit Pair " + this.state.pair.pair_number} type={TextType.Heading4} icon="grip-lines" />}
					type={DrawerType.Right}
					layout={DrawerLayout.Hero}
					size={DrawerSize.Medium}
					show={this.state.showPair}
					contents={
						<Provider store={globalStore}>
							<PairDetail pairId={this.state.pair.uuid || ""} close={this.cancelPair} />
						</Provider>
					}
					onClose={this.cancelPair}
					footer={[]}
				/>

				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-around",
					}}>
					<Button
						text={`${this.state.showAdvancedSearch ? "Basic" : "Advanced"} Search`}
						type={ButtonType.TextNaked}
						icon={this.state.showAdvancedSearch ? "caret-up" : "caret-down"}
						onClick={this.toggleAdvancedSearch}></Button>
				</div>

				<Spacer size="2" />

				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-around",
					}}>
					{this.state.showAdvancedSearch ? (
						<div>
							<FormContainer
								id="PairTrackingForm"
								formValidationTrigger="onChange">

								<GridContainer showGutters={true}>
									<LayoutColumn colSize={12} padded>
										<FormField
											id="search Text"
											editor="textbox"
											label="Keyword"
											placeholder="Keyword"
											labelDisplay="ontop"
											value={this.state.searchCriteria.q}
											onChange={(e: any) => {
												let searchCriteria = this.state.searchCriteria;
												searchCriteria.q = e.currentTarget.value;
												this.setState({ searchCriteria: searchCriteria });
											}}
											properties={{ "aria-label": "Keyword" }}
										/>
									</LayoutColumn>
									<LayoutColumn colSize={6} padded>
										<FormField
											id="destination"
											value={this.state.searchCriteria.destination}
											onChange={(e: any) => {
												let searchCriteria = this.state.searchCriteria;
												searchCriteria.destination = e.currentTarget.value;
												this.setState({ searchCriteria: searchCriteria });
											}}
											editor="textbox"
											placeholder="Destination"
											label="Destination"
											labelDisplay="ontop"
										/>
										<FormField
											id="switchSite"
											editor="dropdown"
											options={this.state.switchSiteData}
											label="Switch Site"
											labelDisplay="ontop"
											value={this.state.searchCriteria.switch_site}
											onChange={(e: any) => {
												let searchCriteria = this.state.searchCriteria;
												searchCriteria.switch_site = e.text;
												this.setState({ searchCriteria: searchCriteria });
											}}
										/>
										<FormField
											id="rangeStart"
											value={this.state.searchCriteria.range_start}
											onChange={(e: any) => {
												let searchCriteria = this.state.searchCriteria;
												searchCriteria.range_start = e.currentTarget.value;
												this.setState({ searchCriteria: searchCriteria });
											}}
											editor="textbox"
											placeholder="Range Start"
											label="Range Start"
											labelDisplay="ontop"
										/>
									</LayoutColumn>
									<LayoutColumn colSize={6} padded>
										<FormField
											id="status"
											editor="dropdown"
											options={this.state.statusData}
											label="Pair Status"
											labelDisplay="ontop"
											value={this.state.searchCriteria.status}
											onChange={(e: any) => {
												let searchCriteria = this.state.searchCriteria;
												searchCriteria.status = e.text;
												this.setState({ searchCriteria: searchCriteria });
											}}
										/>
										<FormField
											id="framePartition"
											editor="dropdown"
											options={this.state.framePartitionData}
											label="Frame Partition"
											labelDisplay="ontop"
											value={this.state.searchCriteria.frame_partition}
											onChange={(e: any) => {
												let searchCriteria = this.state.searchCriteria;
												searchCriteria.frame_partition = e.text;
												this.setState({ searchCriteria: searchCriteria });
											}}
										/>
										<FormField
											id="rangeEnd"
											value={this.state.searchCriteria.range_end}
											onChange={(e: any) => {
												let searchCriteria = this.state.searchCriteria;
												searchCriteria.range_end = e.currentTarget.value;
												this.setState({ searchCriteria: searchCriteria });
											}}
											editor="textbox"
											label="Range End"
											placeholder="Range End"
											labelDisplay="ontop"
										/>
									</LayoutColumn>
								</GridContainer>
								<GridContainer showGutters={true}>
									<LayoutColumn colSize={12} alignment="center" padded>
										<Button type={ButtonType.Primary} text="Search" icon="search" onClick={this.searchPair} isBusy={this.state.isLoading} />
										<Button type={ButtonType.TextNaked} text="Clear" icon="times" onClick={() => this.setState({ searchCriteria: { q: "" }, pairList: null })} />
									</LayoutColumn>
								</GridContainer>
							</FormContainer>	
						</div>
					) : (
						<Textbox
							name="searchbox"
							compact={false}
							endIcon="search"
							startIcon="grip-lines"
							placeholderText="Search Pairs"
							type={TextboxType.IconEnd | TextboxType.IconStart}
							helperText="Search by pair number, device, port number, did or defective status"
							onEndIconClick={this.searchPair}
							onKeyUp={(e: any) => {
								if (e.keyCode === 13) this.searchPair();
							}}
							value={this.state.searchCriteria.q}
							onChange={(e: any) => {
								let searchCriteria = this.state.searchCriteria;
								searchCriteria.q = e.currentTarget.value;
								this.setState({ searchCriteria: searchCriteria });
							}}
							endIconRequired={true}
							clearable
							onClear={() => this.setState({ searchCriteria: { q: "" }, pairList: null })}
							width={50}
							properties={{ "aria-label": "Search Pairs" }}
						/>
					)}
				</div>

				<Spacer size="4" />
				{this.state.isLoading ? (
					<ActionList isLoading items={[]} />
				) : (
					<Fragment>
						{!this.state.pairList && (
							<GridContainer showGutters={true}>
								<LayoutColumn colSize={12} alignment={"center"} padded>
									<Error context={ErrorContext.Component} message="Please search to display a list of pairs" type={ErrorType.FirstAction} />
								</LayoutColumn>
							</GridContainer>
						)}
						{this.state.pairList && this.state.pairList.length < 1 && (
							<GridContainer showGutters={true}>
								<LayoutColumn colSize={12} alignment={"center"} padded>
									<Error context={ErrorContext.Component} message="No pairs found. Please search to display a list of pairs" type={ErrorType.NoData} />
								</LayoutColumn>
							</GridContainer>
						)}
						{this.state.pairList && this.state.pairList.length > 0 && (
							<Fragment>
								<AdvancedTable showFilterButton columns={headers} items={tableData} maxRows={10000} actionButtons={actionButtons} />
							</Fragment>
						)}
					</Fragment>
				)}
			</Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({
	adminView: state.app.adminView,
	impersonate: state.app.impersonate,
});

export default connect(mapStateToProps)(PairList);
