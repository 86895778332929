import { ActionList, DropdownItemProps, FormContainer, FormField, GridContainer, LayoutColumn, Text, TextType, ToastContainer, ToastPosition } from "@mit/hui";
import React, { Fragment } from "react";
import { ChangeLogModel } from "../../api/models/ChangeLogModel";
import { PairModel } from "../../api/models/PairModel";
import PairController from "../../api/PairController";
import StringProvider from "../../services/StringProvider";
import _ from "lodash";
import DeviceController from "../../api/DeviceController";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { store } from "../../common/redux/store/store";
import * as notificationActionCreator from "../../common/redux/actions/notification";
import { uuidv4 } from "../../common/redux/actions/notification";
import AuthProvider from "../../services/AuthProvider";
export const globalStore = store;

interface IPairProps {
	pairId: string;
	close: () => void;
	actions?: any;
}

interface PairState {
	pair: PairModel;
	pairId: string;
	switchSiteData: DropdownItemProps[];
	framePartitionData: DropdownItemProps[];
	statusData: DropdownItemProps[];
	audioCodesDevices: DropdownItemProps[];
	formError: boolean;
	isLoading: boolean;
	changeLogs: ChangeLogModel[];
	isBusy: boolean;
	isLoadingDid: boolean;
}

class PairDetail extends React.Component<IPairProps, PairState> {
	pairController: PairController;
	deviceController: DeviceController;
	stringProvider: StringProvider;
	_isMounted: boolean;
	authProvider: AuthProvider

	constructor(props: any) {
		super(props);

		//This class did not take any special action on timeout
		//It will continue to act in such a way
		const onSessionTimeout = () => {}
		this.authProvider = new AuthProvider(onSessionTimeout)
		this.pairController = new PairController(this.authProvider);
		this.stringProvider = new StringProvider();
		this.deviceController = new DeviceController(this.authProvider);

		this._isMounted = false;
		this.state = {
			pairId: this.props.pairId,
			pair: {
				switch_site: "",
				frame_partition: "",
				pair_number: 0,
				status: "",
				notes: null,
			},
			isBusy: false,
			switchSiteData: [],
			framePartitionData: [],
			statusData: [],
			audioCodesDevices: [],
			formError: false,
			isLoading: false,
			changeLogs: [],
			isLoadingDid: false,
		};
	}

	generateDropdownItems = (items: string[], nullable?: boolean) => {
		const dropdownData: DropdownItemProps[] = [];
		if (nullable) {
			dropdownData.push({ text: "Unassigned", icon: "" });
		}
		items.forEach((item: string) => {
			dropdownData.push({ text: item, icon: "" });
		});
		return dropdownData;
	};

	generateValueDropdownItems = (items: any[]) => {
		const dropdownData: DropdownItemProps[] = [];
		items.forEach((item: any) => {
			if (item.enabled) {
				dropdownData.push({
					text: this.stringProvider.capitalizeFirstLetter(item.value),
					icon: "",
				});
			}
		});
		return dropdownData;
	};

	componentDidMount() {
		this._isMounted = true;
		const fetchLookups = async () => {
			const switchSites = globalStore.getState().lookups.switchSites;
			const framePartitions = globalStore.getState().lookups.framePartitions;
			const pairStatuses = globalStore.getState().lookups.pairStatues;
			const audioCodesDevices = await this.pairController.getAudioCodesDevices();
			this._isMounted &&
				this.setState({
					switchSiteData: this.generateDropdownItems(this.stringProvider.listCapitalizeAll(switchSites)),
					framePartitionData: this.generateDropdownItems(this.stringProvider.listCapitalizeFirst(framePartitions)),
					statusData: this.generateValueDropdownItems(pairStatuses),
					audioCodesDevices: this.generateDropdownItems(this.stringProvider.listCapitalizeAll(audioCodesDevices), true),
				});
		};
		const fetchPair = async () => {
			this.setState({ isLoading: true });

			const pair = await this.pairController.getPair(this.state.pairId);
			this._isMounted &&
				this.setState({
					isLoading: false,
					pair: this.fromPair(pair),
				});
		};

		this._isMounted && fetchLookups() && fetchPair() && this.updateChangeLog();
	}

	updateChangeLog = async () => {
		const changeLogs = await this.pairController.getPairChangeLog(this.state.pairId);
		this.setState({ changeLogs: changeLogs || [] });
	};

	componentWillUnmount() {
		this._isMounted = false;
	}

	updatePair = () => {
		if (!this.state.formError) {
			this.setState({ isBusy: true });
			this.pairController
				.updatePair(this.toPair(this.state.pair))
				.then((response) => {
					if (response) {
						this.props.actions.addNotification(uuidv4(), "Success", "Updated", `Pair ${this.state.pair.pair_number} was successfully updated`);
						this.setState({
							pair: this.fromPair(response),
						});
						this.updateChangeLog();
						let drawer = document.getElementsByClassName("drawer")[0];
						drawer.scroll(0, 0);
					}
					this.setState({
						isBusy: false,
					});
				})
				.catch(() => this.setState({ isBusy: false }));
		}
	};

	findDeviceDid = async () => {
		if (this.state.pair.audio_codes_hostname && this.state.pair.audio_codes_port) {
			this.setState({ isLoadingDid: true });
			let devideDid = await this.deviceController.getDeviceDid(this.state.pair.audio_codes_hostname, this.state.pair.audio_codes_port);
			let updatedPair = this.state.pair;
			if (updatedPair.audio_codes_user) {
				updatedPair.audio_codes_user.did = devideDid;
			} else {
				updatedPair.audio_codes_user = {
					did: devideDid,
					account_type: "",
					account_service: "",
					username: "",
					owners: [],
					admins: [],
					assigned_phones: [],
					devices: [],
					first_name: "",
					last_name: "",
					display_name: "",
					description: "",
					location: "",
					active: true,
				};
			}
			this.setState({ pair: updatedPair, isLoadingDid: false });
		}
	};

	findDevice = _.debounce(() => this.findDeviceDid(), 500);

	render() {
		if (this.state.isLoading) return <ActionList isLoading items={[]} />;
		return (
			<Fragment>
				<FormContainer
					action={() => {
						this.updatePair();
					}}
					actionIsBusy={this.state.isBusy}
					submitText="Save"
					secondaryActionText="Cancel"
					secondaryAction={() => this.props.close()}
					isValid={(e: any) => this.setState({ formError: !e })}
					formValidationTrigger="onChange"
					id="PairForm">
					{this.state.changeLogs && this.state.changeLogs.length > 0 ? (
						<div className="metadata mb-4">
							<GridContainer showGutters={true}>
								<LayoutColumn colSize={6}>
									<Text type={TextType.Body} content="Created By" padded={false} />
								</LayoutColumn>
								<LayoutColumn colSize={6}>
									<Text
										type={TextType.Body}
										content={
											this.state.changeLogs.filter((log) => log.action === "add")[0].editor + " " + this.state.changeLogs.filter((log) => log.action === "add")[0].action_date
										}
										padded={false}
									/>
								</LayoutColumn>
							</GridContainer>
							<GridContainer showGutters={true}>
								<LayoutColumn colSize={6}>
									<Text type={TextType.Body} content="Modified By" padded={false} />
								</LayoutColumn>
								<LayoutColumn colSize={6}>
									<Text
										type={TextType.Body}
										content={this.state.changeLogs[this.state.changeLogs.length - 1].editor + " " + this.state.changeLogs[this.state.changeLogs.length - 1].action_date}
										padded={false}
									/>
								</LayoutColumn>
							</GridContainer>
						</div>
					) : (
						<div></div>
					)}
					<GridContainer showGutters={true}>
						<LayoutColumn colSize={6}>
							<FormField
								id="switchSite"
								editor="dropdown"
								options={this.state.switchSiteData}
								label="Switch Site"
								labelDisplay="ontop"
								value={this.state.pair.switch_site}
								onChange={(e: any) => {
									let updatedPair = this.state.pair;
									updatedPair.switch_site = e.text;
									this.setState({ pair: updatedPair });
								}}
								properties={{ readOnly: true }}
							/>
							<FormField
								id="pairNumber"
								value={this.state.pair.pair_number}
								onChange={(e: any) => {
									let updatedPair = this.state.pair;
									updatedPair.pair_number = e.currentTarget.value;
									this.setState({ pair: updatedPair });
								}}
								editor="textbox"
								label="Pair Number"
								labelDisplay="ontop"
								readonly={true}
							/>
							<FormField
								id="pairRange"
								value={this.state.pair.pair_range ? `${this.state.pair.pair_range?.range_start} - ${this.state.pair.pair_range?.range_end}` : ""}
								editor="textbox"
								label="Pair Range"
								labelDisplay="ontop"
								readonly={true}
							/>
							<FormField
								id="audioCodesDevices"
								onChange={(e: any) => {
									let updatedPair = this.state.pair;
									updatedPair.audio_codes_hostname = e.text;
									updatedPair.audio_codes_port = null;
									updatedPair.audio_codes_user = null;
									this.setState({ pair: updatedPair });
								}}
								editor="dropdown"
								options={this.state.audioCodesDevices}
								label="AudioCodes Device"
								labelDisplay="ontop"
								value={this.state.pair.audio_codes_hostname}
								properties={{ required: false }}
							/>
							{this.state.pair.audio_codes_hostname && this.state.pair.audio_codes_hostname !== "Unassigned" && (
								<FormField
									id="did"
									value={this.stringProvider.formatPhoneNumber(this.state.pair.audio_codes_user?.did) || ""}
									readonly={!this.state.isLoadingDid}
									properties={{ isLoading: this.state.isLoadingDid }}
									editor="textbox"
									label="DID"
									labelDisplay="ontop"
								/>
							)}
						</LayoutColumn>
						<LayoutColumn colSize={6}>
							<FormField
								id="framePartition"
								editor="dropdown"
								options={this.state.framePartitionData}
								label="Frame Partition"
								labelDisplay="ontop"
								value={this.state.pair.frame_partition}
								onChange={(e: any) => {
									let updatedPair = this.state.pair;
									updatedPair.frame_partition = e.text;
									this.setState({ pair: updatedPair });
								}}
								properties={{ readOnly: true }}
							/>
							<FormField
								id="status"
								editor="dropdown"
								options={this.state.statusData}
								label="Status"
								labelDisplay="ontop"
								value={this.state.pair.status}
								onChange={(e: any) => {
									let updatedPair = this.state.pair;
									updatedPair.status = e.text;
									this.setState({ pair: updatedPair });
								}}
								readonly={false}
							/>
							<FormField id="pairDesitation" value={this.state.pair.pair_range?.destination || ""} editor="textbox" label="Pair Range Destination" labelDisplay="ontop" readonly={true} />
							{this.state.pair.audio_codes_hostname && this.state.pair.audio_codes_hostname !== "Unassigned" && (
								<FormField
									id="port"
									value={this.state.pair.audio_codes_port}
									onChange={(e: any) => {
										let updatedPair = this.state.pair;
										updatedPair.audio_codes_port = e.currentTarget.value;
										if (e.currentTarget.value) {
											updatedPair.audio_codes_user = null;
										}
										this.setState({ pair: updatedPair });
										this.findDevice();
									}}
									editor="textbox"
									label="AudioCodes Port"
									labelDisplay="ontop"
									required={true}
								/>
							)}
							<FormField
								id="notes"
								value={this.state.pair.notes}
								editor="multilinetextbox"
								label="Notes"
								onChange={(e: any) => {
									let updatedPair = this.state.pair;
									updatedPair.notes = e.currentTarget.value;
									this.setState({ pair: updatedPair });
								}}
								pattern={{
									value: /^.{0,50}$/,
									message: "Maximum 50 characters",
								}}
								labelDisplay="ontop"
							/>
						</LayoutColumn>
					</GridContainer>
				</FormContainer>
				<ToastContainer
					toasts={[
						{
							icon: { type: "light", icon: "bell", padded: false },
							title: "Invalid Form",
							subtext: "Update",
							message: "Please complete all required fields before submitting",
							show: this.state.formError,
							type: 1,
							onClose: () => this.setState({ formError: false }),
						},
					]}
					position={ToastPosition.TopCenter}
				/>
			</Fragment>
		);
	}
	//API Transformations
	toPair = (pair: PairModel): PairModel => {
		pair.frame_partition = pair.frame_partition.toLowerCase();
		pair.switch_site = pair.switch_site.toLowerCase();
		pair.status = pair.status.toLowerCase();
		pair.audio_codes_hostname = pair.audio_codes_hostname === "Unassigned" ? null : pair.audio_codes_hostname;
		return pair;
	};

	fromPair = (pair: PairModel): PairModel => {
		pair.frame_partition = this.stringProvider.capitalizeFirstLetter(pair.frame_partition);
		pair.switch_site = pair.switch_site.toUpperCase();
		pair.status = this.stringProvider.capitalizeFirstLetter(pair.status);
		pair.audio_codes_hostname = pair.audio_codes_hostname ? pair.audio_codes_hostname : "Unassigned";
		return pair;
	};
}

const mapDispatchToProps = (dispatch: any) => ({
	actions: bindActionCreators(notificationActionCreator, dispatch),
});

export default connect(null, mapDispatchToProps)(PairDetail);
